<template>
    <v-dialog persistent width="800" v-model="show" >
        <v-card>
            <v-card-title class="text-h5">
                <LocText text_id="person_add.title" />
            </v-card-title>
            <v-card-text>
                <div style="white-space:pre-line;">
                    <LocText text_id="person_add.warning" />
                </div>

                <v-form v-model="valid">
                    <v-text-field  
                        v-model="name"      
                        :rules="name_rules"
                        data-cy="person-add-name">
                            <template v-slot:label>
                                <LocText text_id="person_add.name" />
                            </template>
                    </v-text-field>

                    <v-text-field  
                        v-model="long_name "
                        data-cy="person-add-long-name">
                            <template v-slot:label>
                                <LocText text_id="person_add.long_name" />
                            </template>
                    </v-text-field>

                    <v-textarea auto-grow rows="1"
                        data-cy="person-add-note"
                        v-model="note" >
                            <template v-slot:label>
                                <LocText text_id="person_add.note" />
                            </template>
                    </v-textarea>


                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    depressed
                    color="primary" 
                    @click="on_add_person" 
                    :disabled="!valid"
                    data-cy="person-add-ok"
                    > 
                        <LocText text_id="general.submit" /> 
                </v-btn>
                <v-btn 
                    depressed 
                    class="ml-3"
                    @click="on_cancel"
                    data-cy="person-add-cancel"
                    > 
                        <LocText text_id="general.cancel" /> 
                </v-btn>
                <v-spacer/>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import LocText          from "../components/LocText"
import { mapState }     from 'pinia'
import { mapActions }   from 'pinia'
import { useAppStore }  from '../stores/appStore'

export default { 
    name: "PersonAdd",    
    props : [ 'show' ],
    components: { LocText },
    data() {
        return {
            valid : false,
            name  : "",
            long_name : "",
            note  : "",
            name_rules: [
                value => !!value || 'required',
                value => this.person_list.findIndex( item => item.name == value ) == -1 || 'name already used'
            ],            
        }
    },
    created() {
        if(this.show) this.on_show()
    },
    watch : {
        show() { 
            if(this.show) this.on_show() 
        }
    },
    computed : {
        ...mapState( useAppStore, ['person_list'] ),
    },
    methods : {
        ...mapActions( useAppStore, [ 
            'do_create_person',
        ]),
        on_show() {
            this.name       =""
            this.long_name  =""
            this.note       =""
        },        
        on_add_person() {
            this.do_create_person( this.name, this.long_name, this.note, (json)=> {
                this.$emit('created', json )
                this.$emit('done') 
            })
        },
        on_cancel() {
            this.$emit('done') 
        }
    }
}
</script>
<style scoped>
</style>



