import Vue      from "vue";
import VueI18n  from 'vue-i18n'

Vue.use(VueI18n)

const dateTimeFormats = {
    'en' : {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric'
        }
    },
    'it' : {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
        long: { // usato in history
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric'
        }
    },
}

const options = { 
    locale: "en", 
    messages : "", 
    dateTimeFormats:dateTimeFormats, 
    silentTranslationWarn: true 
}

export default new VueI18n( options ) 
