<template>
    <v-container fluid data-cy="view-monitor">

        <div class="d-flex justify-start" style="width=100%">
            
            <div class="flex-grow-1">            

                <div class="d-flex justify-start flex-wrap ">
                    <div class="text-h6" > <LocText text_id="monitor.title" /> </div>
                    <v-spacer/>
                        <v-btn 
                            color="primary" dark small depressed 
                            @click="on_load" 
                            data-cy="monitor-update"
                        > 
                            update
                        </v-btn>
                </div>

                <div >
                    <v-data-table
                        mobile-breakpoint="100"
                        :headers="lang_current=='en' ? event_headers_eng : event_headers_ita"
                        :items="event_list"
                        :items-per-page="100"
                        dense
                        @click:row="event_clicked">
                    </v-data-table>
                </div>

            </div>
            
            <!-- <div class="flex-grow-0 ml-5" style="border-left:1px solid #444; ">  -->
            <!-- <div class="flex-grow-0 ml-5" :style="{ 'border_left' : '1px solid '+ border_color }" >  -->
            <div class="flex-grow-0 ml-5 right-pane"  > 
            
                <p class="ml-6 text-subtitle-2 font-weight-bold">site stats:</p>

                <v-data-table style="width:230px;"
                    mobile-breakpoint="100"
                    class="ml-2"
                    dense
                    hide-default-header
                    hide-default-footer
                    :headers="table_headers"
                    :items="table1"
                />
                
                <p class="ml-6 mt-10 text-subtitle-2 font-weight-bold" >file system:</p>
                
                <v-data-table style="width:230px;" 
                    mobile-breakpoint="100"
                    class = "ml-2"
                    dense
                    hide-default-header
                    hide-default-footer
                    :headers="table_headers"
                    :items="table2"
                />
            </div>

        </div>

    </v-container>    
</template>

<script>
import   LocText          from "../components/LocText"
import { mapState    }    from 'pinia'
import { mapActions  }    from 'pinia'
import { useAppStore }    from '../stores/appStore'
export default { 
    name: "Monitor",    
    components: { LocText },
    data() {
        return {
            event_headers_eng: [    
                 { text: 'date',      value: 'date_str' },
                 { text: 'user',      value: 'username' },
                 { text: 'action',    value: 'action'   },
                 { text: 'data_type', value: 'obj_class'},
                 { text: 'data_name', value: 'obj_name' },
            ],
            event_headers_ita: [    
                 { text: 'data',      value: 'date_str' },
                 { text: 'utente',    value: 'username' },
                 { text: 'azione',    value: 'action'   },
                 { text: 'tipo dato', value: 'obj_class'},
                 { text: 'nome dato', value: 'obj_name' },
            ],
            table_headers: [    
                 { text: 'name',      value: 'name' },
                 { text: 'name',      value: 'value' },
            ],

            table1 : [],
            table2 : [],
        }
    },
    mounted() {
        this.on_load()
    },
    watch: {
    },
    computed : {
        ...mapState( useAppStore, ['user', 'event_list', 'site_numbers', 'lang_current', 'theme_current'] ),
        border_color : function() { return (this.theme_current == 'dark') ? "#333" : "#DDD" }
    },
    methods : {
        ...mapActions( useAppStore, [ 'load_event_list', 'load_site_numbers' ] ),

        on_load() {
            this.load_event_list()
            this.load_site_numbers( () => {

                const D = {}
                this.site_numbers.forEach( x => {
                     D[x.name] = x.value
                })

                this.table1 = [
                    { name:'n. users',          value:D.num_users },
                    { name:'n. images',         value:D.num_images },
                    { name:'n. documents',      value:D.num_documents },
                    { name:'n. transcriptions', value:D.num_transcriptions }
                ]

                this.table2 = [
                    { name:'database size',      value:D.db_size },
                    { name:'backups size',       value:D.db_bk_size },
                    { name:'disk size',          value:D.disk_size },
                    { name:'disk used',          value:D.disk_used },
                    { name:'disk free',          value:D.disk_free }
                ]
            })
        },
        event_clicked() {
            //console.log("Monitor::event_clicked")
        }
    },
}
</script>
<style lang="css" scoped>
.v-chip { 
    margin:5px;
    padding:10px;
    min-width:50px;
}


/* setting border color depending on theme */
.theme--dark .right-pane {
    border-left : 1px solid #444 !important;
}
.theme--light .right-pane {
    border-left : 1px solid #DDD !important;
}



</style>



