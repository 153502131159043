<template>
    <v-container fluid id="tr_panel" ref="tr_panel" class="pa-2 pt-0" :style="{ height:'100%'}"> <!-- tr_panel-id is used in copybook to retrieve its size -->
        
        <!------ TRANSCRIPTION HEADER ----------->

        <div class="d-flex justify-start">
            <v-icon v-if="document && document.final" color="green" > mdi-check-bold </v-icon> 
            <v-icon v-if="document && !document.final"> mdi-pencil </v-icon> 
            <LocText text_id="transcription.title" class="ml-3" />
            <v-spacer/>
            <pre v-if="!expanded" class="code pt-1">{{label}}</pre>
            <pre v-if="!expanded" class="code pt-1"> tr: {{tr.name}}</pre>
            <v-spacer/>
            <div class="text-body-2">
                <locText v-if="document && document.autograph" text_id="transcription.autograph"/> 
            </div>
<!-- 
            <v-icon 
                v-if="!expanded" 
                @click="expand"  
                data-cy="tr-expand" > 
                mdi-chevron-double-up 
            </v-icon>
            <v-icon 
                v-if="expanded"  
                @click="collapse"
                data-cy="tr-collapse" > 
                > 
                mdi-chevron-double-down
            </v-icon>
 -->

        </div>


        <!------ VIEW TRANSCRIPTION ----------->
        <div v-if="document">

            <div v-if="expanded && ( !edit || !user )" >

                <div class="d-flex justify-start mt-2">

                    <TranscriptionField label_id="transcription.date" :lock='document.lock_date || document.final' class="mr-2">
                        <div v-if="tr.show_diffs">
                            <span v-for="x in tr.date_sent_diff" :key="x.id" :class="x.cls" >
                                {{x.text}}
                            </span>
                        </div>    
                        <div v-else>
                            <span v-if="tr.date_sent" data-cy="tr-date"> 
                                <!-- +12 ore cosi' non cambia il giorno tra ita:GMT-2 e california:GMT+7 -->
                                {{ $d( Date.parse(tr.date_sent + "T12:00:00" ) , 'short' )}} 
                            </span>
                        </div>
                    </TranscriptionField>

                    <TranscriptionField label_id="transcription.sender" :lock="document.lock_sender || document.final" class="mr-2">
                        <div v-if="tr.show_diffs">
                            <span v-for="x in tr.sender_diff" :key="x.id" :class="x.cls" >{{x.text}}</span>
                        </div>    
                        <div v-else data-cy="tr-sender">
                            {{tr.sender.name}} 
                        </div>
                    </TranscriptionField>     

                    <TranscriptionField label_id="transcription.recipient" :lock="document.lock_recipient || document.final" class="mr-2">
                        <div v-if="tr.show_diffs">
                            <span v-for="x in tr.recipient_diff" :key="x.id" :class="x.cls" >{{x.text}}</span>
                        </div>    
                        <div v-else data-cy="tr-recipient">
                            {{tr.recipient.name}} 
                        </div>
                    </TranscriptionField>     

                    
                    <TranscriptionField v-if="tr.cc_recipient.names" label_id="transcription.cc_recipient" :lock="document.lock_cc_recipient || document.final" class="mr-2">
                        <div v-if="tr.show_diffs">
                            <span v-for="x in tr.cc_recipient_diff" :key="x.id" :class="x.cls" >{{x.text}}</span>
                        </div>    
                        <div v-else data-cy="tr-cc-recipient">
                            {{tr.cc_recipient.names}}
                        </div>

                    </TranscriptionField>     

                </div>

                <TranscriptionField label_id="transcription.text" 
                    :lock="document.lock_transcription || document.final" 
                    :rows="text_rows" 
                    class="mt-2">
                    <div v-if="tr.show_diffs">
                        <span v-for="x in tr.text_diff" :key="x.id" :class="x.cls" >{{x.text}}</span>
                    </div>    
                    <div v-else data-cy="tr-text" style="white-space:pre-wrap;">
                        {{tr.text}}
                    </div>
                </TranscriptionField>     

                <TranscriptionField 
                    v-if="tr.bibliography"
                    label_id="transcription.bibliography" 
                    :lock="document.lock_bibliography || document.final" 
                    :rows="1"
                    class="mt-2"
                    style="min-height:40px; max-height:40px;"
                    >
                    <div v-if="tr.show_diffs">
                        <span v-for="x in tr.biblio_diff" :key="x.id" :class="x.cls" >{{x.text}}</span>
                    </div>
                    <div v-else data-cy="tr-biblio">
                        {{tr.bibliography}}
                    </div>

                       
                </TranscriptionField>     


                <!-------- SYNOPSIS -----------
                <TranscriptionField label_id="transcription.synopsis" :lock="document.lock_synopsis || document.final" :rows="5" class="mt-2">
                    <div v-if="tr.show_diffs">
                        <span v-for="x in tr.synopsis_diffs" :key="x.id" :class="x.cls" >{{x.text}}</span>
                    </div>    
                    <div v-else data-cy="tr-synopsis" >
                        {{tr.synopsis}}
                    </div>
                </TranscriptionField>     
                ------------>


                <!------ VIEW TRANSCRIPTION BOTTOM LINE ----------->

                <div class="d-flex justify-start align-center pt-3 pb-0 mb-0">

                    <div v-if="num_pages>1" class="pr-3">
                        <v-btn-toggle>
                            <v-btn 
                                small 
                                v-if="tr.show_history_flag"  
                                data-cy="hide-history"
                                @click="history_hide" > 
                                    <LocText text_id="transcription.hide_history" /> 
                            </v-btn>
                            <v-btn 
                                small 
                                v-if="!tr.show_history_flag" 
                                data-cy="show-history"
                                @click="history_show" > 
                                    <LocText text_id="transcription.show_history" /> 
                            </v-btn>
                        </v-btn-toggle>
                    </div>



                    <div v-if="tr.show_history" class="pr-3">
                        <v-btn-toggle>
                            <v-btn 
                                small 
                                data-cy="history-prev"
                                @click="history_prev"> 
                                    <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn small > 
                                {{page+1}} / {{ num_pages}} 
                            </v-btn>
                            <v-btn 
                                small 
                                data-cy="history-next"
                                @click="history_next">
                                    <v-icon >mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>    

                    <div v-if="num_pages && !tr.show_history" class="pr-3 text-caption">
                        <LocText text_id="transcription.version" />         {{page+1}} 
                        <LocText text_id="transcription.contributors" />  {{ document.contributors }} 
                    </div>

                    <div v-if="num_pages && tr.show_history" class="pr-3 text-caption">
                        <LocText text_id="transcription.version" />         {{page+1}} 
                        <span v-if="current_idx==page && tr.show_history" > * </span> 
                        <LocText text_id="transcription.contributed_by" />  {{tr.author}} 
                        <LocText text_id="transcription.the" />             {{ $d( Date.parse(tr.date_edit), 'short' )}} 
                    </div>

                    <div v-if="tr.show_history && page>0" class="pr-3">
                        <v-btn-toggle>
                            <v-btn 
                                small 
                                v-if="tr.show_diffs_flag"  
                                data-cy="hide-changes"
                                @click="diffs_hide" > 
                                    <LocText text_id="transcription.hide_changes" /> 
                            </v-btn>
                            <v-btn  
                                small 
                                v-if="!tr.show_diffs_flag" 
                                data-cy="show-changes"
                                @click="diffs_show" > 
                                    <LocText text_id="transcription.show_changes" /> 
                            </v-btn>
                        </v-btn-toggle>
                    </div>

                    <v-spacer/>
                        
                    <v-btn 
                        v-if="!user && !document.final" 
                        small
                        disabled 
                        >                                   
                            <LocText text_id="transcription.edit" /> 
                    </v-btn>

                    <v-btn 
                        v-if="user && !document.final"  
                        small
                        depressed color="primary" 
                        @click="on_edit"
                        data-cy="tr-edit"
                        >
                            <LocText text_id="transcription.edit" /> 
                    </v-btn>

                    <v-menu 
                        offset-y 
                        v-if="user && user.is_staff" 
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="error"
                            small
                            depressed
                            v-bind="attrs"
                            v-on="on"
                            class="ml-3"
                            >
                            staff
                            </v-btn>
                        </template>
                        <v-list v-if="document">

                            <v-list-item v-if="!document.final" @click="on_edit_lock" class="menu-item">
                                <v-list-item-title>
                                        <LocText text_id="transcription.edit_locks" /> 
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else >
                                <v-list-item-title class="menu-item-disabled">
                                        <LocText text_id="transcription.edit_locks" /> 
                                </v-list-item-title>
                            </v-list-item>


                            <v-list-item v-if="num_pages && !document.final" class="menu-item">
                                <v-list-item-title @click="on_make_current" >
                                    <LocText text_id="transcription.make_current" /> 
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else >
                                <v-list-item-title class="menu-item-disabled" >
                                    <LocText text_id="transcription.make_current" /> 
                                </v-list-item-title>
                            </v-list-item>


                            <v-list-item v-if="num_pages && document.transcription && !document.final" class="menu-item">
                                <v-list-item-title @click="on_make_final" >
                                    <LocText text_id="transcription.make_final" /> 
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="document.final" class="menu-item">
                                <v-list-item-title @click="on_revert_final" >
                                    <LocText text_id="transcription.revert_final" /> 
                                </v-list-item-title>
                            </v-list-item>



                            <v-divider />
                            <v-list-item v-if="num_pages && !document.final" class="menu-item">
                                <v-list-item-title @click="ask_delete_confirmation=true" >
                                    <LocText text_id="transcription.delete" /> 
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else >
                                <v-list-item-title class="menu-item-disabled" >
                                    <LocText text_id="transcription.delete" /> 
                                </v-list-item-title>
                            </v-list-item>



                        </v-list>
                    </v-menu>
                </div>
            </div>    
        </div>    

        <TranscriptionEditDlg
            :show="edit" 
            :transcription="tr_for_edit" 
            @done="on_edit_done" 
        />

        <TranscriptionEditLock
            :show="edit_lock" 
            :document="document" 
            @done="on_edit_lock_done" 
            @cancel="edit_lock=false" 
        />

        <Confirm
            :show="ask_delete_confirmation"  
            title="transcription.delete_confirm_title"
            text="transcription.delete_confirm_text"
            @yes="on_delete_confirmed()" 
            @no="ask_delete_confirmation=false" 
        />


    </v-container>
</template>

<script>
import DiffMatchPatch           from 'diff-match-patch'
import LocText                  from "../components/LocText"
import TranscriptionEditDlg     from "../components/TranscriptionEditDlg"
import TranscriptionEditLock    from "../components/TranscriptionEditLock"
import TranscriptionField       from "../components/TranscriptionField"
import Confirm                  from "../components/Confirm"
import { mapState      }        from 'pinia'
import { mapActions    }        from 'pinia'
import { useAppStore   }        from '../stores/appStore'
import { fetch_json }           from '../api'


export default {
    name: "Transcription",
    props : [ 'document', 'label' ],
    components: { TranscriptionEditDlg, TranscriptionEditLock, TranscriptionField, LocText, Confirm },    
    data () {
        return {
            expanded: true,
            edit    : false, 
            edit_lock    : false, 
            ask_delete_confirmation : false,

            differ  : null, 

            tr_list : [],                  // all the transcription versions
            page : 0,                      // index of the visible version
            num_pages : 0,                 // num of translation versions
            current_idx : -1,              // page of transcription pointed by the document

            tr_for_edit : null,            // input of transcription_edit -- set in on_edit                   

            tr : {                         // data for the 'view' mode
                name : "",
                author : "",               // fields updated when page changes 
                date_edit : "",
                date_sent : "",
                sender :    { name:'-' },  // person object 
                recipient : { name:'-' },  // person object 
                cc_recipient : { names:'-' },  // person object 
                text : "",
                bibliography : "",
                synopsis : "",

                show_history_flag : false, // persiste la cambio di doc
                show_diffs_flag : false,   // persiste la cambio di doc 
                show_history : false,      // show_history_flag && num_pages>1
                show_diffs : false,        // show_diffs_flag && num_pages>1 && page>0

                date_sent_diff :[],
                sender_diff :[],
                recipient_diff : [],
                cc_recipient_diff : [],
                text_diff : [],
                biblio_diff : [],
                synopsis_diff : [],

                bounded_resize : null,
                resize_ob : null,

            },
            text_height : 128,
            text_rows : -48,            
        }
    },
    created() {
            
            this.retrieve_tr_versions()
    },
    mounted () {
        //console.log("tr mounted")
        // call .bind as otherwise the `this` will not point to the component
        this.bounded_resize = this.on_resize.bind(this); 
        // https://usefulangle.com/post/319/javascript-detect-element-resize
        this.resize_ob = new ResizeObserver( this.bounded_resize );

        if(this.$refs.tr_panel)
            this.resize_ob.observe(this.$refs.tr_panel );
    },
    beforeUpdate() {
        //console.log("tr before update")
        if(this.$refs.tr_panel)
            this.resize_ob.unobserve( this.$refs.tr_panel );
    },
    updated() {
        //console.log("tr updated")
        this.$nextTick( function() {
            if(this.$refs.tr_panel)
                this.resize_ob.observe(this.$refs.tr_panel );
        })
    },
    watch : {
        document() {
            
            this.edit = false
            this.retrieve_tr_versions()
        },
    },
    computed : {
        ...mapState(   useAppStore, ['user'] ),
    },
    methods : {
        ...mapActions( useAppStore, [ 
            'get_person_by_url',
            'do_delete_transcription',
            'do_make_transcription_current',
            'do_make_transcription_final',
            'do_revert_transcription_final',
        ] ),

        expand(){
            this.expanded = true
            this.$emit('resized')
            this.$emit('expanded')
        },
        collapse(){
            this.expanded = false
            this.$emit('resized')
            this.$emit('collapsed')
        },
        reset_view() {
            this.tr_list = []
            this.page = 0
            this.num_pages = 0
            this.current_idx = -1
            this.tr_for_edit = null

            this.tr.name = ""
            this.tr.author = ""
            this.tr.date_edit = ""
            this.tr.date_sent = ""
            this.tr.sender = { name:'-' }
            this.tr.recipient = { name:'-' }
            this.tr.cc_recipient = { names:'-' }
            this.tr.text = ""
            this.tr.synopsis = ""
            this.tr.show_history = false,
            this.tr.show_diffs = false
            this.tr.bibliography = ""

            this.tr.date_sent_diff = []
            this.tr.sender_diff = []
            this.tr.recipient_diff = []
            this.tr.cc_recipient_diff = []
            this.tr.text_diff = []
            this.tr.biblio_diff = []
            this.tr.synopsis_diff = []


        },
        retrieve_tr_versions() {
            //console.log(" --- retrieve_tr_versions -- ")

            this.reset_view()

            if( !this.document ) {
                //console.log( "Transcription:: no document" )
                return 
            }    
            if( !this.document.name ) {
                //console.log( "Transcription:: no document name", this.document )
                return 
            }
            // document.contributors o document.transcription potrebbero essere cambiati --- li ricarico
            fetch_json( "GET", this.document.url, {}, "update_document_transcription", json => {
                this.document.transcription = json.transcription 
                this.document.contributors = json.contributors 

                // retrieve transcriptions list
                fetch_json( "GET", '/api/transcription/?published=true&document=' + this.document.name, {}, "retrieve_tr_versions", json => {
                    //console.log( 'json', json )
                    if( json && json.results ) {

                        this.tr_list = json.results
                        this.num_pages = this.tr_list.length
                    }
                    this.init_page()
                    this.update_view()
                })
            })
        },
        init_page() {
            // set this.page to the current transcription if exists
            this.page = Math.max( 0, this.num_pages-1 )
            this.current_idx = -1
            if( this.document.transcription ) {
                this.current_idx = this.tr_list.findIndex( item => item.url == this.document.transcription )
                if( this.current_idx != - 1)
                    this.page = this.current_idx
            }
        },
        history_show() {
            this.tr.show_history_flag=true
            this.update_view()
        },
        history_hide() {
            this.tr.show_history_flag=false
            this.init_page()
            this.update_view()
        },
        history_next() {
            if( this.page < this.num_pages-1 ) {
                this.page += 1
                this.update_view()
            }
        },
        history_prev() {
            if( this.page > 0 ) {
                this.page -= 1
                this.update_view()
            }
        },
        diffs_show() {
            this.tr.show_diffs_flag=true
            this.update_view()
        },
        diffs_hide() {
            this.tr.show_diffs_flag=false
            this.update_view()
        },
        update_view() {
            if( !this.num_pages ) {
                return
            }

            const t = this.tr_list[ this.page]

            const sender    = this.get_person_by_url( t.sender    )
            const recipient = this.get_person_by_url( t.recipient )

            let names = []
            t.cc_recipient.forEach( x => {
                const obj = this.get_person_by_url( x )
                if( obj ) { names.push( obj.name ) }
            })

            //console.log( 'Transcription::update_view sender=', sender, "recipient=",recipient ) 
            //console.log( 'Transcription::update_view t=', t ) 

            this.tr.name            = t.name
            this.tr.url             = t.url
            this.tr.author          = (t.author) ? t.author.first_name+" "+t.author.last_name  : "??"
            this.tr.date_edit       = t.date_created
            this.tr.date_sent       = t.date_sent
            this.tr.sender          = sender    // oggetto
            this.tr.recipient       = recipient // oggetto
            this.tr.cc_recipient    = { names:names.join(', ') } // oggetto
            this.tr.text            = t.transcription
            this.tr.bibliography    = t.bibliography
            this.tr.synopsis        = t.synopsis
 
            this.tr.show_history    = this.tr.show_history_flag && this.num_pages>1
            this.tr.show_diffs      = this.tr.show_history && this.tr.show_diffs_flag && this.page>0 
 
            this.tr.date_sent_diff = []
            this.tr.sender_diff = []
            this.tr.recipient_diff = []
            this.tr.cc_recipient_diff = []
            this.tr.text_diff = []
            this.tr.biblio_diff = []
            this.tr.synopsis_diff = []


            if( this.tr.show_diffs )
            {
                const t1 = this.tr_list[this.page-1]
                const t2 = this.tr_list[this.page  ]

                const date1      = t1.date_sent ? this.$d( Date.parse(t1.date_sent + "T12:00:00" ), 'short') : "";
                const sender1    = this.get_person_by_url( t1.sender    ).name
                const recipient1 = this.get_person_by_url( t1.recipient ).name
                let cc1 = []
                t1.cc_recipient.forEach( x => {
                    const obj = this.get_person_by_url( x )
                    if( obj && obj.name != '-' ) { cc1.push( obj.name ) }
                })

                const date2      = t2.date_sent ? this.$d( Date.parse(t2.date_sent + "T12:00:00" ), 'short') : "";
                const sender2    = this.get_person_by_url( t2.sender    ).name
                const recipient2 = this.get_person_by_url( t2.recipient ).name
                let cc2 = []
                t2.cc_recipient.forEach( x => {
                    const obj = this.get_person_by_url( x )
                    if( obj && obj.name != '-' ) { cc2.push( obj.name ) }
                })

                this.tr.date_sent_diff    = this.calc_diffs( date1,  date2  )
                //console.log( 'date_sent_diff',      this.tr.date_sent_diff )
                this.tr.sender_diff       = this.calc_diffs( sender1,          sender2          )
                //console.log( 'sender_diff',         this.tr.sender_diff )
                this.tr.recipient_diff    = this.calc_diffs( recipient1,       recipient2       )
                //console.log( 'recipient_diff',      this.tr.recipient_diff )
                this.tr.cc_recipient_diff = this.calc_diffs( cc1.join(', '),   cc2.join(', ')   )
                //console.log( 'cc_recipient_diff',   this.tr.cc_recipient_diff )
                this.tr.text_diff         = this.calc_diffs( t1.transcription, t2.transcription )
                //console.log( 'text_diff',           this.tr.text_diff )
              //this.tr.synopsis_diff     = this.calc_diffs( t1.synopsis,      t2.synopsis      )
                this.tr.biblio_diff       = this.calc_diffs( t1.bibliography,  t2.bibliography  )
                //console.log( 'biblio_diff',         this.tr.biblio_diff )
            }   
            this.$nextTick(  this.on_resize )
        },
        emit_resized() {
            this.$emit('resized')
        },
        calc_diffs( text1, text2 ) {
            
            if( !this.differ ) this.differ = new DiffMatchPatch()

            if( !text1 && !text2 )
                return []
            if( !text1 ) text1=""
            if( !text2 ) text2=""
            //console.log( "calc_diff  -- t1=", text1, " t2=", text2 )

            const diffs = this.differ.diff_main( text1, text2  )
            this.differ.diff_cleanupSemantic(diffs)
            //console.log("diffs", diffs)

            let counter = 0
            let results = []
            diffs.forEach( item => {
                //console.log( '   item', item )
                const op = item[0]
                const text = item[1]
                let obj = {} 
                obj.id = counter
                obj.text = text
                if( op ==-1 ) obj.text =  text.replace( '\n', '\\n' )
                if( op == 1 ) obj.text =  text.replace( '\n', '\\n\n' )

                obj.cls = "diff_normal"
                if( op ==  1 ) obj.cls = "diff_added"
                if( op == -1 ) obj.cls = "diff_removed"
                results.push(obj)
                counter +=1
            })
            return results
        },
        on_edit() {

            if( !this.document )
                return

            // se sto per editare una trascrizione
            // ed esiste una user_transcription sullo stesso documento e sullo stesso user
            // allora devo invece prendere quella

            const usr_id = this.user.id
            const doc_id = this.document.name
            const url = '/api/transcription/?published=false&author=' + usr_id +"&document=" + doc_id
            fetch_json( "GET", url, {}, "get user_transcription by author and doc", json => {    
                //console.log( "Transcription::on_edit check usr_tr", json )
                if( json.count > 0 ) {
                    this.tr_for_edit = json.results[0]
                }
                else
                {
                    if( this.num_pages ) {
                        this.tr_for_edit = this.tr_list[ this.page]
                    }
                    else
                    {
                        // se non ho nessuna trascrizione, ne faccio una 
                        let tr = {}
                        tr.name = this.document.name + '_T'
                        tr.author = this.user.url
                        tr.document_name = this.document.name
                        tr.date_created = ""
                        tr.date_sent = ""
                        tr.sender = ""
                        tr.recipient = ""
                        tr.cc_recipient = []
                        tr.synopsys = ""
                        tr.transcription = ""
                        tr.url = ""
                        tr.bibliography = ""
                        this.tr_for_edit = tr
                    }
                }
                // insert doc with full details
                this.tr_for_edit.document = this.document
                this.edit=true
            })    
        },
        on_edit_done(exit_mode) {
            console.log( 'Transcription, done_edit', exit_mode)
            this.edit=false
            this.tr_for_edit = null
            this.retrieve_tr_versions()        
            this.$emit('done-edit', exit_mode)     // <<<<<< to Copybook, to refresh the letters status
        },
        on_edit_lock() {
            //console.log("Transcription::on_edit_lock")
            this.edit_lock = true
        },
        on_edit_lock_done() {
            this.edit_lock = false
            this.retrieve_tr_versions()
        },
        on_make_current() {
            if( !this.num_pages )
                return 
            const t = this.tr_list[ this.page ]
            if( this.document.transcription == t.url ) {
                this.$toast.success( "this version was already the current one" )    
                return 
            }
            this.do_make_transcription_current( t, ()=>{
                //console.log( 'tr -- make current')
                this.retrieve_tr_versions()
            })        
        },
        on_make_final() {
            this.do_make_transcription_final( this.document, ()=>{
                this.document.final = true
                this.retrieve_tr_versions()
            })
        },
        on_revert_final() {
            this.do_revert_transcription_final( this.document, ()=>{
                this.document.final = false
                this.retrieve_tr_versions()
            })
        },
        on_delete_confirmed() {
            this.ask_delete_confirmation = false

            if( !this.num_pages )
                return 
            // se era la trascrizione corrente, 
            // la trascrizione corrente diventa la trascrizione piu recente, diversa da tr_current, se c'e'


            // const t = this.tr_list[this.page]
            // if( this.document.transcription == t.url && this.num_pages > 1) {
            //     let page = 0
            //     for( let i=0; i<this.num_pages; i++ ) {
            //         if( i != this.page )
            //             page = i
            //     }
            //     const t = this.tr_list[ page ]
            //     this.do_make_transcription_current( t, ()=>{
            //         this.on_delete_transcription()
            //     })
            // }
            // else {
            //     this.on_delete_transcription()
            // }

            this.on_delete_transcription()
        },
        on_delete_transcription() {
            // chiamato da  confirm >>> on_delete_confirmed >>> 
            const t = this.tr_list[ this.page ]
            this.do_delete_transcription( t, ()=>{

                //this.retrieve_tr_versions()
                //this.init_page()
                //this.update_view()

                // potrebbe essere azzerata la current-transcription
                // e comunque dopo una delete devo ricalcolare i contributors
                // per questo chiamo comunque make_current sull'ultima trascrizione
                //
                // l'unica pippa e' che non poso chiamare make_current 
                // se ho rimosso tutte le trascrizioni --- vabbe -- 
                //  comunque in questo caso i contributors non vi vedono 

                fetch_json( "GET", '/api/transcription/?published=true&document=' + this.document.name, {}, "retrieve_tr_versions", json => {
                    //console.log( 'json', json )
                    if( json && json.results ) {
                        const tr_list = json.results
                        const num_tr = tr_list.length
                        // tr_list is supposed to be sorted di ID == most recent as last
                        if( num_tr ) {
                            const new_current_tr = tr_list[ num_tr-1 ]
                            this.do_make_transcription_current( new_current_tr, ()=>{
                                //console.log( 'tr -- make current')
                                this.retrieve_tr_versions()
                            })        
                        }
                        else
                        {
                            this.retrieve_tr_versions()
                        }
                    }
                })
            })
        },
        on_resize( entries ) {
            //console.log("tr on_resize")
            if( !entries ) return
            this.emit_resized
            const rect = entries[0].contentRect;
            const panel_height = rect.height;
            const widgets_height = ( this.tr.bibliography) ? 185 : 138; 
            this.text_height = panel_height - widgets_height
            this.text_rows = - this.text_height
            //console.log('tr_panel text_rows=',this.text_rows);
        }
    }
}
</script>

<style scoped>
#tr_panel {
    background-color : var(--v-panel-base); 
}
.code {
    font-size: 70%;
}
.diff_normal {
    white-space: pre-wrap;
}
.diff_added {
    white-space: pre-wrap;
    background-color: #00800030 !important;
}
.diff_removed {
    white-space: pre-wrap;
    background-color: #ff000030 !important;
    text-decoration:line-through;
}
.menu-item:hover {
    background-color:#ddd;
    cursor:pointer;
}
.menu-item-disabled {
    color:#aaa;    
}
</style>
