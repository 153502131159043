<template>      
<!-- <v-container> -->
<!-- <v-row no-gutters> -->
<!-- <v-col no-gutters cols="9" class="mx-auto" > -->
    <div data-cy="view-home" class="topdiv" >
        <!--  
        <v-container>
            <v-row class="text-center">
                <v-col cols="9" class="mt-10 mx-auto" >
                    <v-img
                    :src="require('../assets/idea_letters_black.svg')"
                    class="mt-30"
                    contain
                    height="250"
                    />
                </v-col>
            </v-row>

            <v-row class="text-center">
                <v-col cols="9" class="mt-10 mx-auto" >
                    <H1 class="text-h5" >
                        Welcome to the IDEA Letters Platform
                    </H1>  
                    <p>
                        Il brano standard del Lorem Ipsum usato sin dal sedicesimo secolo è riprodotto qui di seguito per coloro che fossero interessati. Anche le sezioni 1.10.32 e 1.10.33 del "de Finibus Bonorum et Malorum" di Cicerone sono riprodotte nella loro forma originale, accompagnate dalla traduzione inglese del 1914 di H. Rackham.
                    </p>

                    <div class="my-8">
                        <Login/>
                        <v-btn large color="primary" class="mx-2" >Sign up</v-btn>
                    </div>  
                </v-col>
            </v-row>
        </v-container>
        -->

        <!-- 
        <v-img :aspect-ratio="2.85" :src="require('../assets/isa_banner.png')">
            <div v-bind:style="{paddingTop:'3rem', paddingLeft:'2rem'}">
                <div class="text-h5 sitetitle white--text" > <LocText text_id="home.title_line1" /> <br>    </div> 
                <div class="text-h2 sitetitle white--text" > <LocText text_id="home.title_line2" />         </div> 
                <div class="text-h2 sitetitle white--text" > <LocText text_id="home.title_line3" />         </div> 
                <div class="d-flex justify-start flex-wrap align-center pt-10 pb-2">
                    <div class="pr-4"><v-img width="100px" :src="require('../assets/logo_idea.svg' )"></v-img></div>
                    <div class="pr-4"><v-img width="100px" :src="require('../assets/seal_white.png')"></v-img></div>
                    <div class="pr-4"><v-img width="110px" :src="require('../assets/ASM_white.svg' )"></v-img></div>
                </div>
            </div>
        </v-img>
         -->

        <v-img 
            :aspect-ratio="2.85" 
            :src="require('../assets/banner.svg')" 
            class="banner_bg pt-6 mt-6"
            >
        </v-img>

        <div class="text-body-1 my-6 mx-3" > 
            <LocText text_id="home.intro" markdown="yes"/> 
        </div>
        
        <!--         
        <v-card outlined class="my-2">
            <v-card-text > 
                <LocText text_id="home.intro" markdown="yes"/> 
            </v-card-text>
        </v-card>
         -->
        <!--  --------------- CAROUSEL ---------------> 
        
        <v-responsive aspect-ratio="2.5" width="100%">
        <v-carousel
            cycle
            width="100%"
            height="100%"
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover

        >
            <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
            :src="slide.src"
            ztransition="slide-x-transition"
            >
            </v-carousel-item>
        </v-carousel>
        </v-responsive> 
        
<!--     
        <div class="d-flex justify-center flex-wrap align-stretch pa-1">

            <v-card  class="ma-2 d-flex flex-column"  max-width="260"  outlined>
                <v-card-title>
                    <v-icon class="pr-2">mdi-folder-multiple-outline</v-icon>
                    <LocText text_id="home.browse_title"/>
                </v-card-title> 
                <v-card-text>
                    <LocText text_id="home.browse_text"/>
                </v-card-text>
                <v-spacer/>
                <v-card-actions >
                    <v-spacer/>
                    <v-btn primary depressed @click="$router.push('/B')" >                    
                        <LocText text_id="home.browse_button"/>
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>

            </v-card>


            <v-card  class="ma-2 d-flex flex-column"  max-width="260"  outlined>
                <v-card-title>
                    <v-icon class="pr-2">mdi-magnify</v-icon>                    
                    <LocText text_id="home.search_title"/>
                </v-card-title>
                <v-card-text>
                    <LocText text_id="home.search_text"/>
                </v-card-text>
                <v-spacer/>
                <v-card-actions >
                    <v-spacer/>
                    <v-btn primary depressed >                    
                        <LocText text_id="home.search_button"/>
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>


            <v-card  class="ma-2 d-flex flex-column"  max-width="20"  outlined>
                <v-card-title>
                    <v-icon class="pr-2">mdi-feather</v-icon>
                    <LocText text_id="home.transcribe_title"/>
                </v-card-title>
                <v-card-text>
                    <LocText text_id="home.transcribe_text"/>
                </v-card-text>
                <v-spacer/>
                <v-card-actions >
                    <v-spacer/>
                    <v-btn primary depressed @click="$router.push('/guideline')" >
                        <LocText text_id="home.transcribe_button"/>
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </div>
     -->
     

        <v-container class="pa-0 ma-0" >
            <v-row no-gutters align="stretch" >
                <v-col no-gutters cols="12" lg="4" xl="4" >
                    <v-card height="100%" class="card1 mb-3 d-flex flex-column" >
                            <v-card-title>
                                <v-icon class="pr-2">mdi-folder-multiple-outline</v-icon>
                                <LocText text_id="home.browse_title"/>
                            </v-card-title> 
                            <v-card-text class="text-body-1 full-height">
                                <LocText text_id="home.browse_text" markdown="yes" />
                            </v-card-text>
                            <v-spacer/>
                            <v-card-actions >
                                <v-spacer/>
                                <v-btn dark depressed @click="$router.push('/B')" >                    
                                    <LocText text_id="home.browse_button"/>
                                </v-btn>
                                <v-spacer/>
                            </v-card-actions>
                    </v-card>
                </v-col>

                <v-col no-gutters cols="12" lg="4" xl="4" >
                    <v-card   height="100%" class="card2 mb-3 d-flex flex-column" >
                        <v-card-title>
                            <v-icon class="pr-2">mdi-magnify</v-icon>                    
                            <LocText text_id="home.search_title"/>
                        </v-card-title>
                        <v-card-text  class="text-body-1 full-height">
                            <LocText text_id="home.search_text"  markdown="yes"/>
                        </v-card-text>
                        <v-spacer/>
                        <v-card-actions >
                            <v-spacer/>
                            <v-btn dark depressed @click="do_show_search_filters(true)">                    
                                <LocText text_id="home.search_button"/>
                            </v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col no-gutters cols="12" lg="4" xl="4" >
                    <v-card   height="100%" class="card3 mb-3 d-flex flex-column" >
                        <v-card-title>
                            <v-icon class="pr-2">mdi-feather</v-icon>
                            <LocText text_id="home.transcribe_title"/>
                        </v-card-title>
                        <v-card-text  class="text-body-1 full-height">
                            <LocText text_id="home.transcribe_text"  markdown="yes"/>
                        </v-card-text>
                        <v-spacer/>
                        <v-card-actions >
                            <v-spacer/>
                            <v-btn dark depressed @click="$router.push('/guideline')" >
                                <LocText text_id="home.transcribe_button"/>
                            </v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>


        <!------ FOOTER ------>
        
        <v-card dark class="banner_bg">
                <v-card-title>
                    <div class="mx-auto pt-4 text-h6"><LocText text_id="home.footer_title"/></div> 
                </v-card-title>    
                <div class="d-flex justify-space-around py-4">
                    <div >
                        <v-img class="mx-auto" width="90px" :src="require('../assets/logo_idea.svg' )"></v-img>
                        <div class="text-body-1 mx-auto my-4" style="text-align:center"> IDEA: Isabella d'Este Archive</div>
                    </div>
                    <div >
                        <v-img class="mx-auto" width="100px" :src="require('../assets/seal_white.png')"></v-img>
                        <div class="text-body-1 mx-auto my-4" style="text-align:center"> UCI: University of California at Irvine</div>
                    </div>
                    <div >
                        <v-img class="mx-auto" width="100px" :src="require('../assets/ASM_white.svg' )"></v-img>
                        <div class="text-body-1 mx-auto my-4" style="text-align:center"> Archivio di stato di Mantova</div>
                    </div>
                </div>
                <v-divider/>
                <div class="d-flex justify-center py-4">
                    <LocText text_id="home.powered_by"/>
                    <v-img width="120px" max-width="120px" class="mx-3" :src="require('../assets/logo_cineca.svg' )"></v-img>
                </div>
        </v-card>    

    </div>
</template>

<script>
import LocText from "../components/LocText"
import { useAppStore }      from '../stores/appStore'
import { mapActions  }      from 'pinia'

export default {
    name:"Home",
    components: { LocText },

    data () {
        return {
            slides : [
                {   src: require('../assets/b1.jpg') },
                {   src: require('../assets/b2.jpg') },
                {   src: require('../assets/b3.jpg') },
                {   src: require('../assets/b4.jpg') },
                {   src: require('../assets/b5.jpg') },
                {   src: require('../assets/b6.jpg') },
                {   src: require('../assets/b7.jpg') },
                {   src: require('../assets/b8.jpg') },
                {   src: require('../assets/b9.jpg') },
                {   src: require('../assets/b10.jpg') },
                {   src: require('../assets/b11.jpg') },
                {   src: require('../assets/b12.jpg') },
            ]
        }
    },
    methods: {
        ...mapActions( useAppStore, [ 
                'do_show_search_filters',
            ]),
    }

}
</script>

<style scoped>
    .topdiv {
        max-width:960px;
        margin:auto;
    }
    .card1 {
        background-image: url("/static/letters/vue/img/folders_tr.svg") !important; 
        background-position: bottom right !important; 
        background-repeat: no-repeat !important; 
    }
    .card2 {
        background-image: url("/static/letters/vue/img/magnify_tr.svg") !important; 
        background-position: bottom right !important; 
        background-repeat: no-repeat !important; 
    }
    .card3 {
        background-image: url("/static/letters/vue/img/feather_tr.svg") !important; 
        background-position: bottom right !important; 
        background-repeat: no-repeat !important; 
    }
    .banner_bg {
        background-color: var( --v-banner-base ) !important; 
    }
    .z--full-height {
        height: 100%;
    }

</style>