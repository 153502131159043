<template>
    <v-container fluid data-cy="view-person"> 

        <!--- header ---->    
        <div class="d-flex justify-start align-centre flex-wrap">

            <div v-if="view_mode==0" class="text-h6 pagetitle pt-1" > <LocText text_id="person_page.persons" /> </div>

            <div v-if="view_mode==1" class="text-h6 pagetitle pt-1" >
                <router-link class="text-decoration-none router-link cy-persons"  to="/person/" > 
                    &nbsp; <LocText text_id="person_page.persons" /> 
                </router-link>
                / {{ person.name}}
            </div> 

            <v-spacer/>
            <span v-if="view_mode==0" class="pa-2" >
                filter names
            </span>
            <v-text-field outlined clearable dense
                v-if="view_mode==0"
                v-model="filter_text"
                data-cy="filter-person"
            />
            <v-spacer/>

            <div v-if="user" class="pl-6">
                <v-btn 
                    color="primary" dark  depressed 
                    @click="show_create_dialog=true" 
                    data-cy="person-add"
                    > 
                    <LocText text_id="person_page.new" />  
                </v-btn>
            </div>
            <div v-if="user && view_mode==1" class="pl-6">
                <v-btn 
                    color="primary" dark depressed 
                    @click="show_edit_dialog=true" 
                    data-cy="person-edit"
                    > 
                    <LocText text_id="person_page.edit" /> 
                </v-btn>
            </div>
            <div v-if="user && view_mode==1" class="pl-6">
                <v-btn 
                    color="error" dark depressed 
                    @click="ask_delete_confirmation=true"
                    data-cy="person-delete"
                    > 
                    <LocText text_id="person_page.delete" /> 
                </v-btn>
            </div>

            <!-- view mode 
            <v-btn-toggle v-model="view_mode" class="pl-6">
                <v-btn small ><v-icon >mdi-format-list-bulleted</v-icon></v-btn>
                <v-btn small ><v-icon >mdi-account-box</v-icon></v-btn>
            </v-btn-toggle>
            -->

            <!-- next / prev page  -->
            <div v-if="view_mode==1" class="pl-6">
                <v-btn-toggle>
                    <v-btn small 
                        data-cy="person-prev"  
                        @click="prev_person"> 
                            <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn small 
                        data-cy="person-num" > 
                        {{person.id}}
                    </v-btn>
                    <v-btn small 
                        data-cy="person-next"  
                        @click="next_person">
                            <v-icon >mdi-chevron-right</v-icon>
                    </v-btn>
                </v-btn-toggle>
                <v-spacer/>
            </div>


        </div>
                
        <!-- view as table --->
        <div v-if="view_mode==0" class="pt-3">
            <v-data-table
                mobile-breakpoint="100"
                :headers="table_headers"
                :items="filtered_list"
                :items-per-page="200"
                dense
                @click:row="person_clicked">

                    <template v-slot:header.name="      { header }" > <LocText :text_id="header.text" /> </template>
                    <template v-slot:header.long_name=" { header }" > <LocText :text_id="header.text" /> </template>
                    <template v-slot:header.note="      { header }" > <LocText :text_id="header.text" /> </template>

            </v-data-table>
        </div>

        <!-- view details --->
        <div v-if="view_mode==1" >
            <div class="pt-3 pl-5" >
                <div class="d-flex py-2"> <div class="person_lab"><LocText text_id="person_page.name"      />: </div> {{person.name}}      </div>
                <v-divider />
                <div class="d-flex py-2"> <div class="person_lab"><LocText text_id="person_page.long_name" />: </div> {{person.long_name}} </div>
                <v-divider />
                <div class="d-flex py-2"> <div class="person_lab"><LocText text_id="person_page.note"      />: </div> {{person.note}}      </div>
                <v-divider />
            </div>


            <div v-if="transcriptions.length">
                <div class="text-h6 pl-2 pt-5"> <LocText text_id="person_page.referred_by"/>: </div>
                <div class="pl-2" > {{ transcriptions.length }} <LocText text_id="person_page.referred_by_tr"/> </div>
                
                <div class="d-flex justify-start flex-wrap pa-4">
                    <v-chip label 
                        v-for="t in transcriptions" 
                        :key="t.name"
                        @click="on_transcription_clicked(t.document_name)"
                        >
                    {{t.document_name}}
                    </v-chip>
                </div>
            </div>

        </div>


        <PersonAdd 
            :show="show_create_dialog"  
            :person_list="person_list" 
            @done="show_create_dialog=false" 
            @created="on_created"
        />

        <PersonEdit
            :show="show_edit_dialog"  
            :person="person" 
            @done="show_edit_dialog=false" 
        />

        <Confirm
            :show="ask_delete_confirmation"  
            title="person_delete.confirm_title"
            text="person_delete.confirm_text"
            @yes="on_delete_person()" 
            @no="ask_delete_confirmation=false" 
        />

    </v-container>    
</template>

<script>
import Confirm    from "../components/Confirm"
import PersonAdd  from "../components/PersonAdd"
import PersonEdit from "../components/PersonEdit"
import LocText    from "../components/LocText"

import { mapState    }   from 'pinia'
import { mapActions  }   from 'pinia'
import { useAppStore }   from '../stores/appStore'

export default { 
    name: "Person",    
    components: { PersonAdd, PersonEdit, LocText, Confirm },
    data() {
        return {
            view_mode               : 0, // 0=table, 1=single person
            person                  : null,
            person_idx              : 0,
            transcriptions          : [],
            show_create_dialog      : false,
            show_edit_dialog        : false,
            ask_delete_confirmation : false,
            filter_text             : "",
            filtered_list           : [],

            table_headers: [    
                 { text: 'person_page.name',       value: 'name'  },
                 { text: 'person_page.long_name',  value: 'long_name' },
                 { text: 'person_page.note',       value: 'note' },
            ],

        }
    },
    mounted() {
        this.do_filter()        
        this.on_load()
    },
    watch: {
        $route() {
            this.on_load()
        },
        person_list() {
            this.on_load()
            this.do_filter()
        },
        filter_text() {
            this.do_filter()
        }
    },
    computed : {
        ...mapState( useAppStore, ['user', 'person_list'] ),
    },
    methods : {

        ...mapActions( useAppStore, [ 
            'do_delete_person',
            'get_transcriptions_by_person',
        ] ),

        on_load() {

            const params = this.$route.params['query']
            //console.log( 'Person::on_Load - params=', params )

            this.show_create_dialog  = false 
            this.show_edit_dialog = false 

            // retrieve the requested person, if any
            this.person_idx = 0
            this.view_mode = 0
            this.transcriptions = []
            if( params != "" )
            {
                const idx = this.person_list.findIndex( item => item.id == params )
                if( idx != -1 )
                {
                    this.person_idx = idx
                    this.view_mode = 1
                    this.person = this.person_list[this.person_idx]
                    this.get_transcriptions_by_person( this.person, (results)=> {
                        this.transcriptions = results
                    })
                }
            }
            this.person = this.person_list[this.person_idx]

        },
        next_person() {
            const i = this.person_idx
            const j = ( i<this.person_list.length-1 ) ? this.person_idx+1 : 0;
            const path = "/person/"+this.person_list[j].id
            this.$router.replace( path )
        },
        prev_person() {
            const i = this.person_idx
            const j = ( i>0 ) ? this.person_idx-1 : this.person_list.length-1;
            const path = "/person/"+this.person_list[j].id
            this.$router.replace( path )
        },
        person_clicked( person ) {
            this.$router.replace( "/person/"+person.id )
        },
        on_delete_person() {
            this.ask_delete_confirmation=false
            this.do_delete_person( this.person, ()=>this.on_load() )
        },
        on_created( json ) {
            if( json && json.id )
                this.$router.replace( "/person/"+json.id )
        },
        on_transcription_clicked( name ) {
            //console.log("on_transcription_clicked",doc)
            this.$router.push( '/' + name )
        },
        do_filter() {
            
            if( !this.filter_text || this.filter_text == "" ) {
                this.filtered_list = this.person_list
            }
            else
            {
                const ft = this.filter_text.toLowerCase()
                this.filtered_list = this.person_list.filter( person => 
                    person.name.toLowerCase().includes( ft ) || 
                    person.long_name.toLowerCase().includes( ft) 
                )
            }
        }
    },
}
</script>
<style scoped>

.person_lab {
    min-width:150px;
    padding-right:10px;
}
.v-chip { 
    margin:2px;
    min-width:105px;
}
</style>



