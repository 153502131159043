<template>
    <v-container fluid pa-0 data-cy="view-guideline">
        <div class="d-flex justify-start pa-1" v-if="user && user.is_staff && lang_edit">
            <v-btn v-if="!edit" small depressed color="primary"  @click="edit=true"  > edit   </v-btn>
            <!-- <v-btn v-if="edit"  small depressed color="primary"  @click="on_save()"  > save   </v-btn>
            <v-btn v-if="edit"  small depressed class="pa-3"     @click="edit=false" > cancel </v-btn> -->
        </div>    
        <div v-if="edit">
            <TextEditor 
                v-bind:text="$t('page.transcription_criteria')" 
                v-on:save="on_save($event)"
                v-on:cancel="on_cancel()"
            />
        </div>
        <div v-else v-html="$t('page.transcription_criteria')" class="pa-3 text-body">
        </div>
    </v-container>
</template>

<script>
import TextEditor      from "../components/TextEditor"
import { mapState }    from 'pinia'
import { mapActions }  from 'pinia'
import { useAppStore } from '../stores/appStore'


export default { 
    name: "About",  
    components: { TextEditor },
    data() {
        return {
            text:"",
            edit:false,
        }
    },
    mounted () {
        this.init()
    },
    computed : {
        ...mapState(   useAppStore, ['user', 'lang_ita', 'lang_eng', 'lang_current', 'lang_edit'] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 'set_site_text'] ),

        init() {
            this.text=this.$t('page.transcription_criteria')
        },
        on_save( value ) {
            let text_ita = this.lang_ita['page']['transcription_criteria']
            let text_eng = this.lang_eng['page']['transcription_criteria']

            if( this.lang_current == "it") {
                text_ita = value
            } else {
                text_eng = value
            }
            this.set_site_text( 'page.transcription_criteria', text_ita, text_eng)
            this.edit=false
            this.init()
        },
        on_cancel() {
            this.edit=false
        }
    }
}
</script>



<style scoped >
.text-body {
    margin: 20px !important;
}
.text-body a {
    pointer-events: none;
}
.text-body h1 {
    margin: 10px 0 20px !important;
}
.text-body h2 {
    margin: 10px 0 20px !important;
}
.text-body h3 {
    margin: 10px 0 20px !important;
}
.text-body blockquote {
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding-left: 1em;
    margin: 20px 0 !important;
}
.text-body code {
    padding: 0 4px !important;
    margin: 0 5px !important;
}
.text-body pre code {
    padding: 8px !important;
    margin: 0 5px !important;
}
.text-body code:before {
    content: none !important;
    letter-spacing: initial !important;
}
.text-body code:after {
    content: none !important;
    letter-spacing: initial !important;
}
.text-body p {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    min-height: 1rem;
}
</style>

