<template>
    <v-container fluid  class="pa-0, ma-0" > 
        <div class="d-flex justify-start flex-wrap">
            <div class="text-h6  pt-1" >Test</div>
        </div>
                
        <Splitter 
            :header_size="12*2 + 64 + 36" 
            @h1="on_h1"
            @h2="on_h2"
        >
            <template v-slot:top-panel>
                <div class="orange" v-bind:style="{ height:h1 }"> top </div>
            </template>
            <template v-slot:bottom-panel>
                <div class="pink" v-bind:style="{ height:h2 }"> bottom </div>
            </template>

        </Splitter>

    </v-container>    
</template>

<script>
import Splitter  from "../components/Splitter"

export default { 
    name: "TestPage",    
    components: { Splitter },
    data() {
        return {
            h1:100,
            h2:100,
        }
    },
    mounted () {
    },
    destroyed() {
    },    
    methods : {
        on_h1(h1) { this.h1 = h1 },
        on_h2(h2) { this.h2 = h2 }
    },
}
</script>






