<template>
    <v-container fluid data-cy="view-history"> <!-- fluid => not using the column layout -->
        <div class="d-flex justify-start flex-wrap">

            <div class="text-h6 pagetitle pt-1" > <LocText text_id="history.title"/> </div>
            <v-spacer/>

            <!-- view mode -->
            <v-btn-toggle v-model="view_mode" class="pl-6">
                <v-btn small ><v-icon >mdi-view-grid-outline</v-icon></v-btn>
                <v-btn small ><v-icon >mdi-format-list-bulleted</v-icon></v-btn>
            </v-btn-toggle>

        </div>
                
        <!-- view as table --->
        <div v-if="view_mode==0" class="pt-3">
            <v-data-table
                mobile-breakpoint="100"
                :headers="lang_current=='en' ? table_headers_eng : table_headers_ita"
                :items="history"
                :items-per-page="100"
                dense
                @click:row="on_item_click">

                <template v-slot:item.date="{ item }">
                    <span>{{ $d( Date.parse(item.date), 'long') }}</span>
                </template>

            </v-data-table>
        </div>

        <!-- view as icons --->
        <div v-if="view_mode==1" class="pt-3">
        </div>

    </v-container>    
</template>

<script>
import LocText          from "../components/LocText"
import { mapState }     from 'pinia'
//import { mapActions }   from 'pinia'
import { useAppStore }  from '../stores/appStore'

export default { 
    name: "History",    
    components: { LocText },
    data() {
        return {
            view_mode : 0, // 0=table, 1=single person

            table_headers_ita: [    
                 { text: 'pagina',        value: 'page'  },
                 { text: 'data',          value: 'date' },
            ],
            table_headers_eng: [    
                 { text: 'page',          value: 'page'  },
                 { text: 'date',          value: 'date' },
            ],
        }
    },
    created() {
        this.on_load()
    },
    computed : {
        ...mapState( useAppStore, ['history', 'lang_current'] ),
    },
    methods : {

        // ...mapActions( useAppStore, [ 
        //     'load_persons',
        //     'do_delete_person',
        //     'do_save_person',
        //     'get_person_by_url', 
        //     'get_person_by_long_name',
        // ] ),

        on_load() {
            //console.log('history::on_load')
        },
        on_item_click( item ) {
            this.$router.replace( item.page )
        },
    },
}

</script>
<style scoped>
</style>



