<template>
     <!-- id="transcription"  -->
    <v-container fluid ref="tr_panel" :style="{ height:'100%'}" class="ma-0, pa-0">

    <!-- <v-sheet color="#ece8df" class="px-4 pb-4 pt-2" :style="{ height:'100%' }"> -->
    <v-sheet color="panel" class="px-4 pb-4 pt-2" :style="{ height:'100%' }">

        <div class="d-flex justify-start">
            <LocText text_id="transcription.editor_title" />
            <span v-if="transcription" class="pl-3"> {{transcription.name}} </span>
            <span v-if="transcription && !transcription.published" class="pl-3"> [ DRAFT ] </span>
            <v-spacer/>
        </div>
        <v-form v-model="valid" color="white" class="pt-2">

            <div v-if="transcription && transcription.document"> <!-- may happen while closing-->

                <div class="d-flex justify-start pt-0 " >
                    <v-text-field 
                        filled
                        dense 
                        background-color = "field"
                        v-model="transcription.date_sent" 
                        placeholder =  "yyyy-mm-dd"
                        :rules = date_rules
                        class="text-body-2 mr-3"
                        style="max-width:120px;"
                        :disabled="transcription.document.lock_date"
                        data-cy="tre-date">

                        <template v-slot:label>
                            <LocText text_id="transcription.date" />
                            <v-icon 
                                small 
                                style="vertical-align: top"
                                v-if="transcription.document.lock_date"
                                >
                                mdi-lock
                            </v-icon>
                        </template>
                    </v-text-field>


                    <v-autocomplete
                        filled
                        dense 
                        label="sender"
                        background-color = "field"
                        :items="person_list" 
                        item-text="long_name" 
                        v-model="sender_name" 
                        data-cy="tre-sender"
                        class="text-body-2"
                        :disabled="transcription.document.lock_sender"
                        id="tre-sender">

                        <template v-slot:label>
                            <LocText text_id="transcription.sender" />
                            <v-icon 
                                small 
                                style="vertical-align: top"
                                v-if="transcription.document.lock_sender"
                                >
                                mdi-lock
                            </v-icon>
                        </template>

                    </v-autocomplete>

                    <v-btn icon small depressed @click="show_add_person=true" class="mt-3" > 
                        <v-icon small>mdi-plus</v-icon>
                    </v-btn> 

                    <v-autocomplete
                        filled
                        dense 
                        background-color = "field"
                        :items="person_list" 
                        item-text="long_name" 
                        v-model="recipient_name" 
                        data-cy="tre-recipient"
                        class="text-body-2"
                        :disabled="transcription.document.lock_recipient"
                        id="tre-recipient">

                        <template v-slot:label>
                            <LocText text_id="transcription.recipient" />
                            <v-icon 
                                small 
                                style="vertical-align: top"
                                v-if="transcription.document.lock_recipient"
                                >
                                mdi-lock
                            </v-icon>
                        </template>

                    </v-autocomplete>
                    <v-btn icon small depressed @click="show_add_person=true" class="mt-3">
                        <v-icon small >mdi-plus</v-icon>
                    </v-btn>
                </div>

                <div class="d-flex justify-start">
                    <v-autocomplete
                        filled
                        dense 
                        label="cc addressee"
                        background-color = "field"
                        
                        multiple
                        :items="person_list" 
                        v-model="cc_recipient_names" 
                        item-text="long_name" 
                        
                        data-cy="tre-sender-place"
                        class="text-body-2"
                        id="tre-cc-recipient"
                        :disabled="transcription.document.lock_cc_recipient"
                        @change="on_delayed_resize"
                        >

                        <template v-slot:label>
                            <LocText text_id="transcription.cc_recipient" />
                            <v-icon 
                                small 
                                style="vertical-align: top"
                                v-if="transcription.document.lock_cc_recipient"
                                >
                                mdi-lock
                            </v-icon>
                        </template>

                    </v-autocomplete>
                </div>

                <div class="d-flex justify-start">
                    <v-textarea   
                        ref="tr_text_widget"
                        filled
                        dense
                        hide-details
                        no-resize
                        :height="text_height + 'px'"
                        background-color = "field"
                        v-model="transcription.transcription"  
                        :disabled="transcription.document.lock_transcription"
                        class="text-body-2"
                        data-cy="tre-text">

                        <template v-slot:label>
                            <LocText text_id="transcription.text" />
                            <v-icon 
                                small 
                                style="vertical-align: top"
                                v-if="transcription.document.lock_transcription"
                                >
                                mdi-lock
                            </v-icon>
                        </template>

                    </v-textarea>
                </div>

                <div class="d-flex justify-start">
                    <v-textarea   
                        filled
                        dense
                        hide-details
                        no-resize
                        :height="1 + 'rem'"

                        background-color = "field"
                        v-model="transcription.bibliography"  
                        :disabled="transcription.document.lock_bibliography"
                        class="text-body-2 pt-6"
                        data-cy="tre-biblio">

                        <template v-slot:label>
                            <LocText text_id="transcription.bibliography" />
                            <v-icon 
                                small 
                                style="vertical-align: top"
                                v-if="transcription.document.lock_bibliography"
                                >
                                mdi-lock
                            </v-icon>
                        </template>

                    </v-textarea>

                </div>



    <!--    ---synopsis---

                <div class="d-flex justify-start pt-6">
                    <v-textarea   
                        filled
                        dense
                        z-hide-details
                        no-resize
                        rows="2"
                        row-height="15"
                        background-color = "field"
                        v-model="transcription.synopsis"  
                        :disabled="transcription.document.lock_synopsis"
                        class="text-body-2"
                        data-cy="tre-synopsis">

                        <template v-slot:label>
                            <LocText text_id="transcription.synopsis" />
                            <v-icon 
                                small 
                                style="vertical-align: top"
                                v-if="transcription.document.lock_synopsis"
                                >
                                mdi-lock
                            </v-icon>
                        </template>

                    </v-textarea>
                </div>
    -->


                <!--- BOTTOM ------------------------------------>

                <div class="d-flex justify-start pt-4">
                    <v-spacer/>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                color="primary" depressed 
                                class="ml-3"  
                                @click="on_save" 
                                v-bind="attrs" v-on="on"
                                data-cy="tre-save"
                                > 
                                <LocText text_id="transcription.save"   /> 
                            </v-btn>
                        </template>
                        <span style="white-space: pre-line;">
                            <LocText text_id="transcription.save_tooltip" /> 
                        </span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                color="primary" depressed
                                class="ml-3"  
                                    @click="on_submit" 
                                    data-cy="tre-submit"
                                    v-bind="attrs" v-on="on"> 
                                    <LocText text_id="transcription.submit" /> 
                            </v-btn>
                        </template>
                        <span style="white-space: pre-line;" >
                            <LocText text_id="transcription.submit_tooltip" /> 
                        </span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                class="ml-3" depressed 
                                @click="on_cancel" 
                                data-cy="tre-cancel"
                                v-bind="attrs" v-on="on"> 
                                    <LocText text_id="transcription.cancel" /> 
                            </v-btn>
                        </template>
                        <span style="white-space: pre-line;">
                            <LocText text_id="transcription.cancel_tooltip" /> 
                        </span>
                    </v-tooltip>
                </div>
                <PersonAdd 
                    :show="show_add_person" 
                    @done="show_add_person=false" 
                />
            </div>
        </v-form>
    </v-sheet>
    </v-container>
</template>

<script>

import PersonAdd        from "../components/PersonAdd"
import LocText          from "../components/LocText"
import { useAppStore }  from '../stores/appStore'
import { mapState }     from 'pinia'
import { mapActions }   from 'pinia'


export default {
    name: "TranscriptionEdit",
    props : [ 'show', 'transcription', 'height' ],
    // expect transcription prop to have a member document, 
    // and document should be a doc_details --- not just the url

    components: { PersonAdd, LocText },//, Dragon, splitter },
    data () {
        return {
            valid : false,
            sender_name : "",
            recipient_name : "",
            cc_recipient_names : [],

            show_dlg : false,
            show_add_person : false,

            bounded_resize : null,
            resize_ob : null,

            date_rules: [
                value => {
                    if( !value ) return 'required'
                    const reg = /^\d{4}-\d{2}-\d{2}$/
                    if( !reg.test(value) ) return 'invalid format'
                    const tok = value.split('-')
                    const y = parseInt(tok[0])
                    const m = parseInt(tok[1])
                    const d = parseInt(tok[2])
                    if ( y<1490 || y>1540 ) return "year must be in 1490..1540"
                    if ( m<1    || m>12   ) return "month must be in 01..12"
                    if ( d<1    || d>31   ) return "day must be in 01..31"
                    return true
                }
            ],

            text_height : '52px',
        }
    },
    mounted () {
        // call .bind as otherwise the `this` will not point to the component
        this.bounded_resize = this.on_resize.bind(this); 
        // https://usefulangle.com/post/319/javascript-detect-element-resize
        this.resize_ob = new ResizeObserver( this.bounded_resize );

        if( this.show) {
            this.on_edit()
        }
    },
    beforeDestroy() {
        //console.log('TranscriptionEdit component before destroy')
        this.end_editing()
    },
    beforeUpdate() {
        if(this.$refs.tr_panel) {
            //console.log( 'unobserve', this.$refs.tr_panel )
            this.resize_ob.unobserve( this.$refs.tr_panel );
        }
    },
    updated() {
        this.$nextTick( function() {
            if(this.$refs.tr_panel) {
                //console.log( 'observe', this.$refs.tr_panel )
                this.resize_ob.observe( this.$refs.tr_panel );
            }
        })
    },    
    watch : {
        show() {
            if( this.show) {
                this.on_edit()
            }
        },
    },
    computed : {
        ...mapState( useAppStore, ['user', 'person_list'] ),
    },
    methods : {

        ...mapActions( useAppStore, [ 
            'remove_transcription' ,
            'get_person_by_url', 
            'get_person_by_long_name',
            'doc_name',
            'load_user_transcriptions',
            'begin_editing',
            'done_editing',
            'end_editing',
            'get_transcriptions_by_docname',
            'do_create_transcription',
            'do_save_transcription',
            'do_make_transcription_current',
        ] ),

        on_resize( entries ) {
            if( !entries ) return
            const rect = entries[0].contentRect;
            const height = rect.height;
            this.text_height = height - 330
            //console.log( 'on_resize', this.text_height)
        },
        on_delayed_resize() {
            //this.$nextTick( this.on_resize )
        },

        on_edit() {

            if( !this.transcription ) {
                //console.error( "TranscriptionEditor::on_edit bad tr", this.transcription )
                return 
            }
            if( !this.transcription.document ) {
                //console.error( "TranscriptionEditor::on_edit no doc in tr", this.transcription )
                return 
            }
            //debugger
            this.begin_editing( this.transcription.document )
            this.sender_name    = this.get_person_by_url( this.transcription.sender ).long_name
            this.recipient_name = this.get_person_by_url( this.transcription.recipient ).long_name

            this.cc_recipient_names = []
            this.transcription.cc_recipient.forEach( x => {
                const obj = this.get_person_by_url( x )
                if( obj ) { this.cc_recipient_names.push( obj.long_name ) }
            })            

            this.show_dlg = true
            //setTimeout( this.on_delayed_resize, 200 )

        },
        on_cancel() {
            this.show_dlg=false
            this.$emit('done', 'cancel') 
            this.done_editing( this.transcription.document )
        },

        // SUBMIT E SAVE 
        //
        // abbiamo tre variabili : 
        // 1) stiamo facendo save o submit          
        // 2) la transcrizione esisteva o no        
        // 3) la transcrizione era pubblica o no    
        //
        // i casi che devo testare sono :
        //
        // submit   not-exist                        - creo pubblica           
        // submit       exist        was-published   - NON DEVO SOVRASCRIVERE - ne va fatto uno nuovo, pubblico
        // submit       exist    not-was-published   - sovrascrivo - diventa pubblica - la versione privata sparisce            
        // save     not-exist                        - creo privato
        // save         exist        was-published   - NON DEVO SOVRASCRIVERE - creo privato, ma la versione pubblica rimane
        // save         exist    not-was-published   - sovrascrivo resta privato  


        on_submit() {   
            // retrieve the highest_version_number
            // set name and set publish=true 
            // then calls on_store

            if( !this.transcription )
                return 

            // if this was a published transcription
            // we reset the url, so it will be stored as a new object
            if( this.transcription.url && this.transcription.published )                
                this.transcription.url = "" 

            // moved in the backend --- restored, in the backend is not working

            const doc_name = this.transcription.document.name
            this.get_transcriptions_by_docname( doc_name, (json) => {
                //console.log( "transcription docs", json )
                let highest_version_number = 0
                json.results.forEach( tr => {
                    const pos = tr.name.indexOf('_T')
                    if( pos != -1 ) 
                    {
                        const name2 = tr.name.substring( pos+2)
                        const num = parseInt(name2)
                        if( num > highest_version_number ) // fallisce se num==nan
                            highest_version_number = num
                    }
                })
                //console.log( 'highest_version_number', highest_version_number)
                highest_version_number += 1
                this.transcription.name = doc_name + "_T" + highest_version_number
                this.transcription.published = true
                this.on_store('submit')
            })
        },
        on_save() { 
            this.exit_mode = 'save'

            // set name and set publish=false
            // then calls on_store

            // if this was a published transcription
            // we reset the url, so it will be stored as a new object

            if( !this.transcription )
                return 

            //const doc_name = this.transcription.document.name //this.doc_name( this.transcription.document )
            //this.transcription.name = doc_name + "_T"

            if( this.transcription.published )
                this.transcription.url = false

            this.transcription.published = false

            // moved in the backend --- restored, in the backend is not working
            const doc_name = this.transcription.document.name
            this.get_transcriptions_by_docname( doc_name, (json) => {
                let highest_version_number = 0
                json.results.forEach( tr => {
                    const pos = tr.name.indexOf('_T')
                    if( pos != -1 ) 
                    {
                        const name2 = tr.name.substring( pos+2)
                        const num = parseInt(name2)
                        if( num > highest_version_number ) // fallisce se num==nan
                            highest_version_number = num
                    }
                })
                //console.log( 'highest_version_number', highest_version_number)
                highest_version_number += 1
                this.transcription.name = doc_name + "_T" + highest_version_number
                this.transcription.published = false
                this.on_store('save')
            })
        },
        on_store(exit_mode) {
            console.log('Editor, exit_mode', exit_mode)
            // if the transcription exists (has a url) 
            // update it, otherwise create it

            const create = !this.transcription.url 

            //const csrf = Cookies.get('csrftoken')

            const sender    = this.get_person_by_long_name( this.sender_name    )
            const recipient = this.get_person_by_long_name( this.recipient_name )

            let cc_recipient = []
            this.cc_recipient_names.forEach( x => {
                const obj = this.get_person_by_long_name( x )
                if( obj ) cc_recipient.push( obj.url )
            })

            let data = {
                document:   this.transcription.document.url,
                name:       this.transcription.name,
                author:     this.user.url,                    
                published:  this.transcription.published,  
            }
            if(this.transcription.date_sent    ) data.date_sent=        this.transcription.date_sent
            if(this.transcription.transcription) data.transcription=    this.transcription.transcription 
            if(this.transcription.synopsis     ) data.synopsis=         this.transcription.synopsis      
            if( sender    && sender.url        ) data.sender=           sender.url    
            if( recipient && recipient.url     ) data.recipient=        recipient.url 
            if( cc_recipient                   ) data.cc_recipient=     cc_recipient
            if(this.transcription.bibliography ) data.bibliography =    this.transcription.bibliography

            var self = this
            function done_storing() {
                self.show_dlg=false
                self.$emit('done', exit_mode) 
                self.done_editing( self.transcription.document )
                self.load_user_transcriptions()
            }
            //console.log('tr=',this.transcription)
            if( create )
                this.do_create_transcription(data, (json)=>{
                    //console.log( "do_create_transcription", json )
                    if( json && json.url && json.published ) {
                        this.do_make_transcription_current( json, done_storing )
                    }
                    else
                    {
                        done_storing()
                    }

                })
            else 
            {
                this.do_save_transcription( this.transcription.url, data, (ok)=> {
                    if( ok && data.published ) {

                        //se la transcription e' pubblica, devo fare make_current
                        //do-save non ritorna il json, utilizzo data, dove va aggiunta la url

                        data.url = this.transcription.url
                        //console.log( "do_save_transcription", ok, data ) 

                        this.do_make_transcription_current( data, done_storing )
                    }
                    else
                    {
                        done_storing()
                    }
                })
            }
        },
    }
}
</script>

<style scoped>
::v-deep .v-textarea textarea  {
    line-height: 1.2rem !important;
}
</style>
