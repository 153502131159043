import Vue from "vue"
import Cookies from 'js-cookie'

export function report_fetch_error( error ) {
    //example:  error.name = "TypeError", error.message = "Failed to Fetch", error.stack = " TypeError: Failed to fetch\n ... lo stack ... " 
    console.error( 'report_fetch_error:', 'stack:', error.stack  )
    Vue.$toast.error( error.message )
    //debugger
    return null
}
export function report_read_json_error( error ) {
    console.error( 'report_read_json_error:', 'stack:', error.stack  )
    if( error && error.message )
        Vue.$toast.warning( error.message )
    else
        Vue.$toast.warning( "error reading json" )
    return null
}
export function report_result( message, result, quiet=false ) {
    if( result.ok ) {
            if( !quiet )
                Vue.$toast.success( message + " completed" )
    } else {
        console.error(  message, 'result:', result )
        Vue.$toast.warning( message  + ' failed with_status : ' + result.status )
    }
    return result
}
export function report_result_errors( message, result ) {
    if( !result.ok ) {
        console.error(  message, 'result:', result )
        Vue.$toast.warning( message  + ' failed with_status : ' + result.status )
    }
    return result
}
export function to_json( msg ) {         
    return function( result ) {
        if( !result ||  !result.ok ) {
            console.error( msg, 'result:', result )
            Vue.$toast.warning( msg  + ' failed with_status : ' + result.status )
            return null
        } else
            return result.json() 
    }
}    

// call the callback with the resulting json
export function fetch_json( method, url, args, caption, callback ) {
    const csrf = Cookies.get('csrftoken')
    let headers = {'X-CSRFToken': csrf }
    let body = null
    if(args.constructor.name == 'FormData') {
        body = args 
        //console.log( 'fetch_bool formdata',body)
    } else {
        body = JSON.stringify( args )
        headers['Content-type'] = 'application/json; charset=UTF-8'
        //console.log( 'fetch_bool json',body)
    }
    let options = { method:method, headers:headers }
    if( method != 'GET') options['body'] = body
    const request = new Request( url, options )
    fetch( request )
    .catch( report_fetch_error )
    .then ( to_json( caption ) )
    .catch( report_read_json_error )
    .then ( json => { 
        //console.log( caption, json )
        if(callback) callback(json) 
    })
}
// call the callback with result.ok
export function fetch_bool( method, url, args, caption, callback ) {
    const csrf = Cookies.get('csrftoken')
    let headers = {'X-CSRFToken': csrf }
    let body = null
    if(args.constructor.name == 'FormData') {
        body = args 
        //console.log( 'fetch_bool formdata', body)
    } else {
        body = JSON.stringify( args )
        headers['Content-type'] = 'application/json; charset=UTF-8'
        //console.log( 'fetch_bool json',body)
    }
    let options = { method:method, headers:headers }
    if( method != 'GET') options['body'] = body
    const request = new Request( url, options )
    fetch( request )
    .catch( report_fetch_error )
    .then ( result => { 
        //console.log( caption, result )
        if(callback) callback(result.ok) 
    })
}


///////////////////////////////////////////////////////////////////////


export function user_current( callback ) {
    fetch_json( 'GET', '/api/current_user/', {}, 'retrieve_user', (json) => {
        callback(json)
    })
}
export function user_login( username, password, callback ) {
    const body = new FormData()
    body.set('username', username )
    body.set('password', password )
    body.set('next',    '/api/'   )
    fetch_bool( 'POST', '/api-auth/login/', body, "login", (ok) => {callback(ok) })
}
export function user_logout( callback ) {
    fetch_bool( 'POST', '/api-auth/logout/', {}, "logout", (ok) => { callback(ok) }) 
}
export function user_list( callback ) {
    user_list_rec( '/api/users/', [], callback )
}
export function user_list_rec( url, results, callback ) {
    fetch_json( 'GET', url, {}, 'user_list_rec', (json) => {
        results = [ ...results, ...json.results ]
        if( json.next ){
            //console.log('user_list_rec', json.next )
            user_list_rec( json.next, results, callback)
        }
        else
        {
            if( callback ) callback(  { results:results } ) 
        }
    })
}
export function user_online_list( callback ) {
    fetch_json( 'GET', '/api/online_users/', {}, 'user_online_list', (json) => { callback(json) } )
}
export function author_list( callback ) {
    fetch_json( 'GET', '/api/authors/', {}, 'author_list', (json) => { callback(json) } )
}
export function buste_list( callback ) {
    fetch_json( 'GET', '/api/buste/', {}, 'buste_list', (json) => { callback(json) })
}




export function user_document_list( user, callback ) {
    fetch_json( 'GET', '/api/user_document/?author=' + user.id, {}, 'user_document_list', (json) => { callback(json) })
}
export function user_document_add( user, title, callback ) {
    const body = new FormData()
    body.set('user', user.url )
    body.set('title', title )
    fetch_bool( 'POST', '/api/user_document/', body, 'user_document_add', (json) => { callback(json) })
}
export function user_document_save( user, doc, callback ) {
    //debugger
    const body = new FormData()
    body.set('user',  user.url )
    body.set('title', doc.title )
    body.set('text',  doc.text )
    fetch_bool( 'PATCH', doc.url, body, 'user_document_save', (ok) => { callback(ok) })
}
export function user_document_delete( doc, callback ) {
    fetch_bool( 'DELETE', doc.url, {}, 'user_document_delete', (ok) => { callback(ok) })
}




export function bookmark_list( callback ) {
    fetch_json( 'GET', '/api/bookmarks/', {}, 'bookmark_list', (json) => { callback(json) })
}
export function bookmark_add( user, path, label, callback ) {
    const body = new FormData()
    body.set('user', user.url )
    body.set('path', path )
    body.set('label', label )
    fetch_bool( 'POST', '/api/bookmarks/', body, 'bookmark_add', (ok) => { callback(ok) })
}
export function bookmark_delete( obj, callback ) {
    fetch_bool( 'DELETE', obj.url, {}, 'bookmark_delete', (ok) => { callback(ok) })
}



export function user_transcription_list( user, callback ) {
    fetch_json( 'GET', '/api/transcription/?published=false&author=' + user.id, {}, 'user_transcription_list', (json) => { callback(json) })
}
export function user_transcription_delete( url, callback ) {
    fetch_bool( 'DELETE', url, {}, 'user_transcription_delete', (ok) => { callback(ok) })
}



export function history_list( callback ) {
    fetch_json( 'GET', '/api/history/', {}, 'history_list', (json) => { callback(json) })
}
export function history_add( user, page, callback ) {
    const body = new FormData()
    //body.set('user', user.url ) -- lo fa da solo
    body.set('page', page )
    fetch_bool( 'POST', '/api/history/', body, 'history_add', (ok) => { callback(ok) })
}



export function person_list( callback ) {
    fetch_json( 'GET', '/api/person/', {}, 'person_list', (json) => { callback(json) })
}
//return the object created
export function person_add( name, long_name, note, callback ) {
    const body = new FormData()
    body.set('name',        name       )
    body.set('long_name',   long_name  )
    body.set('note',        note       )
    fetch_json( 'POST', '/api/person/', body, 'person_add', (json) => { 

        //console.log('person_add', json) 
        callback(json) 
    
    })
}
export function person_save( person, callback ) {
    const body = new FormData()
    body.set('name',        person.name       )
    body.set('long_name',   person.long_name  )
    body.set('note',        person.note       )
    fetch_bool( 'PATCH', person.url, body, 'person_save', (ok) => { callback(ok) })
}
export function person_delete( person, callback ) {
    fetch_bool( 'DELETE', person.url, {}, 'person_delete', (ok) => { callback(ok) })
}


export function place_list( callback ) {
    fetch_json( 'GET', '/api/place/', {}, 'place_list', (json) => { callback(json) })
}
// return the object created
export function place_add( name, long_name, note, callback ) {
    const body = new FormData()
    body.set('name',        name       )
    body.set('long_name',   long_name  )
    body.set('note',        note       )
    fetch_json( 'POST', '/api/place/', body, 'place_add', (json) => { callback(json) })
}
export function place_save( place, callback ) {
    const body = new FormData()
    body.set('name',        place.name       )
    body.set('long_name',   place.long_name  )
    body.set('note',        place.note       )
    fetch_bool( 'PUT', place.url, body, 'place_save', (ok) => { callback(ok) })
}
export function place_delete( place, callback ) {
    fetch_bool( 'DELETE', place.url, {}, 'place_delete', (ok) => { callback(ok) })
}



export function event_list( callback ) {
    fetch_json( 'GET', '/api/site_event/', {}, 'event_list', (json) => { callback(json) })
}
export function stat_list( callback ) {
    fetch_json( 'GET', '/api/site_numbers/', {}, 'stat_list', (json) => { callback(json) })
}



export function transcription_list_by_author( user, callback ) {
    fetch_json( 'GET', '/api/transcription/?&author=' + user.id, {}, 'transcription_list_by_author', (json) => { callback(json) })
}
export function transcription_list_by_docname( doc_name, callback ) {
    fetch_json( 'GET', '/api/transcription/?document=' + doc_name, {}, 'transcription_list_by_doc', (json) => { callback(json) })
}
export function  transcription_list_by_person( person, callback ) {
    fetch_json( 'GET', '/api/transcription/?version=current&published=true&person=' + person.id, {}, 'transcription_list_by_person', (json) => { callback(json) })
}

export function transcription_delete( obj, callback ) {
    fetch_bool( 'DELETE', obj.url, {}, 'transcription_delete', (ok) => { callback(ok) })
}
//export function transcription_make_current( tr, callback ) {
export function transcription_make_current( tr_id, callback ) {    
    //const body = new FormData()
    //body.set('transcription', tr.url  )
    //fetch_bool( 'PATCH', tr.document, body, 'transcription_make_current', (ok) => { callback(ok) })
    //console.log( 'transcription_make_current', tr_id )
    fetch_bool( 'POST', '/api/make_current/' + tr_id  + "/", {}, 'transcription_make_current', (ok) => { callback(ok) })
}
export function transcription_add( data, callback ) {  // data will be serialized as json -- to handle recipients
    fetch_json( 'POST', '/api/transcription/', data, 'transcription_add', (json) => { callback(json) })
}
export function transcription_save( url, data, callback ) { // data will be serialized as json -- to handle recipients
    //debugger
    fetch_bool( 'PATCH', url, data, 'transcription_save', (ok) => { callback(ok) })
}


export function doc_details( url, callback ) {
    fetch_json( 'GET', url, {}, 'doc_details', (json) => { callback(json) })
}
export function doc_save_flags( doc, callback ) {
    //debugger
    const body = new FormData()
    body.set('name',                 doc.name  )
    body.set('lock_date',            doc.lock_date  )
    body.set('lock_sender',          doc.lock_sender  )
    body.set('lock_sender_place',    doc.lock_sender_place  )
    body.set('lock_recipient',       doc.lock_recipient  )
    body.set('lock_cc_recipient',    doc.lock_cc_recipient  )
    body.set('lock_transcription',   doc.lock_transcription  )
    body.set('lock_synopsis',        doc.lock_synopsis  )
    body.set('lock_bibliography',    doc.lock_bibliography  )
    fetch_bool( 'PATCH', doc.url, body, 'doc_set_locks', (ok) => { callback(ok) })
}
export function doc_finalize( doc, callback ) {
    //debugger
    const body = new FormData()
    body.set('final', true )
    fetch_bool( 'PATCH', doc.url, body, 'doc_finalize', (ok) => { callback(ok) })
}
export function doc_unfinalize( doc, callback ) {
    //debugger
    const body = new FormData()
    body.set('final', false )
    fetch_bool( 'PATCH', doc.url, body, 'doc_unfinalize', (ok) => { callback(ok) })
}
export function doc_edit_begin( user, doc, callback) {
    //debugger
    //const body = new FormData()
    //body.set('editor', user.url )
    //fetch_bool( 'PATCH', doc.url, body, 'doc_edit_begin', (ok) => { callback(ok) })
    
    // ad-hoc api because now doc is readonly (via rest)
    const x = doc.url.split("/")
    const doc_id = x[ x.length-2 ]
    fetch_bool( 'PATCH', "/api/begin_edit/" + doc_id, {}, 'doc_edit_begin', (ok) => { callback(ok) })
}
export function doc_edit_end( doc, callback) {
    //debugger
    //const body = new FormData()
    //body.set('editor', "" )
    //fetch_bool( 'PATCH', doc.url, body, 'doc_edit_end', (ok) => { if(callback) callback(ok) })

    // ad-hoc api because now doc is readonly (via rest)
    const x = doc.url.split("/")
    const doc_id = x[ x.length-2 ]
    fetch_bool( 'PATCH', "/api/end_edit/" + doc_id, {}, 'doc_edit_end', (ok) => { callback(ok) })
}
export function room_list( callback ) {
    fetch_json( 'GET', '/api/rooms/', {}, 'room_list', json => {
        callback(json)
    })
}
export function room_create( name, creator_url, members_url, group, callback ) {
    //debugger
    // force the creator to be a member 
    if( ! members_url.includes( creator_url ) )
        members_url = [ creator_url, ...members_url ]

    // the operation is in two step, 
    // first create the Room, then Add the members
    const data = { name:name, creator:creator_url, group:group } //, avatar:avatar }
    fetch_json( 'POST', '/api/rooms/', data, "room_create", json => {  
        
        if( json && json.url ) // json is the new room 
            room_update( json.url, name, members_url, () => { callback(json) } )
        else
            callback(json) 
    })
}
export function room_update( room_url, new_name, new_members_url, callback ) {
    const data = { name:new_name, users:new_members_url, }
    fetch_json( 'PATCH', room_url, data, "room_update", json => {
        callback(json)
    })
}
export function room_delete( room_url, callback ) {
    fetch_bool( 'DELETE', room_url, {}, "room_delete", (ok) => {
        callback(ok)
    } ) 
}


export function message_list( filter, callback ) {
    const url = '/api/message/' + filter // filter must be "?room=roomId"
    fetch_json( 'GET', url, {}, "message_list", json => {
        callback(json)
    })
}
export function message_create( room_url, user_url, content, callback ) {
    const body = new FormData()
    body.set('room', room_url )
    body.set('content', content )
    body.set('sender', user_url  )
    fetch_bool( 'POST', '/api/message/', body, "message_create", (ok) => {
        callback(ok)
    }) 
}

export function site_text_load( callback )  {
    let data1 = []

    site_text_load_rec( '/api/site_text/', data1, data2 => {

        site_text_load_rec( '/api/site_page/', data2, data3 => {

            if( callback )
                callback( data3 )
        })
    })
}

export function site_text_load_rec( url, results, callback )  {
    fetch_json( "GET", url, {}, "load_site_text", json => {

        if( json.results  )
            results = [ ...results, ...json.results ]

        if( json && json.next )
            site_text_load_rec( json.next, results, callback )
        else
            if( callback) callback( results )
   })    
}

export function site_text_update(id, text_id, text_eng, text_ita, callback  ) {

    const body = new FormData()
    body.set('name',        text_id )
    body.set('text_eng',    text_eng )
    body.set('text_ita',    text_ita )

    const url = ( text_id.startsWith("page") ? "/api/site_page/" : '/api/site_text/' )

    fetch_bool( "PUT", url + id +'/', body, "set_site_text", ()=>{ if(callback) callback() } )
}
// returns the id
export function site_text_add( text_id, text_eng, text_ita, callback  ) {

    const body = new FormData()
    body.set('name',        text_id )
    body.set('text_eng',    text_eng )
    body.set('text_ita',    text_ita )

    const url = ( text_id.startsWith("page") ? "/api/site_page/" : '/api/site_text/' )

    fetch_json( "POST", url, body, "set_site_text", (json)=>{ if(callback) callback(json) } )
}
