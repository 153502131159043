<template>
    <v-dialog persistent :value="show" max-width="600" >
        <v-card class="pa-4">
            <v-card-title class="text-h5"> <LocText text_id="chat_edit.title" /> </v-card-title>
            <v-card-text flat>
                <div class="py-4"> creator: {{ room_creator.full_name }} </div> 
                <v-form  ref="form" v-model="valid" >

                    <v-text-field 
                        v-model="group_name"     
                        data-cy="group_name"
                        :rules="[ group_name_rule ]"
                        :disabled="disable_edit">
                            <template v-slot:label>
                                <LocText text_id="chat_edit.group_name" />
                            </template>
                    </v-text-field >

                    <v-autocomplete 
                        v-model="members_url" 
                        :items="member_candidates" 
                        item-text="full_name"
                        item-value="url"                              
                        multiple
                        chips   
                        deletable-chips
                        data-cy="members"
                        :disabled="disable_edit">
                            <template v-slot:label>
                                <LocText text_id="chat_edit.group_members" />
                            </template>
                    </v-autocomplete>

                </v-form>
            </v-card-text>


            <v-card-actions class="pb-5" v-if="!disable_edit">
                <v-spacer/>
                <v-btn 
                    color="primary" 
                    depressed 
                    data-cy="add-group-ok-btn"
                    v-bind:disabled="!valid" 
                    @click="on_ok"       
                    >  
                    <LocText text_id="general.save" /> 
                </v-btn>
                <v-btn
                    depressed 
                    data-cy="add-group-cancel-btn"
                    @click="on_close" 
                    > 
                    <LocText text_id="general.cancel" /> 
                </v-btn>
                <v-spacer/>
            </v-card-actions>


            <v-card-actions class="pb-5" v-if="disable_edit">
                <v-spacer/>
                <v-btn
                    depressed 
                    data-cy="add-group-cancel-btn"
                    @click="on_close" 
                    > 
                    <LocText text_id="general.close" /> 
                </v-btn>
                <v-spacer/>
            </v-card-actions>

        </v-card>
    </v-dialog>   
</template>

<script>

import LocText              from "../components/LocText"
import { useAppStore  }     from '../stores/appStore'
import { mapState    }      from 'pinia'
import { mapActions  }      from 'pinia'

export default {
    name: "ChatEditRoom",
    props : [ 'show', 'room' ],
    components: { LocText },

    data: () => ({
        valid:false,
        disable_edit:false,
        room_creator: "",
        group_name : "",
        member_candidates : [],
        members_url : [],
        show_error: false,
    }),
    watch : {
        show()          { if(this.show) this.on_show() },
        group_name()    { this.show_error = false },
        user_added()    { this.show_error = false },
        user_removed()  { this.show_error = false },
    },
    computed : {
        ...mapState(   useAppStore, ['rooms','users','user'] ),
    },
    mounted() {
        this.load_users()
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'load_users',
            'load_rooms', 
            'create_room', 
            'edit_room', 
            'add_room_user',
        ] ),
        on_show() {
            if( !this.room ) return
            
            this.disable_edit = this.user.url != this.room.creator
            this.room_creator = this.users.find( x => x.url == this.room.creator )

            this.group_name = this.room.roomName
            // candidate are all the users except the current user
            this.member_candidates = this.users.filter( x => x.id != this.user.id )
            this.members_url = this.room.users.map( x => x.url )
        },
        group_name_rule(value) { 
            if( !value ) return "required"
            if(  value.length < 2 ) return "name_too_short"
            if( !value.match( /^[a-z1-9 _']+$/i ) ) return "invalid character"
            return true
        },
        on_ok() {
            this.edit_room( this.room, this.group_name, this.members_url, result => {
                if( result ) // the result is a bool:success
                    this.on_close()
            } )
        },
        on_close() {
            this.$emit('done')
        },
    },
};
</script>

<style scoped>
</style>
