<template>
    <v-container fluid  data-cy="view-online-users"> <!-- fluid => not using the column layout -->
        <div class="d-flex justify-start flex-wrap">
            <div class="text-h6  pt-1" ><LocText text_id="online_users.title" /></div>
            <v-spacer/>
            
            <v-btn v-if="user.id==641"
                depressed
                color="error"  
                @click="ask_m_confirmation=true" 
                > 
                send maintenance alert
            </v-btn>
        </div>
                
        <!-- view as table --->
        <div class="pt-3">
            <v-data-table
                mobile-breakpoint="100"
                :headers="lang_current=='en' ? table_headers_eng : table_headers_ita"
                :items="online_users"
                :items-per-page="30"
                dense >
            </v-data-table>
        </div>

        <Confirm
            :show="ask_m_confirmation"  
            title="Please confirm"
            text="send maintenance alert ?"
            @yes="on_maintenance()" 
            @no="ask_m_confirmation=false" 
        />


    </v-container>    
</template>

<script>
import LocText          from "../components/LocText"
import Confirm          from "../components/Confirm"
import { mapState    }  from 'pinia'
import { mapActions  }  from 'pinia'
import { useAppStore }  from '../stores/appStore'

export default { 
    name: "OnlineUsers",    
    components: { LocText, Confirm },
    data() {
        return {
            table_headers_ita: [    
                 { text: 'nome',        value: 'first_name'  },
                 { text: 'cognome',     value: 'last_name'   },
            ],
            table_headers_eng: [    
                 { text: 'first_name',  value: 'first_name'  },
                 { text: 'last name',   value: 'last_name'   },
            ], 
            
            ask_m_confirmation : false,
        }
    },
    created() {
        this.load_online_users()
    },
    watch: {
        $route() {
            this.load_online_users()
        },
    },
    computed : {
        ...mapState( useAppStore, ['user','online_users', 'lang_current'] ),
    },
    methods : {
        ...mapActions( useAppStore, [ 'load_online_users'] ),

        on_maintenance() {
            this.$socket.emit( 'broadcast_maintenace_alert')
            this.ask_m_confirmation=false
        }
    },
    sockets: {
        maintenance_alert: function () {
            console.log('maintenance_alert_received:')
        }
    },        
}
</script>
<style scoped>
</style>



