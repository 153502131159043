import Vue from 'vue'
Vue.config.devtools = true
Vue.config.productionTip = false

import App              from './App.vue'
import router           from './router'
import vuetify          from './plugins/vuetify' // vuetify and tiptap-vuetify
import i18n             from './plugins/i18n'
import {}               from './plugins/toast'
import {}               from './plugins/input_autosize'
import pinia            from './plugins/pinia'
import {}               from './plugins/vue-socket.js'
import {}               from './plugins/markdown.js'
import VueResource      from 'vue-resource'  //TODO: check if needed
import { mapActions }   from 'pinia'
import { useAppStore }  from './stores/appStore'

new Vue({
    VueResource,
    router,
    vuetify,
    i18n,
    pinia,

    data() {
        return {
            connected : false,
        }
    },
    beforeMount() {
        this.init_store( this.$i18n )

        this.$router.afterEach((to, from, failure ) => {
            if (!failure && to!="/")  
                this.add_to_history(to)
        })
    },    
    computed : {
        //...mapState( useAppStore, [] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 'init_store', 'add_to_history' ]),
    },
    sockets: {
        connect: function () {
            //console.log('connected')
            this.connected = true
        },
        disconnect: function () {
            //console.log('DISconnected')
            this.connected = false
        },
    },      
    render: h => h(App)

}).$mount('#app')
