<template>
    <v-dialog persistent :value="show" max-width="400" >
        <v-card>
            <v-card-title class="text-h5">
            <LocText text_id="login.title" />
            </v-card-title>
            <v-card-text>
                <v-text-field 
                    v-model="username"     
                    data-cy="username"
                    :label="$t('user.username')"
                />
                <v-text-field 
                    v-model="password" 
                    data-cy="password"
                    :type="show_psw ? 'text' : 'password'" 
                    :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('user.password')"
                    @click:append = "show_psw = !show_psw" 
                />
                <!-- <div v-if="show_error" class="text-caption" color="red"> <LocText text_id="login.failed" /> </div> -->

                <v-alert
                    v-if="show_error"
                    dense
                    text
                    type="error"
                    data-cy="login-error"
                    >
                    <LocText text_id="login.failed" />
                    <LocText text_id="login.lost_password" />
                    <a 
                        href="#" 
                        @click.prevent="on_lost_password"
                        data-cy="lost-password-btn"
                        >            
                        <LocText text_id="general.here" />    
                    </a>
                </v-alert>

            </v-card-text>
            <v-card-actions class="pb-5">
                <v-spacer/>
                
                <v-btn 
                    color="primary" 
                    depressed 
                    class="login-btn" 
                    data-cy="login-ok-btn"
                    @click="on_login"       
                    >  
                    <LocText text_id="nav.login" />
                </v-btn>
                
                <v-btn
                    depressed 
                    class="login-btn" 
                    data-cy="login-cancel-btn"
                    @click="on_login_close" 
                    > 
                    <LocText text_id="general.cancel" />
                </v-btn>

                <!-- <v-btn
                    depressed 
                    class="login-btn" 
                    @click="on_lost_password" 
                    > 
                    <LocText text_id="login.lost_password" />
                </v-btn> -->

                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>   
</template>

<script>
import LocText              from "../components/LocText"
import { mapActions   }     from 'pinia'
import { useAppStore  }     from '../stores/appStore'

export default {
    name: "UserLogin",
    props : [ 'show' ],
    components: { LocText },    
    data: () => ({

        username:"",
        password:"",
        show_psw: false,
        show_error: false,

    }),
    watch : {
        username() { this.show_error = false },
        password() { this.show_error = false }
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'do_login', 
        ] ),

        on_login() {
            this.do_login( this.username, this.password, (user) => {
                if( user ) 
                    this.on_login_close()
                else 
                    this.show_error = true 
            })
        },
        on_login_close() {
            this.username = ""
            this.password = ""
            this.show_psw = false
            this.show_error = false
            this.$emit('done') 
        },
        on_lost_password() {
            this.username = ""
            this.password = ""
            this.show_psw = false
            this.show_error = false
            this.$emit('lost_password') 
        },
    },
};
</script>

<style scoped>
.login-btn { min-width:80px !important; }
</style>
