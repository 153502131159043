<template>
    <v-dialog 
        persistent 
        :value="show" 
        max-width="400" 
        data-cy="signup-dlg"
    >
        <v-card>
            <v-card-title class="text-h5">
            <LocText text_id="signup.title" />
            </v-card-title>
            <v-card-text>
                <v-form  ref="form" v-model="valid" >

                    <v-text-field 
                        v-model="first_name"     
                        :label="$t('signup.name')"
                        :rules="[ name_rule ]"
                        data-cy="first-name"
                    />
                    <v-text-field 
                        v-model="last_name"     
                        v-bind:label="$t('signup.surname')"
                        :rules="[ name_rule ]"
                        data-cy="last-name"
                    />
                    <v-text-field 
                        v-model="email" 
                        v-bind:label="$t('signup.email')"
                        :error-messages="email_error"
                        :rules="[ email_rule ]"
                        data-cy="email"
                    />
                    <v-text-field 
                        v-model="username"     
                        v-bind:label="$t('signup.username')"
                        :error-messages="username_error"
                        :rules="[ username_rule ]"
                        data-cy="username"
                    />
                    <v-text-field 
                        v-model="password" 
                        :type="show_psw ? 'text' : 'password'" 
                        :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append = "show_psw = !show_psw" 
                        v-bind:label="$t('signup.password')"
                        :rules="[ password_rule ]"
                        data-cy="password"
                    />
                    <v-text-field 
                        v-model="password2" 
                        :type="show_psw ? 'text' : 'password'" 
                        :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append = "show_psw = !show_psw" 
                        v-bind:label="$t('signup.password2')"
                        :rules="[ password2_rule ]"
                        data-cy="password2"
                    />

                    <div class="text-caption"> {{ bottom_error }} </div>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-5">
                <v-spacer/>
                <v-btn 
                    color="primary" 
                    text--color="white"
                    depressed 
                    @click="on_signup" 
                    v-bind:disabled="!valid" 
                    data-cy="signup-ok-btn"
                > 
                    <LocText text_id="general.submit" />
                </v-btn>
                
                <v-btn 
                    depressed 
                    @click="on_close" 
                    data-cy="signup-cancel-btn"
                >                          
                    <LocText text_id="general.cancel" />
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue              from "vue"
import LocText          from "../components/LocText"
import { fetch_json }   from '../api'

//import { mapState }     from 'pinia'
//import { mapActions } from 'pinia'
//import { useAppStore }  from '../stores/appStore'

export default {
    name: "UserSignup",
    props : [ 'show' ],
    components: { LocText },    

    data: () => ({
        first_name : "",
        last_name  : "",
        email      : "",
        username   : "",
        password   : "",
        password2  : "",
        show_psw   : false,
        valid      : false,
        email_error : '',
        username_error : '',
        bottom_error  : "",

        // non si puo usare $t nei dati  
        //first_name_lab : this.$t('signup.name'),
    }),
    created() {
        if( this.show ) this.on_show()
    },    
    watch: {
        show() {
            if( this.show ) this.on_show()
        },
        username() { 
            this.username_error = "" 
        },
        email() { 
            this.email_error = "" 
        }
    },
    methods : {

        name_rule(value) { 
            if( !value ) return this.$t('signup.required')
            if(  value.length < 2 ) return this.$t('signup.name_too_short')
            if( !value.match( /^[a-z']+$/i ) ) return this.$t('signup.name_bad_char')
            return true
        },
        email_rule( value ) { 
            if( !value ) return this.$t('signup.required')
            if( !value.match( /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/ ) ) return this.$t('signup.bad_email')
            return true
        },
        username_rule(value) { 
            if( !value ) return this.$t('signup.required')
            if(  value.length < 2 ) return this.$t('signup.name_too_short')
            if( !value.match( /^[a-z0-9_]+$/i )) return this.$t('signup.username_bad_char')
            return true
        },
        password_rule(value) { 
            if( !value ) return this.$t('signup.required')
            if( value.length < 8 ) return this.$t('signup.password_too_short')
            return true
        },
        password2_rule() {
            if( this.password2 != this.password ) return this.$t('signup.password_dont_match')
            return true
        },
        on_show() {
            this.first_name     = ""
            this.last_name      = ""
            this.email          = ""
            this.username       = ""
            this.password       = ""
            this.password2      = ""
            this.show_psw       = false
            this.valid          = false
            this.email_error    = ""
            this.username_error = ""
            this.bottom_error   = ""
        },
        on_signup() {

            const body = new FormData()
            body.set('first_name',  this.first_name )
            body.set('last_name',   this.last_name  )
            body.set('email',       this.email      )
            body.set('username',    this.username   )
            body.set('password',    this.password   )

            fetch_json( "POST", '/register', body, "register", json => {
                if( json && json.ok ) {  //json.ok e' un bool
                    Vue.$toast.success( this.$t('signup.email_sent'))
                    this.on_reset() 
                    this.$emit('done') 
                }
                else {
                    if( json.msg == "username already used") {
                        this.username_error = this.$t('signup.username_already_used')
                    }
                    else if( json.msg == "email already used") {
                        this.email_error = this.$t('signup.email_already_used')
                    }
                    else {
                        this.bottom_error = json.msg
                    }
                }
            })

        },
        on_reset() {
            this.$refs.form.reset()
            this.show_psw   = false
            this.bottom_error  = ""
        },
        on_close() {
            this.on_reset()
            this.$emit('done') 
        },
    },
}
</script>
<style scoped>
</style>