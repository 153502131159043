<template>
    <div>
        <v-container data-cy="view-buste">
            <div class="d-flex justify-start flex-wrap">
                <div class="text-h6 pagetitle" ><LocText text_id="buste.title"/></div> 
            </div>
            <div v-if="!$vuetify.theme.dark">
                <img v-if="lang_current=='en'" src="https://docs.google.com/drawings/d/e/2PACX-1vTjKHZ6okd0CdA0hhdeyxrWraBUwRqP3WAIiiHudyBQmtpPlu2LnCQ8EMb1ZiRM6EAEiWHOToyhgnsH/pub?w=804&amp;h=143" />
                <img v-if="lang_current=='it'" src="https://docs.google.com/drawings/d/e/2PACX-1vQ0XCDoe9mDKDq-9IFrfDrG66F9PxXnOfHLF23XCUIc296q-CW1eSUx8gRzFBcsdtb920axY3eyxfV6/pub?w=804&amp;h=143" />
            </div>
            <div v-else>
                <img v-if="lang_current=='it'" src="https://docs.google.com/drawings/d/e/2PACX-1vS7-l3YroPXh10jH5Ml5zE_tg4FrOXJgMqoMTEhzKDA9vkYDgn3IVYQJhkKz9so4_AQOBs_cS3OswUz/pub?w=804&amp;h=143" />
                <img v-if="lang_current=='en'" src="https://docs.google.com/drawings/d/e/2PACX-1vSkHPETX-d3_dBjE_4NNtERG4p9OfX1w2_o0_nQjbpArfGWhXaGMG1VO0D9u-tOBYA5z3MzV0utnf3Z/pub?w=804&amp;h=143" />
            </div>
    
            <!----SERIE 1----------------------->
            <div v-if="series">
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie1.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie1.subtitle }} </div>
                    <div class="text-body pr-3" > {{ series.serie1.text }} </div>
                </div>            
                <div class="d-flex justify-start flex-wrap">
                    <div
                        v-for="b in serie1" 
                        :key="b.name"
                        @click="open_busta(b)"
                        >
                        <v-sheet rounded class="buste_with_letters ma-1 pa-2" color="box1">
                            <div class="text-body font-weight-bold" > 
                                {{ b.name.replace('V00', 'Volta ') }}
                            </div>
                            <div class="text-caption"> {{ b.years }} </div>
                            <v-sheet class="text-caption letters_nd" color="box1b"> {{ b.collection_set[0].num_doc }} / {{ b.collection_set[0].num_doc_wt }}  </v-sheet>
                        </v-sheet>
                    </div>
                </div>
                
                
                <!----SERIE 2----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie2.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie2.subtitle }} </div>
                    <div class="text-body pr-3" > {{ series.serie2.text }} </div>
                </div>            
                <div class="d-flex justify-start flex-wrap">
                    <div
                        v-for="b in serie2" 
                        :key="b.name"
                        @click="open_busta(b)"
                        >
                        <v-sheet rounded class="buste_with_letters ma-1 pa-2" color="box1">
                            <div class="text-body font-weight-bold" > 
                                {{ b.name==="VA01" ? "Volta Autografi" : b.name }}
                            </div>
                            <div class="text-caption"> {{ b.years }} </div>
                            <v-sheet class="text-caption letters_nd" color="box1b"> {{ b.collection_set[0].num_doc }} / {{ b.collection_set[0].num_doc_wt }}  </v-sheet>
                        </v-sheet>
                    </div>
                </div>

                <!----SERIE 3----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie3.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie3.subtitle }} </div>
                    <div class="text-body pr-3" > {{ series.serie3.text }} </div>
                </div>            
                <div class="d-flex justify-start align-stretch flex-wrap" >
                    <div
                        v-for="b in serie3" 
                        :key="b.name"
                        >
                        <v-sheet rounded class="buste_with_copybook ma-1" v-bind:class="{ busta_wip: b.wip }" color="box3">
                            <div class="px-2 ">
                                <div class="d-flex justify-start pt-1" >
                                    <div class="text-body font-weight-bold" > {{ b.name }} </div>
                                    <v-spacer />
                                    <v-icon v-if="b.wip" small color="#ff4141" z-class="mb-2"  > mdi-circle </v-icon> 
                                </div>
                                <div class="text-caption"> {{ $d( Date.parse(b.date_from), 'short' )}} </div>
                            </div>
                            <div class="d-flex justify-start px-1 pb-1"> 
                                <v-sheet
                                    label
                                    v-for="c in b.collection_set" 
                                    :key="c.name"
                                    class="copybook mx-1 mb-1"
                                    color="box3b"
                                    @click="open_copybook(c.name)"
                                >
                                    <div class="copybook_name pt-1" >
                                        {{ c.name }}
                                    </div>
                                    <div class="copybook_nd text-caption" >
                                        {{c.num_doc}} / {{c.num_doc_wt}}
                                    </div>
                                </v-sheet>   

                            </div>

                        </v-sheet>
                    </div>
                </div>

                <!----SERIE 4----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie4.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie4.subtitle }} </div>
                    <div class="text-body pr-3" > {{ series.serie4.text }} </div>
                </div>            
                <div class="d-flex justify-start align-stretch flex-wrap" >
                    <div
                        v-for="b in serie4" 
                        :key="b.name"
                        >
                        <v-sheet rounded class="buste_with_copybook ma-1" v-bind:class="{ busta_wip: b.wip }" color="box3">
                            <div class="px-2 ">
                                <div class="d-flex justify-start pt-1" >
                                    <div class="text-body font-weight-bold" > {{ b.name }} </div>
                                    <v-spacer />
                                    <v-icon v-if="b.wip" small color="#ff4141" z-class="mb-2"  > mdi-circle </v-icon> 
                                </div>
                                <div class="text-caption"> {{ $d( Date.parse(b.date_from), 'short' )}} </div>
                            </div>
                            <div class="d-flex justify-start px-1 pb-1"> 
                                <v-sheet
                                    label
                                    v-for="c in b.collection_set" 
                                    :key="c.name"
                                    class="copybook mx-1 mb-1"
                                    color="box3b"
                                    @click="open_copybook(c.name)"
                                >
                                    <div class="copybook_name pt-1" >
                                        {{ c.name }}
                                    </div>
                                    <div class="copybook_nd text-caption" >
                                        {{c.num_doc}} / {{c.num_doc_wt}}
                                    </div>
                                </v-sheet>   

                            </div>

                        </v-sheet>
                    </div>
                </div>

                <!----SERIE 5----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie5.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie5.subtitle }} </div>
                    <div class="text-body pr-3" > {{ series.serie5.text }} </div>
                </div>            
                <div class="d-flex justify-start flex-wrap">
                    <div
                        v-for="b in serie5" 
                        :key="b.name"
                        @click="open_busta(b)"
                        >
                        <v-sheet rounded class="buste_with_letters buste_wrap buste_w150 ma-1 pa-2" color="box1">
                            <div class="text-body font-weight-bold" > 
                                {{  b.name.replace('000', '').replace('00', '') }}
                            </div>
                            <div class="text-caption text-caption-wrap"> {{ b.note.replace(',','\n') }} </div>
                            <!--div class="text-caption "> - </div-->
                            <v-sheet class="text-caption letters_nd" color="box1b"> {{ b.collection_set[0].num_doc }} / {{ b.collection_set[0].num_doc_wt }}  </v-sheet>
                        </v-sheet>
                    </div>
                </div>


                <!----SERIE 6----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie6.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie6.subtitle }} </div>
                    <div class="text-body pr-3" > {{ series.serie6.text }} </div>
                </div>            
                <div class="d-flex justify-start flex-wrap">
                    <div
                        v-for="b in serie6" 
                        :key="b.name"
                        @click="open_busta(b)"
                        >
                        <v-sheet rounded class="buste_with_original_letters  buste_w150 ma-1 pa-2" color="box2">
                            <div class="text-body font-weight-bold" > 
                                Autografi Volta
                            </div>
                            <div class="text-caption"> {{ b.years }} </div>
                            <v-sheet class="text-caption letters_nd" color="box2b"> {{ b.collection_set[0].num_doc }} / {{ b.collection_set[0].num_doc_wt }}  </v-sheet>
                        </v-sheet>
                    </div>
                </div>

                <!----SERIE 7----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie7.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie7.subtitle }} </div>
                    <div class="text-body pr-3" > {{ series.serie7.text }} </div>
                </div>            
                <div class="d-flex justify-start flex-wrap">
                    <div
                        v-for="b in serie7" 
                        :key="b.name"
                        @click="open_busta(b)"
                        >
                        <v-sheet rounded class="buste_with_letters buste_w150 ma-1 pa-2" color="box1">
                            <div class="text-body font-weight-bold" > 
                                {{ b.name.replace('V00', 'Volta ') }}
                            </div>
                            <div class="text-caption">  {{  b.note }} </div>
                            <v-sheet class="text-caption letters_nd" color="box1b"> {{ b.collection_set[0].num_doc }} / {{ b.collection_set[0].num_doc_wt }}  </v-sheet>
                        </v-sheet>
                    </div>
                </div>
            </div>
        </v-container>
    </div>
    </template>
    
    <script>
    import LocText          from "../components/LocText"
    import sortBy           from 'lodash/sortBy';
    import { mapState }     from 'pinia'
    import { useAppStore }  from '../stores/appStore'
    
    export default { 
        name: "BusteV1",  
        components: { LocText  /*, ColorEditor*/ },    
        data() {
            return {
                sort_by: "name",   
                //view_mode:0,          // 0=icon 1=table
    
                buste_with_letters_color : '#64b5f6',
                show_color_editor : false,
    
                fg1 : "#000",
                fg2 : "#000",
                fg3 : "#000",
                bg1 : "#64b5f6",
                bg2 : "#f664de",
                bg3 : "#ffb74d",
            }
        },
        computed : {
            ...mapState( useAppStore, ['buste', 'lang_current', 'series'] ),
            buste_sorted : function() {
                if( this.sort_by == "name" )
                    return sortBy( this.buste, "name" )
                else
                    return sortBy( this.buste, "date_from" )
            },
            serie1 : function() { 
                return this.buste_sorted.filter( b => {
                    return b.name.startsWith('18') || b.name.startsWith('19')
                })
            },
            serie2 : function() { 
                return this.buste_sorted.filter( b => {
                    return b.name.startsWith('21')
                })
            },
            serie3 : function() { 
                return this.buste_sorted.filter( b => {
                    return b.name.startsWith('29') && !b.name.startsWith('299')
                })
            },
            serie4 : function() { 
                return this.buste_sorted.filter( b => {
                    return b.name.startsWith('299') || b.name.startsWith('300')
                    
                })
            },
            serie5 : function() { 
                return this.buste_sorted.filter( b => {
                    return b.name.startsWith('00')
                    
                })
            },
            serie6 : function() { 
                return this.buste_sorted.filter( b => {
                    return b.name.startsWith('VA01')
                    
                })
            },
            serie7 : function() { 
                return this.buste_sorted.filter( b => {
                    return b.name.startsWith('V0')
                })
            },
            colors : function() {
                return [
                    { i:0, lab:"Box A text",         val:this.fg1 },
                    { i:1, lab:"Box A background",   val:this.bg1 },
                    { i:2, lab:"Box B text",         val:this.fg2 },
                    { i:3, lab:"Box B background",   val:this.bg2 },
                    { i:4, lab:"Box C text",         val:this.fg3 },
                    { i:5, lab:"Box C background",   val:this.bg3 },
                ]
            },
        },
        methods: {
            open_busta( item ) {
                this.$router.push( '/B'+item.name )
            },
            open_copybook( item ) {
                this.$router.push( '/C'+item )
            },
            on_color( i, c ) {
                switch( i ) {
                case 0: this.fg1 = c; break; 
                case 1: this.bg1 = c; break; 
                case 2: this.fg2 = c; break; 
                case 3: this.bg2 = c; break; 
                case 4: this.fg3 = c; break; 
                case 5: this.bg3 = c; break; 
                }
            }
        }
    }
    </script>
    
    <style scoped>
    .buste_with_letters {
        min-width:100px;
    }
    .buste_with_letters:hover {
        background:  var(--v-box1-lighten2 ) !important; 
    }
    .buste_with_original_letters {
        min-width:100px;
    }
    .buste_with_original_letters:hover {
        background:  var(--v-box2-lighten2 ) !important; 
    }
    .buste_with_copybook {  /* no hilight */
        min-width:100px;
    }
    .copybook {
        background-color: white !important;
        border-radius:5px;
        max-width:60px;
        min-width:60px;
    }
    .copybook:hover { 
        background:  var(--v-box3-lighten2 ) !important; 
    }
    .copybook_name {
        font-size:16px;
        font-weight:700;
        text-align:center;
    }
    .copybook_nd {
        text-align:center;
    }
    .busta_wip {
        border-radius:5px;
    }
    .letters_nd {
        text-align: center;
        border-radius: 5px;
    }
    .buste_wrap {
    }
    .buste_w150 {
        min-width:160px;
        max-width:160px;
    }    
    .text-caption-wrap {
        white-space: pre-line !important;
        height: 170px;
    }

    </style>
    