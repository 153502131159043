<template>
    <v-dialog 
        persistent 
        v-model="show_internal" 
        max-width="300" 
    >
        <v-card>
            <v-card-title class="text-h5">
            <LocText text_id="change_password.title" />
            </v-card-title>
            <v-card-text>
                <v-form  ref="form" v-model="valid" >

                    <div class="text-body"> <LocText text_id="change_password.subtitle" /> </div>

                    <v-text-field class="pt-8 pb-4"
                        v-model="old_password" 
                        :type="show_psw ? 'text' : 'password'" 
                        :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append = "show_psw = !show_psw" 
                        v-bind:label="$t('change_password.old_password')"
                        :error-messages="old_password_error"
                    />
                    <v-text-field class="py-4"
                        v-model="new_password1" 
                        :type="show_psw ? 'text' : 'password'" 
                        :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append = "show_psw = !show_psw" 
                        v-bind:label="$t('change_password.password')"
                        :rules="[ rule_psw1 ]"
                    />
                    <v-text-field class="py-4"
                        v-model="new_password2" 
                        :type="show_psw ? 'text' : 'password'" 
                        :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append = "show_psw = !show_psw" 
                        v-bind:label="$t('change_password.password2')"
                        :rules="[ rule_psw2 ]"
                    />

                    <div class="text-caption"> {{ bottom_error }} </div>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-5">
                <v-spacer/>
                <v-btn 
                    color="primary" 
                    text--color="white"
                    depressed 
                    @click="on_change_password" 
                    v-bind:disabled="!valid" 
                > 
                    <LocText text_id="general.submit" />
                </v-btn>
                
                <v-btn 
                    depressed 
                    @click="on_close" 
                >                          
                    <LocText text_id="general.cancel" />
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue              from "vue"
import LocText          from "../components/LocText"
import { fetch_json }   from '../api'
//import { useAppStore} from '../stores/appStore'
//import { mapState }   from 'pinia'
//import { mapActions } from 'pinia'

export default {
    name: "UserPasswordChange",
    props : [ 'show' ],
    components: { LocText },    

    data: () => ({
        old_password   : "",
        new_password1  : "",
        new_password2  : "",
        show_psw   : false,
        valid      : false,
        show_internal : false,
        bottom_error  : "",
        
        old_password_error  : "",
        new_password1_error : "",

        // non si puo usare $t nei dati  
        //first_name_lab : this.$t('change_password.name'),
    }),
    created() {
        if( this.show ) this.on_show()
    },    
    watch: {
        show() {
            if( this.show ) this.on_show()
        },
        old_password() {
            this.old_password_error = ""
        },
        new_password1() {
            this.new_password1_error = ""
        }
    },
    computed : {
         //...mapState( useAppStore, ['user'] ),
     },
    methods : {
        // ...mapActions( useAppStore, [ 
        //     'do_create_person',
        // ]),

        rule_psw1(value) {
            if( !value ) return this.$t('change_password.required')
            return value.length >= 4 || this.$t('change_password.password_too_short')
        },
        rule_psw2() {
            return this.new_password2 == this.new_password1 || this.$t('change_password.new_password2_bad') 
        },
        on_show() {
            this.old_password       = ""
            this.new_password1      = ""
            this.new_password2      = ""
            this.show_psw           = false
            this.valid              = false
            this.bottom_error       = ""
            this.old_password_error = ""
            this.new_password1_error= ""

            this.show_internal = true
        },
        on_change_password() {

            const body = new FormData()
            body.set('old_password',     this.old_password   )
            body.set('new_password1',    this.new_password1  )
            body.set('new_password2',    this.new_password2  )

            fetch_json( 'POST', '/change_password', body, 'change_password', json=>{

                if( json.ok ) {
                    Vue.$toast.success( this.$t('change_password.ok'))
                    this.on_reset() 
                    this.$emit('done') 
                }
                else {
                    //console.log('err', json)
                    if( json.errors.old_password != undefined ) {
                        //console.log( json.errors.old_password[0] )
                        this.old_password_error = this.$t('change_password.old_password_bad')
                    }
                    if( json.errors.new_password1 != undefined ) {
                        //console.log( json.errors.new_password1[0] )
                        this.new_password1_error = this.$t('change_password.new_password1_bad')
                    }
                }
            })

        },
        on_reset() {
            this.$refs.form.reset()
            this.show_psw   = false
            this.bottom_error  = ""
            this.old_password_error = ""
            this.show_internal = false
        },
        on_close() {
            this.on_reset()
            this.$emit('done') 
        },
    },
}
</script>
<style scoped>
</style>
