<template>
    <div class="xfield">
        <div class="d-flex justify-start">
            <div class="xlabel text-caption"> 
                <LocText :text_id="label_id" />
            </div> 
            <v-spacer />
            <v-icon v-if="lock" x-small class="xlock"> 
                mdi-lock-outline 
            </v-icon>     
        </div>
        <div v-if="rows==1" class="xvalue text-body-2"> 
            <slot></slot> 
        </div>
        <div v-if="rows==10" 
            class="xtext text-body-2 overflow-y-auto"  
            style="max-height:8rem; min-height:8rem; width:100%"> 
            <slot></slot> 
        </div>
        <div v-if="rows==5" 
            class="xtext text-body-2 overflow-y-auto"  
            style="max-height:4.5rem; min-height:4.5rem; width:100%"> 
            <slot></slot> 
        </div>
        <!--- use negative rows to pass pixels -->
        <div v-if="rows < 0"  
            class="xtext text-body-2 overflow-y-auto"  
            
            :z-------style="{ max_height : height_px, min_height : height_px, width : '100%' }"
            :style="{ height : height_px, width : '100%' }" >  
            <slot></slot> 
        </div>

    </div>
</template>

<script>
import LocText from "../components/LocText"

export default { 
    name: "TranscriptionField",    
    components: { LocText },  

    props : { 
        label_id:String, 
        lock:{ type:Boolean,  default:false }, 
        rows:{ type:Number,   default:1     }, 
    },
    data() {
        return {
        }
    },
    created() {
    },
    computed: {
        height_px() { return -this.rows + "px" } 
    },
    methods: {
    },
}
</script>
<style scoped>
.xfield {
    border-radius: 4px;
    z--background-color: #fff;
    z--margin-top:10px;
    z--background-color: #333;
    background-color : var(--v-field-base); 
}
.xlabel {
    x---color:gray;
    z---font-size: x-small;
    margin-left: 6px;
    padding-top: 2px;
    line-height: 1.0rem !important;
}
.xvalue {
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom : 2px;
    min-width: 150px;
    z---font-size: smaller;  
}
.xtext {
    padding: 0 6px 6px 6px;
    font-size: smaller;  
    border-radius: 4px;
}
.xlock {
    x---color:gray;
    padding-top : 3px;
    padding-right: 5px;
}
</style>



