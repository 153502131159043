<template>
    <v-dialog v-model="show_dlg" fullscreen hide_overlay persistent class="ma-0 pa-0"> 

        <Splitter :enable_split='true' >

            <template v-slot:top-panel>
                <Dragon
                    viewer_id="TEditDragon"
                    :sources="viewer_images" 
                    :ov_area="viewer_areas"
                />
            </template>

            <template v-slot:bottom-panel>
                <TranscriptionEdit
                    :show="show_dlg"
                    :transcription="transcription"
                    @done="on_edit_done" 
            />
            </template>
        </Splitter>

    </v-dialog>
</template>

<script>
import Dragon            from "../components/Dragon"
import TranscriptionEdit from "../components/TranscriptionEdit"
import Splitter          from "../components/Splitter"

export default {
    name: "TranscriptionEditDlg",
    props : [ 'show', 'transcription' ],
    // expect transcription prop to have a member document, 
    // and document should be a doc_details --- not just the url

    components: { Dragon, TranscriptionEdit, Splitter },
    data () {
        return {
            show_dlg      : false,
            viewer_images : [],
            viewer_areas  : [],
        }
    },
    watch : {
        show() {
            if( this.show) {
                this.on_show()
            }
        },
    },
    methods : {
        on_show() {

            if( !this.transcription ) {
                console.error( "TranscriptionEditor::on_edit bad tr", this.transcription )
                return 
            }
            if( !this.transcription.document ) {
                console.error( "TranscriptionEditor::on_edit no doc in tr", this.transcription )
                return 
            }
            let images = []
            let ov_area = []
            this.transcription.document.imagearea_set.forEach( o => {

                let tile = {}
                tile.filename = o.image.filename
                tile.x = 2000 * images.length
                tile.y = 0 
                tile.width = 2000

                images.push(  tile  )

                let ov1 = {}
                ov1.element = "AREA"+ov_area.length
                ov1.x= tile.x
                ov1.y=0
                ov1.width=2010
                ov1.height=o.area_top *2830 / 1000
                ov_area.push(ov1)

                let ov2 = {}
                ov2.element = "AREA"+ov_area.length
                ov2.x= tile.x,
                ov2.y=o.area_bottom *2830 / 1000 
                ov2.width=2010
                ov2.height=2830-ov2.y
                ov_area.push(ov2)
            }) 
            this.viewer_images = images
            this.viewer_areas = ov_area
            this.show_dlg = true
        },
        on_edit_done(exit_mode) {
            console.log( 'TranscriptionEditDlg on_done', exit_mode)
            this.show_dlg = false
            this.$emit('done',exit_mode)
        },
    }
}
</script>

