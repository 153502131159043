<template>
  <v-app id="inspire">
    <Navbar/>
    <v-main>
        <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import Navbar  from './components/Nav'
  export default {
    name: 'App',
    components: { Navbar, },
  }
</script>


<style lang="sass">
  /* global styles */

  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-down')}
    .custom-class
      display: none

</style>



<style >
/* global styles */
.v-main { 
    padding-top: 63px !important;  
}

.v-application {
    background: var(--v-page-base   ) !important;
    color:      var(--v-text-base   ) !important;
}
/* lo spazio e' importante
   .a .b   == l'elemento con classe a e' parent dell'elemento con classe b
   .a.b    == l'elemento ha sia la classe a che la classe b
*/   

.v-application .text-h2.sitetitle  {
    font-family: 'Nunito Sans', Homenaje, Oswald, Roboto, sans-serif !important;
    font-size: 5rem !important;
    line-height: 5rem;
    letter-spacing: .0125em!important;
}
.v-application .text-h5.sitetitle  {
    font-family: 'Nunito Sans', Homenaje, Oswald, Roboto, sans-serif !important;
    font-size: 3rem !important;
    line-height: 3rem;
    letter-spacing: .0125em!important;
}
.v-application .text-h6.pagetitle  {
    font-family:  Homenaje, Oswald, Roboto, sans-serif !important;
    font-size: 2rem !important;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: .0125em!important;
    color:     var(--v-title-base   ) !important;
}
/*
.v-application .text-h6.breadcrumb  {
    font-family: Homenaje, Oswald, Roboto, sans-serif !important;
    font-size: 1.5rem !important;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: .0125em!important;
    color:     var(--v-title-base   ) !important;
}
*/

.v-data-table {
    background: var(--v-sheet-base  ) !important;
}
.v-sheet {
    background: var(--v-sheet-base  ) !important;
}
.v-btn-toggle {
    background: var(--v-sheet-base  ) !important;
}
.v-tabs>.v-tabs-bar {
    background: var(--v-sheet-base  ) !important;
}
.v-tabs>.v-tabs-items {
    background: var(--v-sheet-base  ) !important;
}
.v-list-item {
    background: var(--v-sheet-base  ) !important;
}
.v-list-item:hover {
    background: var(--v-field-base  ) !important;
}
.v-icon {
    color:      var(--v-text-base   ) !important;
}
.v-expansion-panel{
    color:      var(--v-text-base   ) !important;
}
.v-expansion-panel-content {
    background: var(--v-sheet-base  ) !important;
}
.v-expansion-panel-header {
    background: var(--v-button-base ) !important;
}
.v-card>.v-card__text {
    color:      var(--v-text-base   ) !important;
}
.v-text-field--outlined>.v-input__control>.v-input__slot {
    background: var(--v-field-base  ) !important;
}


.guide_topdiv {
    max-width:960px;
    margin:auto;
    padding: 20px;
    line-height: 2 !important;
}
</style>
