let pinia_i18n = {}

import { defineStore } from 'pinia'
import { 
    user_current,
    user_login,
    user_logout,
    user_list,
    user_online_list,
    author_list,

    buste_list,

    doc_details,
    doc_finalize,
    doc_unfinalize,
    doc_save_flags,
    doc_edit_begin,
    doc_edit_end,

    transcription_add,
    transcription_save,
    transcription_list_by_docname,
    transcription_list_by_author,
    transcription_list_by_person,
    transcription_delete,
    transcription_make_current,

    user_transcription_list,
    user_transcription_delete,

    user_document_list,
    user_document_add,
    user_document_save,
    user_document_delete,

    person_list,
    person_add,
    person_delete,
    person_save,

    place_list,
    place_add,
    place_delete,
    place_save,

    bookmark_list,
    bookmark_add,
    bookmark_delete,

    history_list,
    history_add,

    event_list,
    stat_list,

    room_list, 
    room_create, 
    room_update, 
    room_delete, 

    message_list, 
    message_create,

    site_text_load,
    site_text_update,
    site_text_add,



}  from '../api'

1
export const useAppStore = defineStore('appStore', {
    state: () => ({

        lang_current  : "it",
        lang_edit     : false,
        lang_eng      : {},
        lang_ita      : {},
        lang_ids      : {},  // ids delle voci per il committ
        //i18n          : {},

        theme_current : 'dark',
        theme_colors  : {},
        theme_editor  : false,

        show_search_filters : false,

        user : {},
        user_documents : [],
        user_transcriptions : [],
        user_contributes : [],

        bookmarks : [],
        history: [],
        event_list: [],
        site_numbers: [],

        buste : [],
        series : {},
        copybooks : [],

        person_list : [],
        person_unset : { url:"", name:"-", long_name:"-", note:"-" },

        place_list : [],
        place_unset : { url:"", name:"-", long_name:"-", note:"-" },

        //chat ---------
        users           : [],
        authors         : [],
        users_requested : false,
        online_users    : [],
        rooms           : [],     // rooms del current user
        current_room    : null,   // room current
        rooms_loading   : false,
        rooms_loaded    : true,
        messages        : [],     // messaggi della current room
        messages_loaded : true,
        total_unread_messages : 0,

        doc_being_edited : null
        


    }),
    actions: {

        init_store( i18n ) {

            window.addEventListener( "beforeunload", this.end_editing )            

            pinia_i18n = i18n // global var --- better not make it reactive
            this.load_site_text( ()=> {
                this.retrieve_user( () => {
                    this.load_user_transcriptions()
                    this.load_user_contributes()
                    this.load_bookmarks()
                    this.load_user_documents()
                    this.load_user_history()
                    this.load_rooms()
                    this.load_buste()
                    this.load_persons()
                    this.load_places()
                    this.load_authors()
                } )
            })
        },
        do_show_search_filters(value) {
            this.show_search_filters = value
        },
        retrieve_user( callback ) {
            user_current( (json) => {
                this.user = json.url ? json : null
                if( this.user )  this.user.full_name = this.user.first_name+ ' '+ this.user.last_name
                //debugger
                if(callback) callback(this.user) 
            })
        },
        load_users( callback ) {
            // I want to request the useres only once per session
            if( this.users_requested )
                return;                
            this.users_requested = true;

            user_list( (json) => {
                if ( json && json.results ) {
                    json.results.forEach( u => {
                         u.full_name = u.first_name + " " + u.last_name
                    })
                    this.users = json.results
                    if(callback) callback() 
                }
            })
        },
        load_online_users( callback ) {
            user_online_list( (json) => {
                json.results.forEach( u => {
                    u.full_name = u.first_name + " " + u.last_name
                })
                this.online_users = json.results
                if( callback) callback()
            })
        },
        load_authors( callback ) {
            author_list( (json) => {
                json.results.forEach( u => {
                    u.full_name = u.first_name + " " + u.last_name
                })
                this.authors = json.results
                if( callback) callback()
            })
        },
        do_login( username, password, callback ) {
            user_login( username, password, () => {
                // on success refresh the whole app, to recreate the socket
                this.retrieve_user( user => {
                    if( user && user.url ) // success -- reload all
                        window.location.pathname = "/" 
                    else
                        if( callback ) callback(user)
                })
            })
        },           
        do_logout( callback ) {
            user_logout( () => {
                // on success refresh the whole app, to recreate the socket
                window.location.pathname = "/"
                this.retrieve_user( callback ) // unreachable
            })            
        },

        load_buste() {
            this.buste = []
            this.copybooks = []
            buste_list( json => { 
                this.buste = json.results
                this.buste.forEach( busta => {
                    busta.name  = busta.name.replace('B','')
                    busta.ready = true    
                    busta.color = "white" 
                    busta.wip   = false   
                    busta.type  = busta.collection_set[0].type
                    busta.nd1 = busta.collection_set[0].num_doc
                    busta.nd2 = busta.collection_set[0].num_doc_wt
                    if( busta.type == 'C') {
                        busta.collection_set.forEach( c => { 
                            c.name = c.name.replace('C','')
                            c.b = busta.name
                            this.copybooks.push(c)
                        })
                    }
                })
                this.copybooks.sort( (a,b) => { return parseInt(a.name) - parseInt(b.name) } )
                this.load_series()
            })
        },
        load_user_documents( callback ) {
            this.user_documents = []
            if( !this.user ) return;
            user_document_list( this.user, json => {
                this.user_documents = json.results 
                if(callback) callback()
            })
        },

        add_user_document( callback ) {
            if( !this.user ) return 
            user_document_add( this.user, "new document", () => {
                this.load_user_documents( () => {
                    if (callback) {
                        callback( this.user_documents[ 0 ] ) // adesso sono sortati dal piu recente
                    }
                })
            }) 
        },

        save_user_document( doc ) {
            if( !this.user ) return 
            if( !doc ) {
                console.error( "appStore::save_user_document - bad doc")
                return
            }
            user_document_save( this.user, doc, () => {
                this.load_user_documents()
            })
        },

        delete_user_document( doc ) {
            if( !this.user ) return 
            if( !doc ) {
                console.error( this.$toast.error( "appStore::delete_user_document - bad path" ) )
                return
            }
            user_document_delete( doc, () => {
                this.load_user_documents()
            })

        },

        load_bookmarks() {
            this.bookmarks = []
            if( !this.user ) return
            bookmark_list(json => {
                this.bookmarks = json.results
                this.bookmarks.sort( (a,b) => { a.label < b.label } )
            })

        },
        get_bookmark( path ) {
            if( !this.user ) return null
            return this.bookmarks.find( item => item.path == path )
        },
        add_bookmark( path, label ) {
            if( !this.user ) return 
            if( !path ) {
                console.error( "appStore::add_bookmark - bad path")
                return
            }
            if( this.get_bookmark( path ) ) {
                console.error( "appStore::add_bookmark - bookmark", path, "exits already")
                return
            }
            let lab = path.replace('/','').replace("_", " ")
            if( label ) lab = label
            
            bookmark_add( this.user, path, lab, () => {
                this.load_bookmarks()
            })
        },

        remove_bookmark( path ) {
            if( !this.user ) return 
            if( !path ) {
                console.error( "appStore::remove_bookmark - bad path")
                return
            }
            const obj = this.get_bookmark( path )
            if( !obj ) {
                console.error( "appStore::remove_bookmark - cant find bookmark:", path )
                return
            }
            bookmark_delete( obj, () => {
                this.load_bookmarks()
            })

        },

        doc_name( url ) {
            const x = url.split('/')
            const sz = x.length
            if( sz<3 ) {
                console.error('doc_url_to_doc_name, bad url', url)
                return "D???"
            }
            return x[x.length-2]
        },   
        get_doc_details( url, callback ) {
            doc_details( url, callback )
        },
        // private transcription of this user 
        load_user_transcriptions() {
            //debugger
            this.user_transcriptions = []
            if( !this.user ) return;
            user_transcription_list ( this.user, json => { 
                //debugger
                this.user_transcriptions = json.results
                this.user_transcriptions.forEach( item => {
                    item.document_name = this.doc_name( item.document )
                })
            })

        },
        // published transcriptions of this user 
        load_user_contributes() {
            this.user_contributes = []
            if( !this.user ) return;
            transcription_list_by_author( this.user, json => { 
                this.user_contributes = json.results
                this.user_contributes.forEach( item => {
                    item.document_name = this.doc_name( item.document )
                })
                //debugger
            })    
        },


        load_user_history( callback ) {
            this.user_documents = []
            if( !this.user ) return;
            history_list( json => {
                this.history = json.results 
                //debugger
                if(callback) callback()
            })
        },

        add_to_history( to, callback ) {
            if( !this.user ) return 
            if( to.fullPath == "/history") return
            if( to.fullPath == "/") return
            const item = { 'page':to.fullPath, 'date':new Date().toLocaleString() }
            this.history = [ item, ...this.history ]
            history_add( this.user, to.fullPath, () => {
                if (callback) callback()
            })
        },


        get_elem_with_max_id( vector ) {
            let max_id = -1
            let result = null
            for( let i=0; i<vector.length; i++ ) {
                const elem = this.vector[i]
                if( elem.id > max_id ) {
                    max_id = elem.id
                    result = elem
                }
            }
            return result
        },

        load_persons( callback ) {
            this.person_list = []
            person_list( json => {
                this.person_list = json.results
                //this.person_list.sort( (a,b) => { a.long_name < b.long_name } )
                //debugger
                if(callback) callback()
            })
        },
        get_person_by_url(url) {
            if( !url) return this.person_unset
            const obj = this.person_list.find( item => item.url == url )
            if( !obj ) return this.person_unset
            return obj
        },          
        get_person_by_long_name(long_name) {
            if( !long_name ) return this.person_unset
            const obj = this.person_list.find( item => item.long_name == long_name )
            if( !obj ) return this.person_unset
            return obj
        },
        get_transcriptions_by_person( person, callback  )  {
            transcription_list_by_person( person, (json)=>{
                if( json && json.results ) {
                    json.results.forEach( item => {
                        item.document_name = this.doc_name( item.document )
                    })
                    callback( json.results )
                }
                else
                    callback( [] )
            })
        },
        // return the created object
        do_create_person( name, long_name, note, callback ) {
            if( long_name == "" ) { long_name = name }
            person_add( name, long_name, note, (json) => {
                this.load_persons( () => { 
                    if(callback) callback(json)
                })
            })
        },    
        do_delete_person( person, callback ) {
            person_delete( person, () => {
                this.load_persons(  ()=> {
                    if(callback) callback()
                })
            })
        },
        do_save_person( person, callback ) {
            person_save( person, () => {
                this.load_persons(  ()=> {
                    if(callback) callback()
                })
            })
        },


        load_places() {
            this.place_list = []
            place_list( json => {
                this.place_list = json.results
                this.place_list.sort( (a,b) => { a.long_name < b.long_name } )
                //debugger
            })
        },
        get_place_by_url(url) {
            if( !url) return this.place_unset
            const obj = this.place_list.find( item => item.url == url )
            if( !obj ) return this.place_unset
            return obj
        },          
        get_place_by_long_name(long_name) {
            if( !long_name ) return this.place_unset
            const obj = this.place_list.find( item => item.long_name == long_name )
            if( !obj ) return this.place_unset
            return obj
        },  
        // return the created object
        do_create_place( name, long_name, note, callback ) {
            if( long_name == "" ) { long_name = name }
            place_add( name, long_name, note, (json) => {
                this.load_places()
                if(callback) callback(json)
            })
        },    
        do_delete_place( place, callback ) {
            place_delete( place, () => {
                this.load_places()
                if(callback) callback()
            })
        },
        do_save_place( place, callback ) {
            place_save( place, () => {
                this.load_places()
                if(callback) callback()
            })
        },




        load_event_list(callback) {
            if (!this.user) return;
            event_list( json => {
                this.event_list = json.results
                this.event_list.forEach( x => {
                    if( x.action == "login failed") {
                        x.username = x.obj_name
                        x.obj_name = "-"
                    } else {
                        x.username = x.user.first_name + ' ' + x.user.last_name 
                    }
                    const d = new Date(x.date)
                    x.date_str = d.toLocaleString()
                })
                //debugger
                if (callback) callback()
            })
        },

        load_site_numbers(callback) {
            if (!this.user) return;
            this.site_numbers = []
            stat_list(json => {
                this.site_numbers = json.results
                //debugger
                if (callback) callback()
            })

        },

        do_save_document_flags( doc, callback ) {
            doc_save_flags( doc, ()=>{
                this.load_persons() //??
                if(callback) callback()
            })
        },


        remove_transcription( url ) {
            if( !this.user ) return;
            user_transcription_delete( url, () => { 
                this.load_user_transcriptions()
            })
        },

        get_transcriptions_by_docname( docname, callback ) {
            if( !this.user ) return;
            transcription_list_by_docname( docname, callback )
        },

        do_create_transcription( data, callback ) {
            transcription_add( data, (json) => {
                //console.log( 'do_create_transcription', json )
                if( callback ) callback(json)
            } )
        },

        do_save_transcription( url, data, callback ) {
            transcription_save( url, data, callback )
        },

        do_delete_transcription( tr, callback ) {
            // does not take care if this was the current transcription

            if( !this.user ) return 
            if( !tr || !tr.url ) {
                console.error( "appStore::delete_transcription - bad arguments" )
                this.$toast.error( "appStore::delete_transcription - bad arguments" ) 
                return
            }
            transcription_delete( tr, () => {
                if(callback) callback()
            })
        },
        do_make_transcription_current( tr, callback ) {
            if( !this.user ) return 
            if( !tr || !tr.url || !tr.document ) {
                console.error( "appStore::do_make_transcription_current - bad arguments" ) 
                this.$toast.error( "appStore::do_make_transcription_current - bad arguments" ) 
                return
            }
            const tr_id = tr.url.split('/').at(-2)
            //console.log( 'do_make_transcription_current', tr_id )
            transcription_make_current( tr_id, ()=>{
                if(callback) callback()
            })
        },
        do_make_transcription_final( doc, callback ) {
            if( !this.user ) return 
            if( !doc || !doc.url ) {
                console.error( "appStore::do_make_transcription_final - bad arguments" ) 
                this.$toast.error( "appStore::do_make_transcription_final - bad arguments" ) 
                return
            }
            doc_finalize( doc, () => {
                if(callback) callback()
            })
        },
        do_revert_transcription_final( doc, callback ) {
            if( !this.user ) return 
            if( !doc || !doc.url ) {
                console.error( "appStore::do_revert_transcription_final - bad arguments" ) 
                this.$toast.error( "appStore::do_revert_transcription_final - bad arguments" ) 
                return
            }
            doc_unfinalize( doc, () => {
                if(callback) callback()
            })
        },
        begin_editing( doc, callback ) {
            if( !this.user ) return 
            if( !doc || !doc.url ) {
                console.error( "appStore:: begin_editing - bad arguments" ) 
                this.$toast.error( "appStore:: begin_editing - bad arguments" ) 
                return
            }
            this.doc_being_edited = doc
            doc_edit_begin( this.user, doc, ()=> {
                if(callback) callback()
            })
        },
        done_editing( doc, callback ) {
            if( !this.user ) return 
            if( !doc || !doc.url ) {
                console.error( "appStore::done_editing - bad arguments" ) 
                this.$toast.error( "appStore::done_editing - bad arguments" ) 
                return
            }
            doc_edit_end( doc, ()=> {
                if(callback) callback()
            })
            if( this.doc_being_edited && this.doc_being_edited.url == doc.url ) 
                this.doc_being_edited = null
        },

        // to be called on tab/browser closing
        end_editing() {
            if( this.doc_being_edited ) {
                doc_edit_end( this.doc_being_edited )
                this.doc_being_edited = null
            }
        },

        // ---------- chat --------------

        load_rooms( callback ) {
            
            this.rooms = []
            this.messages = []

            if( !this.user) {
                if(callback) callback() 
                return
            }

            this.rooms_loading = true
            this.rooms_loaded = false

            room_list( json => {
                const username = this.user.first_name + " " + this.user.last_name
                if ( json && json.results ) {
                    json.results.forEach( r => {

                        r.roomId = r.id
                        delete r.id
                        r.roomName = r.name.replace(username,"")
                        delete r.name
                        r.users.forEach( u=> {
                            u._id = u.id
                            delete u.id
                            u.username = u.first_name + " " + u.last_name
                        })

                        if(r.lastMessage) 
                            r.lastMessage = this.adapt_msg( r.lastMessage )

                    })
                    this.rooms = json.results
                }
                this.rooms_loading = false
                this.rooms_loaded = true
                this.update_total_unread_messages()
                //debugger
                if(callback) callback(this.user) 
            })
        },
        adapt_msg(m) {
            m._id = m.id
            delete m.id
            m.senderId = m.sender.id
            m.username = m.sender.first_name + " " + m.sender.last_name
            m.timestamp = m.timestamp.substring(0,5)
          //adapt message gira sui messaggi ricevuti 
          //che quindi sono stati salvati, distributi, non sono stati cancellati e non ci sono stati errori
          //( non serve mettere questi campi bel DB )
            m.saved = true
            m.distributed = true
            m.deleted = false
            m.failure = false
          //  m.seen= false  --- settato a false in incoming message, e settato diversamente in set_current_room
            return m
        },
        set_current_room( arg ) {   // arg = { room, options }
            this.messages = []
            this.messages_loaded = false
            this.current_room = arg.room
            this.current_room.unreadCount = 0    //##### added
            this.update_total_unread_messages()  //##### added

            const date_read = Date.parse( arg.room.date_read )  //##### ???
            
            message_list( "?room="+arg.room.roomId, json => {
                //console.log( "set_current_room", json )
                json.forEach( m => { 
                    this.adapt_msg(m) 
                    const date_wroted = Date.parse( m.date +" " + m.timestamp )
                    m.seen = date_read > date_wroted    //##### ??? 
                })
                this.messages =  json
                this.messages_loaded = true
                //debugger
            })
        },
        incoming_message( m ) {
            if( !this.user ) return 
            //console.log( 'incoming_message', m.content, m, this.current_room.roomId ) 
            m = this.adapt_msg(m)
            m.seen = false
            // se il nuovo messaggio e' nella room corrente, aggiorno this.messages
            if( m.room == this.current_room.url ) {
                this.messages.push(m)
                this.messages = [ ...this.messages ]
            } 
            // imposta il lastMessage nella Room corrispondente, e fai il refresh della RoomList
            const room = this.rooms.find( x => x.url == m.room )
            if( room ) {
                room.lastMessage = m
                room.unreadCount = room.unreadCount+1
                this.rooms = [...this.rooms]
            }
            this.update_total_unread_messages()
            //debugger
        },
        create_room( name, members_url, group, callback ) {
            if( !this.user ) return

            room_create( name, this.user.url, members_url, group, json => {
                if(callback) callback(json)
            })
        },
        // return the modified room
        edit_room( room, new_name, new_members_url, callback ) {
            if( !this.user ) return

            room_update( room.url, new_name, new_members_url, json => {
                if( json ) {  // json is the modified room
                    this.load_rooms()
                    if(callback) callback(true)
                }
                else
                    if(callback) callback(false)
            })
        },
        get_room_url( roomId ) {
            let room = this.rooms.find( r => r.roomId==roomId )
            return ( room ) ? room.url : null
        },
        send_message( arg ) {
            if( !this.user ) return 
            const room_url = this.get_room_url( arg.roomId )
            message_create( room_url, this.user, arg.content, () => {} )
        },
        remove_contact( room, callback  ) {
            if( !this.user ) return 
            if( room.group ) return 
            room_delete( room.url, () => {
                if( callback ) callback()
            })
        },
        update_total_unread_messages() {
            this.total_unread_messages = 0 
            this.rooms.forEach( r => {
                this.total_unread_messages += r.unreadCount
            })
        },


        set_language( lang ) {
            const x = ( lang == 'it') ? 'it' : 'en'
            pinia_i18n.locale = x
            localStorage.setItem("lang", x );
            this.lang_current = x
        },
        set_lang_edit( value ) {
            this.lang_edit = value
        },
        load_site_text( callback )  {
            site_text_load( data => {

                let ita = {}
                let eng = {}
                this.lang_ids = {}

                data.forEach( item => {
                    const [section] = item.name.split(".")
                    ita[ section ] = {}
                    eng[ section ] = {}
                })
                data.forEach( item => {
                    this.lang_ids[item.name] = item.id
                    const [section,name] = item.name.split(".")
                    ita[ section ][ name ] = item.text_ita
                    eng[ section ][ name ] = item.text_eng
                })

                pinia_i18n.setLocaleMessage( 'it', ita );
                pinia_i18n.setLocaleMessage( 'en', eng );
                this.lang_ita = ita
                this.lang_eng = eng
                this.set_language('it')    

                //debugger
                if(callback) 
                    callback()
            })
        },
        set_site_text( text_id, text_ita, text_eng ) {
            //console.log('set_site_text')
            const [section,name] = text_id.split(".")
            this.lang_eng[ section ][ name ] = text_eng
            this.lang_ita[ section ][ name ] = text_ita

            // se non c'e' l'id, creo un site_text nuovo e aggiorno l'id
            const id = this.lang_ids[text_id]
            if(!id) {
                site_text_add( text_id, text_eng, text_ita, ( json )=>{ 
                    console.log( 'site_text_add', json )
                    this.lang_ids[text_id] = json.id
                })
            }
            else
            {
                site_text_update( id, text_id, text_eng, text_ita, ()=>{} )
            }
        },

        show_theme_editor( on ) {
            this.theme_editor = on
        },
        set_theme( vuetify, name ) {
            // devo passare vuetify perche non e' accessibile da pinia
            name = ( name=='dark') ? 'dark' : 'light'
            this.theme_current = name
            //console.log('set_theme >>'+name+'<<' )
            vuetify.theme.dark = ( name == 'dark' )
            this.theme_colors = vuetify.theme.themes[ name ] // tengo una reference ad un pezzo di vuetify
        },
        set_theme_color( name, color ) {
            this.theme_colors[name] = color
        },



        get_busta( name ) {
            return this.buste.find( b => b.name == name )
        },
        get_copybook( name ) {
            return this.copybooks.find( b => b.name == name )
        },
        load_series() {
            let b
            this.series = {}
            let serie1 = { buste : [] }
            serie1.title = "Serie E. LXI. 1 - Corrispondenza con la Marchesa Isabella d'Este"
            serie1.subtitle = "( lettere autografe ricevute, buste 1890-1902 )" 
            serie1.text = "E' costituita da lettere indirizzate alla marchesa, che furono qui radunate, forse dopo essere state tolte dalle filze originarie d'appartenenza in cui si trovavano. Le lettere sono divise per  anno, con una lacuna dal 1515 al 1518, e per località di provenienza."
            b = this.get_busta( "1890" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1504";         serie1.buste.push(b)
            b = this.get_busta( "1891" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1505-1508";    serie1.buste.push(b)
            b = this.get_busta( "1892" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1509";         serie1.buste.push(b)
            b = this.get_busta( "1893" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1510";         serie1.buste.push(b)
            b = this.get_busta( "1894" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1511-1514";    serie1.buste.push(b)
            b = this.get_busta( "1895" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1519";         serie1.buste.push(b)
            b = this.get_busta( "1896" ); b.note= "Lettere da Roma e Ferrara" ;                b.years= "1520";         serie1.buste.push(b)
            b = this.get_busta( "1897" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1520";         serie1.buste.push(b)
            b = this.get_busta( "1898" ); b.note= "Lettere da Ferrara e Panna" ;               b.years= "1521";         serie1.buste.push(b)
            b = this.get_busta( "1899" ); b.note= "Lettere da Mantova" ;                       b.years= "1521";         serie1.buste.push(b)
            b = this.get_busta( "1900" ); b.note= "Lettere di provenienze diverse" ;           b.years= "1521";         serie1.buste.push(b)
            b = this.get_busta( "1901" ); b.note= "Lettere da Ferrara, Milano, Parma";         b.years= "1522";         serie1.buste.push(b)
            b = this.get_busta( "1902" ); b.note= "Lettere da Mantova e provenienze diverse" ; b.years= "1522";         serie1.buste.push(b) 
            this.series['serie1'] = serie1


            let serie2 = { buste : [] }
            serie2.title = "Serie F II 6 - Lettere originali dei Gonzaga"
            serie2.subtitle = "( originali Gonzaga, tra cui lettere autografe di Isabella, buste 2106-2134 )" 
            serie2.text = "Questa serie racchiude la corrispondenza familiare che i Gonzaga di Mantova scambiarono tra di loro o che inviarono ai loro diretti collaboratori, i quali, perciò, la conservarono nell'archivio dei loro Signori."
            b = this.get_busta("2106"); b.years="1484-1490";   serie2.buste.push(b) 
            b = this.get_busta("2107"); b.years="1491";        serie2.buste.push(b) 
            b = this.get_busta("2108"); b.years="1492-1493";   serie2.buste.push(b) 
            b = this.get_busta("2109"); b.years="1494";        serie2.buste.push(b) 
            b = this.get_busta("2110"); b.years="1495";        serie2.buste.push(b) 
            b = this.get_busta("2111"); b.years="1496";        serie2.buste.push(b) 
            b = this.get_busta("2112"); b.years="1497-1498";   serie2.buste.push(b) 
            b = this.get_busta("2113"); b.years="1499";        serie2.buste.push(b) 
            b = this.get_busta("2114"); b.years="1500-1501";   serie2.buste.push(b) 
            b = this.get_busta("2115"); b.years="1502-1503";   serie2.buste.push(b) 
            b = this.get_busta("2116"); b.years="1504-1506";   serie2.buste.push(b) 
            b = this.get_busta("2117"); b.years="1507-1508";   serie2.buste.push(b) 
            b = this.get_busta("2118"); b.years="1509";        serie2.buste.push(b) 
            b = this.get_busta("2119"); b.years="1510-1512";   serie2.buste.push(b) 
            b = this.get_busta("2120"); b.years="1513-1514";   serie2.buste.push(b) 
            b = this.get_busta("2121"); b.years="1515";        serie2.buste.push(b) 
            b = this.get_busta("2122"); b.years="1516";        serie2.buste.push(b) 
            b = this.get_busta("2123"); b.years="1517-1519";   serie2.buste.push(b) 
            b = this.get_busta("2124"); b.years="1520-1521";   serie2.buste.push(b) 
            b = this.get_busta("2125"); b.years="1521";        serie2.buste.push(b) 
            b = this.get_busta("2126"); b.years="1522";        serie2.buste.push(b) 
            b = this.get_busta("2126a");b.years="1522";        serie2.buste.push(b) 
            b = this.get_busta("2127"); b.years="1523";        serie2.buste.push(b) 
            b = this.get_busta("2128"); b.years="1524";        serie2.buste.push(b) 
            b = this.get_busta("2129"); b.years="1525";        serie2.buste.push(b) 
            b = this.get_busta("2130"); b.years="1526";        serie2.buste.push(b) 
            b = this.get_busta("2131"); b.years="1527-1528";   serie2.buste.push(b) 
            b = this.get_busta("2132"); b.years="1529-1535";   serie2.buste.push(b) 
            b = this.get_busta("2133"); b.years="1536-1537";   serie2.buste.push(b) 
            b = this.get_busta("2134"); b.years="1538-1541 ";  serie2.buste.push(b) 
            this.series['serie2'] = serie2

            // NDOC / NDOCWT da sistemare
            let c = {}
            let serie3 = { copybooks : [] }
            serie3.title = "Serie F II 9a - Copialettere ordinari Misti"
            serie3.subtitle = "( copialettere 136,176,206, 209-211, 282, 311, buste 2904, 2911, 2916, 2917, 2929, 2936 )" 
            serie3.text = "I copialettere di Isabella sparsi tra quelli ordinari misti sono:"
            c = this.get_copybook("136"); c.years="1490 - 1491";       serie3.copybooks.push(c)
            c = this.get_copybook("176"); c.years="1502 - 1503";       serie3.copybooks.push(c)
            c = this.get_copybook("206"); c.years="1509";              serie3.copybooks.push(c)
            c = this.get_copybook("209"); c.years="1509 - 1510";       serie3.copybooks.push(c)
            c = this.get_copybook("210"); c.years="1510";              serie3.copybooks.push(c)
            c = this.get_copybook("211"); c.years="1510";              serie3.copybooks.push(c)
            c = this.get_copybook("282"); c.years="1524, 1525, 1527";  serie3.copybooks.push(c)
            c = this.get_copybook("311"); c.years="1534 - 1537";       serie3.copybooks.push(c)            
            this.series['serie3'] = serie3

            let serie4 = { copybooks : [] }
            serie4.title = "Serie F II 9e - Copialettere particolari di Isabella d’Este "
            serie4.subtitle = "( copialettere 1-53, buste 2991-3000 )" 
            serie4.text = "Sono 53 libri di copialettere divisi in 10 buste. Come si e’ detto, non tutti i copialettere di Isabella si trovano in questa  sede, poiché molti sonto frammischiati a quelli ordinari, come  pure molte copie di lettere da lei scritte si trovano nei copialettere del marito e del figlio." +  
            "I libri 15, 26, 27 di questa serie avrebbero dovuto essere collocati tra i copialettere ordinari e misti, contenendo molte lettere anche di Francesco II Gonzaga."
            for( let i=1; i<53; i+=1 ) {
                c = this.get_copybook( i.toString() ); serie4.copybooks.push(c)
            }
            this.series['serie4'] = serie4

            //correggere title/subtitle
            let serie5 = { buste : [] }
            serie5.title = "Raccolta d' Autografi"
            serie5.subtitle = "( lettere autografe ricevute, buste 1-5, 7-10 )" 
            serie5.text = "Questa collezione, costituita dagli archivisti nel secolo XVIII con documenti dell'Archivio Gonzaga, e, in seguito, arricchita  con documenti posteriori al periodo gonzaghesco, è suddivisa nelle seguenti voci: Papi, Sovrani, Cardinali, Elettori dell'Impero, Capitani, Musicisti, Pittori, Storici, Letterati, Poeti, Scienziati, Gonzaga, Uomini politici, Comici, Varii."
            b = this.get_busta( "0001");  b.note="Luigi XII di Francia, Ercole d'Este, Lucrezia Borgia";                                                                                        serie5.buste.push(b)
            b = this.get_busta( "0002");  b.note="Lucrezia Borgia";                                                                                                                             serie5.buste.push(b)
            b = this.get_busta( "0003");  b.note="Lucrezia Borgia";                                                                                                                             serie5.buste.push(b)
            b = this.get_busta( "0004");  b.note="Lucrezia Borgia";                                                                                                                             serie5.buste.push(b)
            b = this.get_busta( "0005");  b.note="Francesco II Gonzaga, Federico Gonzaga, Cesare Borgia, Eleonora Gonzaga";                                                                     serie5.buste.push(b)
            b = this.get_busta( "0007");  b.note="Bernardino Parentino, Francesco Francia, Giovanni Bel lini, Andrea Mantegna, Lodovico Mantegna, Pietro Perugino, Tiziano Vecellio ";          serie5.buste.push(b)
            b = this.get_busta( "0008");  b.note="Bernardo Aretino, Pietro Bembo, Veronica Gambara,  Aldo Manuzio, Pietro Pomponazzo, Giovanni Rucellai,  Bernardo Tasso, Ludovico Ariosto";    serie5.buste.push(b)
            b = this.get_busta( "0009");  b.note="G. G. Trissino, Isabella Gonzaga, moglie di Ferrante  Gonzaga";                                                                               serie5.buste.push(b)
            b = this.get_busta( "0010");  b.note="Galeazzo Sforza, Lorenzo da Pavia.";                                                                                                serie5.buste.push(b)

            // b = this.get_busta( "0001");  b.note="Luigi XII di Francia\nErcole d'Este\nLucrezia Borgia";                                                                                        serie5.buste.push(b)
            // b = this.get_busta( "0002");  b.note="Lucrezia Borgia";                                                                                                                             serie5.buste.push(b)
            // b = this.get_busta( "0003");  b.note="Lucrezia Borgia";                                                                                                                             serie5.buste.push(b)
            // b = this.get_busta( "0004");  b.note="Lucrezia Borgia";                                                                                                                             serie5.buste.push(b)
            // b = this.get_busta( "0005");  b.note="Francesco II Gonzaga\nFederico Gonzaga\nCesare Borgia\nEleonora Gonzaga";                                                                     serie5.buste.push(b)
            // b = this.get_busta( "0007");  b.note="Bernardino Parentino\nFrancesco Francia\nGiovanni Bel lini\nAndrea Mantegna\nLodovico Mantegna\nPietro Perugino\nTiziano Vecellio ";          serie5.buste.push(b)
            // b = this.get_busta( "0008");  b.note="Bernardo Aretino\nPietro Bembo\nVeronica Gambara\n Aldo Manuzio\nPietro Pomponazzo\nGiovanni Rucellai\n Bernardo Tasso\nLudovico Ariosto";    serie5.buste.push(b)
            // b = this.get_busta( "0009");  b.note="G.G.Trissino\nIsabella Gonzaga\nmoglie di Ferrante Gonzaga";                                                                               serie5.buste.push(b)
            // b = this.get_busta( "0010");  b.note="Galeazzo Sforza\nLorenzo da Pavia.";                                                                                                          serie5.buste.push(b)

            this.series['serie5'] = serie5
            
            let serie6 = { buste : [] }
            serie6.title = "Collezione Volta d'Autografi"
            serie6.subtitle = "( lettere autografe spedite da Isabella, busta VA1 )" 
            serie6.text = "La collezione Volta fu costituita nella prima metà del secolo XIX dallo storico  mantovano L.C. Volta, prefetto della Biblioteca governativa di  Mantova, con documenti estratti dall'Archivio Gonzaga."
            b = this.get_busta( "VA01"); b.years="1500 - 1522"; serie6.buste.push(b)
            this.series['serie6'] = serie6

            let serie7 = { buste : [] }
            serie7.title = "Collezione Volta d'Autografi"
            serie7.subtitle = "( lettere autografe ricevute da Isabella, buste V1,V2,V3 )" 
            serie7.text = "Le missive che hanno, invece, Isabella come destinataria, sono, suddivise per mittente"
            // b = this.get_busta( "V001"); b.note="Ludovico Acquanegra ... Federico Fregoso"; serie7.buste.push(b)
            // b = this.get_busta( "V002"); b.note="Gerolamo Gabloneta ...Giacomo Guicciardini"; serie7.buste.push(b)
            // b = this.get_busta( "V003"); b.note="Giuliano de' Medici ... Pietro Antonio  Vivani"; serie7.buste.push(b)
            b = this.get_busta( "V001"); b.note="Acquanegra - Fregoso"; serie7.buste.push(b)
            b = this.get_busta( "V002"); b.note="Gabloneta - Guicciardini"; serie7.buste.push(b)
            b = this.get_busta( "V003"); b.note="Medici - Vivani"; serie7.buste.push(b)
            this.series['serie7'] = serie7


        }
    }
})

