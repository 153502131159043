<template>
    <div class="mx-6" data-cy="view-guide-summary"> 
        
        <!--- header ---->    
        <div class="text-h6 pagetitle pt-3" >
            <LocText text_id="g.title" /> 
        </div> 

        <!--- chapters ---->    
        <div class="pt-3">
            <ul>
                <li> <LocText text_id="g_intro.link"            link="/guide/intro"         /> </li>
            </ul>
            <div class="py-3"><strong><LocText text_id="g_toc.chapter1" /></strong></div>
            <ul>
                
                <li> <LocText text_id="g_archive.link"          link="/guide/archive"       /> </li>
                <li> <LocText text_id="g_viewer.link"           link="/guide/viewer"        /> </li>
                <li> <LocText text_id="g_search.link"           link="/guide/search"        /> </li>
                <li> <LocText text_id="g_person.link"           link="/guide/person"        /> </li>
                <li> <LocText text_id="g_place.link"            link="/guide/place"         /> </li>
            </ul>
            <div class="py-3"><strong><LocText text_id="g_toc.chapter2" /> </strong></div>
            <ul>

                <li> <LocText text_id="g_transcription.link"    link="/guide/transcription" /> </li>
                <!--                 
                    <li> <LocText text_id="sidebar.guideline"       link="/guideline"           /> </li>
                -->
            
            </ul>
            <div class="py-3"><strong><LocText text_id="g_toc.chapter3" /></strong></div>
            <ul>
                <li> <LocText text_id="g_signup.link"           link="/guide/signup"        /> </li>
                <li> <LocText text_id="g_login.link"            link="/guide/login"         /> </li>
                <li> <LocText text_id="g_lostpsw.link"          link="/guide/lost_password" /> </li>
            </ul>
            <div class="py-3"><strong><LocText text_id="g_toc.chapter4" /></strong></div>
            <ul>
                <li> <LocText text_id="g_draft.link"            link="/guide/draft"         /> </li>
                <li> <LocText text_id="g_document.link"         link="/guide/document"      /> </li>
                <li> <LocText text_id="g_bookmark.link"         link="/guide/bookmark"      /> </li>
                <li> <LocText text_id="g_chat.link"             link="/guide/chat"          /> </li>
                <li> <LocText text_id="g_history.link"          link="/guide/history"       /> </li>
            </ul>
        </div>
    </div>    
</template>

<script>
import LocText    from "../components/LocText"
//import { mapState    }   from 'pinia'
//import { mapActions  }   from 'pinia'
//import { useAppStore }   from '../stores/appStore'

export default { 
    name: "Guide",    
    components: { LocText },
}
</script>
<style scoped>
    .topdiv {
        max-width:500px !important;
        margin:auto !important;
        line-height: 2 !important;
    }
</style>



