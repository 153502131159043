<template>
    <v-dialog persistent :value="show" max-width="600" >
        <v-card class="pa-4">

            <v-card-title class="text-h5"> <LocText text_id="chat_add.title" /> </v-card-title>
            <v-tabs fixed-tabs v-model="mode">
                <v-tab> <LocText text_id="chat_add.tab_contact"/></v-tab>
                <v-tab> <LocText text_id="chat_add.tab_group"/></v-tab>
            </v-tabs>
            <v-tabs-items v-model="mode" class="pt-4">

                <!----- create contact ---->
                <v-tab-item >
                    <v-card-text flat>
                        <v-autocomplete 
                            v-model="contact_url" 
                            :items="contact_candidates"  
                            item-text="full_name"
                            item-value="url"                              
                            data-cy="chat_contact_name">
                                <template v-slot:label>
                                    <LocText text_id="chat_add.contact" />
                                </template>
                        </v-autocomplete> 
                    </v-card-text>
                    <v-card-actions class="pb-5">
                        <v-spacer/>
                        
                        <v-btn 
                            color="primary" 
                            depressed 
                            data-cy="add-contact_url-ok-btn"
                            @click="on_create_contact"       
                            >  
                            <LocText text_id="general.ok" /> 
                        </v-btn>
                        <v-btn
                            depressed 
                            data-cy="add-contact_url-cancel-btn"
                            @click="on_close" 
                            > 
                            <LocText text_id="general.cancel" /> 
                        </v-btn>
                        <v-spacer/>
                    </v-card-actions>
                </v-tab-item>

                <!----- create group ---->
                <v-tab-item >
                    <v-card-text flat>
                        <v-form  ref="form" v-model="group_name_valid" >

                            <div class="pb-3">
                                <LocText text_id="chat_add.subtitle" />
                            </div>

                            <v-text-field 
                                v-model="group_name"     
                                data-cy="group_name"
                                label="group name"
                                :rules="[ group_name_rule ]">
                                    <template v-slot:label>
                                        <LocText text_id="chat_add.group_name" />
                                    </template>
                            </v-text-field>

                            <v-autocomplete 
                                v-model="members_url" 
                                :items="member_candidates" 
                                item-text="full_name"
                                item-value="url"                              
                                multiple
                                chips   
                                deletable-chips
                                data-cy="chat_members_name"
                                label="members">
                                    <template v-slot:label>
                                        <LocText text_id="chat_add.group_members" />
                                    </template>
                            </v-autocomplete>

                        </v-form>
                    </v-card-text>

                    <v-card-actions class="pb-5">
                        <v-spacer/>
                        <v-btn 
                            color="primary" 
                            depressed 
                            data-cy="add-group-ok-btn"
                            v-bind:disabled="!group_name_valid" 
                            @click="on_create_group"       
                            >  
                            <LocText text_id="general.ok" /> 
                        </v-btn>
                        <v-btn
                            depressed 
                            data-cy="add-group-cancel-btn"
                            @click="on_close" 
                            > 
                            <LocText text_id="general.cancel" /> 
                        </v-btn>
                        <v-spacer/>
                    </v-card-actions>
                </v-tab-item>
            </v-tabs-items>            
        </v-card>
    </v-dialog>   
</template>

<script>
import LocText              from "../components/LocText"
import { useAppStore  }     from '../stores/appStore'
import { mapState    }      from 'pinia'
import { mapActions  }      from 'pinia'

export default {
    name: "ChatAddRoom",
    props : [ 'show' ],
    components: { LocText },
    data: () => ({
        mode: 0,                 // 0=contact_url, 1=group

        group_name : "",
        group_name_valid:false,          

        member_candidates : [],  // all users except me  -- array of objects
        members_url:[],              // array of users url

        contact_candidates : [], // all users except me and any existing contact_url of mine -- array of objects
        contact_url : null,          // the choosen contact_url url 

        show_error: false,
    }),
    watch : {
        show()          { if(this.show) this.on_show() },
        group_name()    { this.show_error = false },
        contact_url()   { this.show_error = false },
        members_url()   { this.show_error = false },
    },
    computed : {
        ...mapState(   useAppStore, ['rooms','users','user'] ),
    },
    mounted() {
        this.load_users()
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'load_users',
            'load_rooms', 
            'create_room', 
            'add_room_user',
        ] ),
        on_show() {
            //console.log("on_show")

            // candidate are all the users except the current user
            this.member_candidates = this.users.filter( x => x.id != this.user.id )

            // collect my contact_url ==    members_url other then me, in each room of type !group
            var contact_set = new Set()
            this.rooms.forEach( r => { 
                 if( !r.group )    
                     r.users.forEach( u => contact_set.add( u._id ) )
            })
            // contact_candidates are the member_candidates minus my current contact_url
            this.contact_candidates = this.member_candidates.filter( u => !contact_set.has(u.id) ) // boolean difference
        },
        group_name_rule(value) { 
            if( !value ) return "required"
            if(  value.length < 2 ) return "name_too_short"
            if( !value.match( /^[a-z1-9 _']+$/i ) ) return "invalid character"
            return true
        },
        on_create_group() {
            this.create_room( this.group_name, this.members_url, true, json => {
                const new_room = json
                if( new_room.url ) {
                    // this.add_room_user( new_room.url, this.user.url )
                    // this.members_url.forEach( member_url => {  
                    //         this.add_room_user( new_room.url, member_url )
                    // }) 
                    this.load_rooms()
                    this.on_close()
                }
                else
                {
                    //console.log( "result", json )
                }
            })
        },
        on_create_contact() {
            if( !this.contact_url ) // contact_url is the url of the choosen user
                return
            const contact_obj = this.users.find( x => x.url == this.contact_url )    
            const room_name = this.user.full_name + " " + contact_obj.full_name
            const members_url = [ this.contact_url ]

            this.create_room( room_name, members_url, false,  result => {
                const new_room = result
                if( new_room.url ) {
                    // this.add_room_user( new_room.url, this.user.url )
                    // this.add_room_user( new_room.url, this.contact_url )
                    this.load_rooms()
                    this.on_close()
                }
                else
                {
                    //console.log( "result", result )
                }
            })
        },
        on_close() {
            this.members_url = []
            this.contact_url = ""
            this.group_name = ""
            this.$emit('done')
        },
    },
};
</script>

<style scoped>
</style>
