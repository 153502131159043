<template>
    <v-container fluid pa-0>
        <div class="d-flex justify-start pa-1">
            <v-btn small depressed color="primary"  @click="on_save()" > save   </v-btn>
            <v-btn small depressed class="pa-3"     @click="on_cancel" > cancel </v-btn>
        </div>    
        <TiptapVuetify
            v-model="contents"
            :extensions="extensions"
            :card-props="{ flat:true }"
            :minHeight="ed_height"
            :maxHeight="ed_height"
            :toolbar-attributes="toolbar_attributes"
        >
        </TiptapVuetify>
    </v-container>
</template>

<script>
import { 
    TiptapVuetify, 
    Heading, 
    Bold, 
    Italic, 
    Strike, 
    Underline, 
    Code, 
    Paragraph, 
    BulletList, 
    OrderedList, 
    ListItem, 
    Link, 
    Blockquote, 
    HardBreak, 
    HorizontalRule, 
    History } from 'tiptap-vuetify'

//import Superscript from '@tiptap/extension-superscript' 
import { mapState     } from 'pinia'
import { useAppStore  } from '../stores/appStore'


export default { 
    name: "TextEditor",  
    props : [ 'text' ],
    components: { TiptapVuetify },
    data() {
        return {
            contents:"",

            ed_height:'500px',

            // declare extensions you want to use
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {options: {levels: [1, 2, 3] } } ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],

            nativeExtensions: [],
        }
    },
    created() {
        //this.nativeExtensions.push( Superscript )
    },
    mounted () {
        this.contents=this.text
        // call .bind as otherwise the `this` will not point to the component
        this.bounded_resize = this.on_resize.bind(this); 
        window.addEventListener("resize", this.bounded_resize );
        this.on_resize()
    },
    destroyed() {
        window.removeEventListener("resize", this.bounded_resize );
    },
    watch : {
        text() {
            this.contents=this.text
        } 
    },      
    computed : {
        ...mapState(   useAppStore, [ 'theme_current' ] ),

        toolbar_attributes() {
            if( this.theme_current == "dark") {
                return { color: 'black', dark:true }
            } else {
                return { color:'box1', dark:false } // colore: black,green,yellow ok -- info,error ok -- gray no 
            }
        },
    },
    methods : {
        on_resize() {
            //console.log( 'on_resize' )
            //const cbh  = document.getElementById("doc_header")
            const h1 = window.innerHeight
            const h2 = 48//( cbh ? cbh.clientHeight : 0 )
            const h3 = 0 // padding of the container
            const h4 = 64 // nav
            const h5 = 42 // ed toolbar
            //console.log( 'on_resize',h1,h2,h3,h4,h5 )
            this.ed_height = (h1-h2-h3-h4-h5-2)+"px"
            //console.log( 'TextEditor::on_resize ed_height=', this.ed_height )
        },        
        on_save() {
            this.$emit('save', this.contents )
        },
        on_cancel() {
            this.$emit('cancel' )
        }
    }
}
</script>

<style scoped >
.tiptap-vuetify-editor >>> .tiptap-vuetify-editor__content {
    transition: all 0s !important; 
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

