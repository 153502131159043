<template>
    <v-dialog persistent width="500" v-model="show" >
        <v-card>
            <v-card-title class="text-h5">
                <LocText text_id="person_edit.title" />
            </v-card-title>
            <v-card-text>
                <v-form v-model="valid">

                    <v-text-field  
                        v-model="name"      
                        :rules = name_rules 
                        data-cy="person-edit-name" >
                            <template v-slot:label>
                                <LocText text_id="person_edit.name" />
                            </template>
                    </v-text-field>

                    <v-text-field  
                        v-model="long_name"
                        data-cy="person-edit-long-name" >
                            <template v-slot:label>
                                <LocText text_id="person_edit.long_name" />
                            </template>
                    </v-text-field>

                    <v-textarea auto-grow rows="1"
                        v-model="note"
                        data-cy="person-edit-note" >
                            <template v-slot:label>
                                <LocText text_id="person_edit.note" />
                            </template>
                    </v-textarea>

                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    depressed
                    color="primary" 
                    @click="on_save" 
                    :disabled="!valid"
                    data-cy="person-edit-ok"
                    > 
                        <LocText text_id="general.save" /> 
                </v-btn>
                <v-btn 
                    depressed
                    class="ml-3"
                    @click="on_cancel"                       
                    data-cy="person-edit-cancel"
                    > 
                        <LocText text_id="general.cancel" /> 
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import LocText          from "../components/LocText"
import { mapState    }  from 'pinia'
import { mapActions  }  from 'pinia'
import { useAppStore }  from '../stores/appStore'

export default { 
    name: "PersonEdit",    
    props : [ 'show', 'person' ],    
    components: { LocText },
    data() {
        return {
            valid : false,
            name  : "",
            long_name : "",
            note  : "",
            name_rules: [
                value => !!value || 'required',
                value => this.person_list.findIndex( item => item.name == value ) == -1 || value == this.person.name || 'name already used'
            ],            
        }
    },
    created() {
        if(this.show) this.on_show()
    },
    watch : {
        show() { if(this.show) this.on_show() }
    },
    computed : {
        ...mapState( useAppStore, ['person_list'] ),
    },
    methods : {

        ...mapActions( useAppStore, [ 
            'do_save_person',
        ] ),
        on_show() {
            this.name       = this.person.name
            this.long_name  = this.person.long_name
            this.note       = this.person.note
        },
        on_save() {
            //console.log('on_save')
            this.person.name     = this.name       
            this.person.long_name= this.long_name  
            this.person.note     = this.note       
            this.do_save_person( this.person ) 
            this.$emit('done') 
        },
        on_cancel() {
            this.$emit('done') 
        }

    },
}

//TODO:  dopo create person si dovrebbe aprire la pagina della persona creata

</script>
