<template>
    <v-container fluid data-cy="view-user-document">

        <!-- no user--->

        <div v-if="!user" class="pa-1 pagetitle">
            <LocText text_id="user_doc.documents"/> 
        </div>

        <!-- have user --->

        <div v-if="user" >
    
            <!-- list mode --->
            <div v-if="view_mode==0">

                <!-- header --->

                <div class="d-flex justify-start align-center text-h6 pb-2 ">
                    <div class="pagetitle text-h6">
                        <LocText text_id="user_doc.documents"/> 
                    </div>
                    <v-spacer/>
                    <v-btn 
                        color="primary" dark small depressed 
                        @click="on_add_document" 
                        data-cy="userdoc-add"
                        > 
                        <LocText text_id="user_doc.new" />  
                    </v-btn>
                </div>
                
                <!-- table --->

                <v-data-table
                    mobile-breakpoint="100"
                    :headers="lang_current=='en' ? userdoc_headers_eng : userdoc_headers_ita"
                    :items="user_documents"
                    :items-per-page="100"
                    dense
                    @click:row="on_open_document">
                </v-data-table>

            </div>
            
            <!-- document mode --->
            
            <div v-if="view_mode==1">

                <!-- header --->                
            
                <div class="d-flex justify-start align-center text-h6 pb-2 ">

                    <router-link class="text-decoration-none router-link pr-2" to="/user_document" >  
                        <LocText text_id="user_doc.documents"/> 
                    </router-link>
                    / 
                    <input class="px-2" 
                        id="userdoc-title"
                        type="text"
                        v-model="userdoc.title"
                        v-autowidth="{maxWidth: '500px', minWidth: '50px', comfortZone: 0}"
                        data-cy="userdoc-title"
                        placeholder="insert title here" 
                    />

                    <v-icon v-if="bookmark"   @click="remove_bookmark($route.path)" >mdi-bookmark</v-icon>
                    <v-icon v-if="!bookmark"  @click="add_bookmark   ($route.path, userdoc.title)" >mdi-bookmark-outline</v-icon>

                    <v-spacer/>

                    <v-btn 
                        color="primary" dark small depressed 
                        @click="on_add_document"  
                        class="mr-2"
                        data-cy="userdoc-add"
                        > 
                        <LocText text_id="user_doc.new" />  
                    </v-btn>

                    <v-btn 
                        color="primary" dark small depressed 
                        @click="on_save_document" 
                        class="mr-2"
                        data-cy="userdoc-save"
                        > 
                        <LocText text_id="user_doc.save" /> 
                    </v-btn>
                
                    <v-btn 
                        color="error" dark small depressed 
                        @click="userdoc_delete_alert=true"    
                        data-cy="userdoc-delete"
                        >
                        <LocText text_id="user_doc.delete" /> 
                    </v-btn>
                </div>

                <!-- EDITOR --->

                <TiptapVuetify
                    v-model="userdoc.text"
                    :extensions="extensions"
                    :z--card-props="{ flat:true, color:'white'}"
                    :card-props="{ flat:true }"
                    :minHeight="ed_height"
                    :maxHeight="ed_height"
                    placeholder="Welcome" 
                    :toolbar-attributes="toolbar_attributes"
                >
                </TiptapVuetify>
            </div>
        </div>

        <!---- DELETE DLG ----->
        <v-dialog width="280" v-model="userdoc_delete_alert" >
            <v-card>
                <v-card-title>
                    <LocText text_id="user_doc.delete_confirm_title" />
                </v-card-title>
                <v-card-text>
                    <LocText text_id="user_doc.delete_confirm_text" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="error"  
                        depressed dark 
                        @click="on_delete_document" 
                        data-cy="delete-confirm-yes"
                        >
                            <LocText text_id="general.yes" /> 
                    </v-btn>
                    <v-spacer/>
                    <v-btn depressed  
                        @click="userdoc_delete_alert=false"  
                        data-cy="delete-confirm-no"
                        >          
                            <LocText text_id="general.no" /> 
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>    
</template>

<script>
import LocText                  from "../components/LocText"
import { mapState, mapActions } from 'pinia'
import { useAppStore  }        from '../stores/appStore'
import { 
    TiptapVuetify, 
    Heading, 
    Bold, 
    Italic, 
    Strike, 
    Underline, 
    Code, 
    Paragraph, 
    BulletList, 
    OrderedList, 
    ListItem, 
    Link, 
    Blockquote, 
    HardBreak, 
    HorizontalRule, 
    History } from 'tiptap-vuetify'

export default { 

    name: "UserDocument",    
    components: { LocText, TiptapVuetify },
    data() {
        return {
            view_mode : 0, // 0=table, 1=single document
            userdoc: null,
            userdoc_delete_alert : false,
            ed_height:'500px',
            bounded_resize : null,
            
            userdoc_headers_ita: [    
                 { text: 'titolo',               value: 'title'  },
                 { text: 'date creazione:',      value: 'date_created' },
                 { text: 'data ultima modifica', value: 'date_modified' },
            ],
            userdoc_headers_eng: [    
                 { text: 'title',         value: 'title'  },
                 { text: 'date created',  value: 'date_created' },
                 { text: 'date modified', value: 'date_modified' },
            ],

            extensions: [
                History,
                [Heading, {options: {levels: [1, 2, 3] } } ],
                Paragraph,
                HardBreak,

                Bold,
                Italic,
                Underline,
                Strike,

                Link,
                Code,
                Blockquote,

                ListItem,
                BulletList,
                OrderedList,
                HorizontalRule,
            ],            
        }
    },
    mounted () {
        this.load_documents()

        this.contents=this.text
        // call .bind as otherwise the `this` will not point to the component
        this.bounded_resize = this.on_resize.bind(this); 
        window.addEventListener("resize", this.bounded_resize );
        this.on_resize()
    },
    destroyed() {
        window.removeEventListener("resize", this.bounded_resize );
    },    
    watch: {
        $route() {
            this.load_documents()
        },
        user_documents() {
            this.load_documents()
        }
    },
    computed : {
        bookmark() {
            return this.get_bookmark( this.$route.path )
        },

        ...mapState(   useAppStore, ['user', 'user_documents', 'bookmarks', 'lang_current', 'theme_current' ] ),

        toolbar_attributes() {
            if( this.theme_current == "dark") {
                return { color: 'black', dark:true }
            } else {
                return { color:'box1', dark:false } // colore: black,green,yellow ok -- info,error ok -- gray no 
            }
        },
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'load_user_documents', 
            'add_user_document', 
            'save_user_document', 
            'delete_user_document',
            'get_bookmark',
            'add_bookmark',
            'remove_bookmark'
        ] ),

        load_documents() {
            
            const params = this.$route.params['query']
            //console.log( "UserDocument::load_document -- params=", params )

            this.userdoc = { 'title':'' }
            this.view_mode = 0
            if( params )
            {
                this.userdoc = this.user_documents.find( item => item.id == params )
                if( this.userdoc )
                    this.view_mode = 1
            }
        },
        on_open_document( doc ) {
            //console.log( "UserDocument::on_open_document -- doc=", doc, doc.id )
            this.$router.replace( "/user_document/"+doc.id+"/" )
        },
        on_add_document() {
            this.add_user_document( this.on_document_added )
        },
        on_document_added( doc ) {
            //console.log( "UserDocument::on_document_added -- doc=", doc )
            if(!doc) return
            this.on_open_document(doc)
        },
        on_save_document() {
            if( !this.userdoc ) return
            this.save_user_document( this.userdoc )
        },
        on_delete_document() {
            this.userdoc_delete_alert=false
            this.delete_user_document( this.userdoc )
            this.view_mode = 0
        },
        on_resize() {
            const h1 = window.innerHeight
            const h2 = 64 // nav
            const h3 = 40 // doc_header
            const h4 = 24 // padding of the container
            const h5 = 42 // ed toolbar
            //console.log( 'on_resize',h1,h2,h3,h4,h5 )
            this.ed_height = (h1-h2-h3-h4-h5-2)+"px"
            //console.log( 'TextEditor::on_resize ed_height=', this.ed_height )
        },           
    }
}
</script>
<style scoped>
#userdoc-title {
    outline: 0px solid transparent;
    color:      var(--v-text-base   ) !important;
}
#userdoc-title:focus-within {
    outline: 1px solid #ddd;
    border-radius : 5px;
    color:      var(--v-text-base   ) !important;
}


.tiptap-vuetify-editor >>> .tiptap-vuetify-editor__content {
    transition: all 0s !important; 
}
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}



.text-body {
    margin: 20px !important;
}
.text-body a {
    pointer-events: none;
}
.text-body h1 {
    margin: 10px 0 20px !important;
}
.text-body h2 {
    margin: 10px 0 20px !important;
}
.text-body h3 {
    margin: 10px 0 20px !important;
}
.text-body blockquote {
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding-left: 1em;
    margin: 20px 0 !important;
}
.text-body code {
    padding: 0 4px !important;
    margin: 0 5px !important;
}
.text-body pre code {
    padding: 8px !important;
    margin: 0 5px !important;
}
.text-body code:before {
    content: none !important;
    letter-spacing: initial !important;
}
.text-body code:after {
    content: none !important;
    letter-spacing: initial !important;
}
.text-body p {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    min-height: 1rem;
}
</style>



