<template>      
<div>
    <chat-window
        :theme="theme_current"
        :height="chat_height"
        :show-audio="show_audio"
        :show-reaction-emojis="show_reaction_emojis"
        :show-files="show_files"
        :current-user-id="current_user_id"
        :text-messages="lang_current=='it' ? text_ita : text_eng"

        :rooms="rooms"
        :loading-rooms="rooms_loading"
        :roomsLoaded="rooms_loaded"
        
        :messages="messages"
        :messages-loaded="messages_loaded"

        :menu-actions="menu_actions"

        :styles="cssvars"

        @fetch-messages="on_fetch_message"
        @send-message="send_message"
        @add-room="show_add_room=true"
        @menu-action-handler="on_menu_action"
    />
    <ChatAddRoom
        :show="show_add_room"
        @done="add_room_done"
    />
    <ChatEditRoom
        :show="show_edit_room"
        :room="current_room"
        @done="edit_room_done"
    />
    <Confirm
        :show="ask_remove_contact_confirmation"  
        title="Remove Contact"
        text="clicking ok will remove the selected contact from your chat. Are you shure ?"
        @yes="on_contact_delete()" 
        @no="ask_remove_contact_confirmation=false" 
    />    

</div>
</template>

<script>

import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import ChatAddRoom              from "../components/ChatAddRoom"
import ChatEditRoom             from "../components/ChatEditRoom"
import Confirm                  from "../components/Confirm"
import { mapState      }        from 'pinia'
import { mapActions    }        from 'pinia'
import { useAppStore   }        from '../stores/appStore'

export default {
    name:"Chat",
    components: { ChatWindow, ChatAddRoom, ChatEditRoom, Confirm },

    data () {
        return {
            chat_height:"500px",
            show_audio:false,
            show_reaction_emojis:false,
            show_files:false,

            menu_actions: [],

            show_add_room:false,
            show_edit_room:false,
            ask_remove_contact_confirmation:false,

            bounded_resize : null,

            text_eng : {
                ROOMS_EMPTY: 'No rooms',
                ROOM_EMPTY: 'No room selected',
                NEW_MESSAGES: 'new messages',
                MESSAGE_DELETED: 'message deleted',
                MESSAGES_EMPTY: 'No messages',
                CONVERSATION_STARTED: 'Chat started the:',
                TYPE_MESSAGE: 'Type message',
                SEARCH: 'Search',
                IS_ONLINE: 'is online',
                LAST_SEEN: 'last seen ',
                IS_TYPING: 'is typing...',
                CANCEL_SELECT_MESSAGE: 'cancel selected message'
            },            
            text_ita : {
                ROOMS_EMPTY: 'Nessuna conversazione',
                ROOM_EMPTY: 'Nessuna conversazione selezionata',
                NEW_MESSAGES: 'Nuovi messaggi',
                MESSAGE_DELETED: 'messaggio eliminato',
                MESSAGES_EMPTY: 'Nessun messaggio',
                CONVERSATION_STARTED: 'Conversazione iniziata il:',
                TYPE_MESSAGE: 'Scrivi messaggio',
                SEARCH: 'Cerca',
                IS_ONLINE: "e' in linea",
                LAST_SEEN: 'ultima connessione ',
                IS_TYPING: 'sta scrivendo...',
                CANCEL_SELECT_MESSAGE: 'annulla selezione'
            },      
        }
    },
    mounted () {
        // call .bind as otherwise the `this` will not point to the component
        this.bounded_resize = this.on_resize.bind(this); 
        window.addEventListener("resize", this.bounded_resize );
        this.on_resize()
    },
    destroyed() {
        window.removeEventListener("resize", this.bounded_resize );
    },    
    watch : {
        //room_id() { console.log( 'room_id',this.room_id) },
        lang_current() { this.on_language() }
    },    
    computed : {
        ...mapState(   useAppStore, [
            'lang_current',
            'theme_current',
            'theme_colors',
            'user',
            'users', 
            'rooms', 
            'current_room', 
            'rooms_loading', 
            'rooms_loaded', 
            'messages',
            'messages_loaded'
        ] ),
        
        current_user_id() { 
            return this.user ? this.user.id : 0 
        }, 
        cssvars() {
            return {
                general : {
                    color : this.theme_colors.text,
                    backgroundInput: this.theme_colors.field,
                    colorPlaceholder: this.theme_colors.text,
                    //borderStyle: 'solid 1px #555555',
                },
                header : {  
                    background : this.theme_colors.sheet,
                    colorRoomName: this.theme_colors.title,
                    colorRoomInfo: this.theme_colors.lab,
                },
                footer: {
                    background : this.theme_colors.sheet,
                },
                content: {
                    background : this.theme_colors.chatbg,
                },
                sidemenu: {
                    background: this.theme_colors.sheet,
                    backgroundHover: this.theme_colors.field,
                    backgroundActive: this.theme_colors.button,
                    //colorActive: this.theme_colors.active,
                    //borderColorSearch: '#181a1b'
                },   
                message: {
                    background: this.theme_colors.chat2,
                    backgroundMe: this.theme_colors.chat1,
                    color: this.theme_colors.text,
                },                             
            }
        },
    },
    methods : {
        ...mapActions( useAppStore, [ 
            'set_current_room',
            'send_message',
            'incoming_message',
            'remove_contact',
        ] ),
        add_room_done() {
            this.show_add_room = false
        },
        edit_room_done() {
            this.show_edit_room = false
        },
        on_menu_action(  arg  ) {  // arg={ roomId:..., action:{ name:..., title:...} }
            let action = arg.action.name
            //console.log('arg',arg)
            if( action == 'group_settings') {
                this.show_edit_room = true
            }
            if( action == 'contact_delete') {
                //console.log('contact_delete request')
                this.ask_remove_contact_confirmation=true;
            }
        },
        on_fetch_message( arg ) {
            // called when the current room change, and maybe more often
            // arg = { room, options }
            this.set_current_room(arg)
            this.update_menu_actions()
        },
        on_language() {
            this.update_menu_actions()
        },
        update_menu_actions() {
            if(!this.current_room) return
            if(this.current_room.group )
                this.menu_actions = [ { name: 'group_settings', title: this.$t("chat_page.group_settings") } ]
            else
                this.menu_actions = [ { name: 'contact_delete', title: this.$t("chat_page.contact_delete") } ]
        },
        on_contact_delete() {
            this.ask_remove_contact_confirmation=false
            this.remove_contact( this.current_room, () => {
                //console.log( 'on_contact_delete res=',result)
            })
        },
        on_resize() {
            const h1 = window.innerHeight
            const h4 = 64 // nav
            this.chat_height = (h1-h4-2)+"px"
        },
    },
    sockets: {
        chat_message: function (data) {
            //console.log('on message:',data )
            this.incoming_message(data)
        }
    },    
}
</script>

<style scoped>
</style>
