<template>
    <v-dialog width="480" v-model="show" >
        <v-card>
            <v-card-title>
                <LocText text_id="transcription.edit_lock_title" />
            </v-card-title>
            <v-card-text v-if="document">
                <LocText text_id="transcription.edit_lock_description" />
                <v-checkbox v-model="document.lock_date"            label="lock date" />
                <v-checkbox v-model="document.lock_sender"          label="lock sender" />
                <v-checkbox v-model="document.lock_sender_place"    label="lock sender place" />
                <v-checkbox v-model="document.lock_recipient"       label="lock addressee" />
                <v-checkbox v-model="document.lock_cc_recipient"    label="lock cc addressee" />
                <v-checkbox v-model="document.lock_transcription"   label="lock transcription" />
                <v-checkbox v-model="document.lock_synopsis"        label="lock synopsis" />
                <v-checkbox v-model="document.lock_bibliography"    label="lock bibliography" />
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    color="primary" depressed dark 
                    @click="on_submit" 
                    data-cy="trl-submit" > 
                        <LocText text_id="general.submit" /> 
                    </v-btn>
                <v-btn depressed 
                    class="ml-3"
                    @click="on_cancel"
                    data-cy="trl-cancel" > 
                        <LocText text_id="general.cancel" /> 
                    </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import LocText              from "../components/LocText"
//import { mapState    }    from 'pinia'
import { mapActions    }    from 'pinia'
import { useAppStore   }    from '../stores/appStore'

export default { 
    name: "TranscriptionEditLock",    
    props : [ 'document', 'show' ],
    components: { LocText },
    methods : {
        ...mapActions( useAppStore, [ 
            'do_save_document_flags',
        ] ),
        on_submit()  { 
            this.do_save_document_flags( this.document, ()=>{
                this.$emit('done')  
            })
        },
        on_cancel()  { 
            this.$emit('cancel')   
        },
    },
}
</script>

<style scoped>
</style>



