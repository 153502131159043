<template>
  <div>

    <!-- required, se lo tolgo ho errori di layout nella pagina -->
    <!-- <v-app-bar app flat fixed color="#ece8df" :clipped-left="sidebar_clipped" id="main-toolbar"> -->
    <v-app-bar 
        app 
        flat 
        fixed 
        :clipped-left ="sidebar_clipped" 
        :clipped-right="sidebar2_clipped" 
        color="navbar"
        >

        <v-app-bar-nav-icon @click.stop="show_sidebar = !show_sidebar" />
        

        <!-- <v-img max-width="30px" :src="require('../assets/sigillo.png')" ></v-img> -->

        <!-- <v-img max-width="30px" :src="require('../assets/sigillo_desat.png')" ></v-img> -->
       
        <!-- <v-img max-width="30px" :src="require('../assets/logo_idea.svg' )"></v-img> -->

        <!---
        <v-toolbar-title class="d-flex align-center" style="width:202px;" >
        <div class="pr-3">IDEA</div>
        <v-img max-width="100" :src="require('../assets/idea_letters_black.svg')"/>
        </v-toolbar-title>
        -->

        <v-toolbar-title class="text-h5 pl-2" :style="{ width : '202px' }" >
            <!--             
            <router-link 
                to="/" 
                data-cy="nav-title" 
                class="router-link" 
                style="font-family: Homenaje, Oswald, Roboto, sans-serif; font-size:32px;"
                >
                Idea Letters
                <v-icon style="ont-size:26px;" > mdi-feather </v-icon>    
            </router-link>
             -->
<!-- 
            <div 
                class="nav-title"
                data-cy="nav-title" 
                style="font-family: 'Nunito Sans', Homenaje, Oswald, Roboto, sans-serif; font-size:28px; "
                @click="go_home()"
                >
                IDEA letters
                <v-icon > mdi-feather </v-icon>    
            </div>
 -->

<!-- 
            <img v-if="theme_current=='dark'"  src="/static/letters/vue/img/logo_dark.svg" @click="go_home()" />
            <img v-if="theme_current!='light'" src="/static/letters/vue/img/logo.svg"      @click="go_home()" />
 -->
            <img v-if="theme_current == 'dark'  " :src="require('../assets/logo_yellow.svg' )" @click="go_home()" />
            <img v-if="theme_current == 'light' " :src="require('../assets/logo_black.svg' )" @click="go_home()" />
 
            <v-tooltip bottom v-if="!$root.$data.connected" >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                        class="pb-2"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        >
                        mdi-alert
                    </v-icon>
                </template>
                <span>The connection with the server is not active, please hold on.</span>
            </v-tooltip>
        </v-toolbar-title>

        <!-- search_form --->

        <!-- <v-menu offset-y :close-on-content-click="false" v-model="show_search_filters" z-index="11"> -->


            <!-- <template v-slot:activator="{ on, attrs }"> -->

            <!-- search widget -->
            
            <div v-if="!$vuetify.breakpoint.mobile" class="search-widget">
                <v-btn icon small><v-icon color="text">mdi-magnify</v-icon></v-btn>
                <input 
                    type="search" 
                    placeholder="search" 
                    class="search-input" 
                    v-on:keyup.enter="do_search" 
                    v-model="search_text"
                />
                <!-- <v-btn icon small v-bind="attrs" v-on="on"><v-icon>mdi-filter-menu-outline</v-icon></v-btn> -->
                <v-btn icon small @click="do_show_search_filters(true)"><v-icon color="text">mdi-filter-menu-outline</v-icon></v-btn>
            </div>
            <!-- </template> -->

            <!-- search help -->
            <!-- 
            <v-form ref="search_form">
                <v-card>
                    <v-card-text>
                        <div class="d-flex justify-start pb-2"><div class=search_info_lab>Bx              </div>search for Busta number x</div>
                        <div class="d-flex justify-start pb-2"><div class=search_info_lab>Bx Dy           </div>search for Document y within Busta x </div>
                        <div class="d-flex justify-start pb-2"><div class=search_info_lab>Cx              </div>search for Copybook number x</div>
                        <div class="d-flex justify-start pb-2"><div class=search_info_lab>Cx Py           </div>search for Page y of Copybook number x</div>
                        <div class="d-flex justify-start pb-2"><div class=search_info_lab>Cx Dy           </div>search for Document y within Copybook number x</div>
                        <div class="d-flex justify-start pb-2"><div class=search_info_lab>any other text  </div>will be searched within the existing Transcriptions</div>

                    </v-card-text>    
                    <v-card-actions>
                        <v-spacer />
                        <v-btn elevation="0" class="pb-2 pr-2" @click="show_search_filters=false">close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>    
            -->
  
        <!-- </v-menu> -->

        <v-dialog v-model="show_search_filters" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <SearchFilters 
                @close="do_show_search_filters(false)"  
                @set_search_text="set_search_text"
            />   
        </v-dialog>     

        <v-spacer/>

        <div v-if="$vuetify.breakpoint.mobile">
                <v-icon class="ma-2" @click="do_show_search_filters(true)" >mdi-magnify</v-icon>
        </div>
        


        <!-- language -->

        <!-- <v-menu left bottom open-on-hover z-index="11" min-width="250" > -->
        <v-menu  bottom :offset-y="true" :offset-x="false" z-index="11" min-width="148" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" icon v-bind="attrs" v-on="on" data-cy="lang-btn" width="20px" height="20px">
                    {{ lang_current }}
                </v-btn>
            </template>
            <v-list>
                <v-list-item data-cy="lang-eng" @click="set_language('en')"  >english</v-list-item>
                <v-list-item data-cy="lang-ita" @click="set_language('it')"  >italiano</v-list-item>
            </v-list>
        </v-menu>

        <!-- theme -->

        <v-menu bottom :offset-y="true" :offset-x="true"  z-index="11" min-width="148" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2 mr-4" icon v-bind="attrs" v-on="on" data-cy="theme-btn" width="20px" height="20px">
                    <v-icon v-if="theme_current=='light'" > mdi-weather-sunny </v-icon>
                    <v-icon v-else                        > mdi-brightness-3 </v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item data-cy="theme-light" @click="on_theme('light')"  >
                    <v-icon class="mr-2"> mdi-weather-sunny </v-icon>
                    <LocText text_id="theme.light"/>
                </v-list-item>
                <v-list-item data-cy="theme-dark" @click="on_theme('dark')"   >
                    <v-icon class="mr-2"> mdi-brightness-3 </v-icon>
                    <LocText text_id="theme.dark"/>
                </v-list-item>
                <v-list-item v-if="user && user.is_staff"  data-cy="theme-edit" @click="show_theme_editor(!theme_editor)"  >
                    <v-icon class="mr-2"> mdi-palette </v-icon>
                    theme editor
                </v-list-item>

<!-- 
                <v-list-item data-cy="theme-editor" @click="show_sidebar2 = !show_sidebar2"   >
                    <v-icon class="mr-2"> mdi-palette </v-icon>
                    toggle theme editor
                </v-list-item>
-->
            </v-list>
        </v-menu>


        <!-- user -->

        <v-menu bottom v-if="user && user.profile" z-index="11">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" fab small dark data-cy="user-btn">
                    {{user.profile.initials}} 
                </v-btn>
            </template>
            <v-list :style="{'min-width':'300px' }" >
                <v-list-item  class="pt-4" :style="{'justify-content': 'center !important'}" >
                    <v-btn color="primary" elevation="0" fab dark >{{user.profile.initials}}</v-btn>
                </v-list-item>
                <v-list-item :style="{'text-align':'center !important'}" >
                    <v-list-item-content >
                        <v-list-item-title >
                            {{user.username}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{user.email}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item 
                    link 
                    @click="on_logout()" 
                    :style="{'justify-content':'center !important'}"
                    data-cy="logout-btn"
                    >
                    logout
                </v-list-item>
            </v-list>
        </v-menu>

        <!-- login button -->

        <v-btn color="primary" depressed v-if="!user" @click="show_login=true" class="mr-2" data-cy="login-btn">
            <LocText text_id="nav.login"/>
        </v-btn>

        <v-btn color="primary" depressed v-if="!user" @click="show_signup=true"  class="mr-2" data-cy="signup-btn">
            <LocText text_id="nav.signup"/>
        </v-btn>
        
        <!-- v-btn v-if="!user" depressed @click="on_test" > test </v-btn -->
    </v-app-bar>
    <v-divider />


    <v-navigation-drawer app v-model="show_sidebar" :clipped="sidebar_clipped" enable-resize-watcher >
        <Sidebar />
    </v-navigation-drawer>     

    <v-navigation-drawer :stateless="true" v-if="user && user.is_staff" app right v-model="theme_editor" :clipped="sidebar_clipped" enable-resize-watcher width="300">
        <ThemeEditor @close="show_sidebar2=false" />
    </v-navigation-drawer>     


    <UserSignup 
        :show="show_signup"  
        @done="show_signup=false" 
    />
    <UserLogin 
        :show="show_login"
        @done="show_login=false"
        @lost_password="on_lost_password"
    />

    <UserPasswordReset
        :show="show_reset_password_dlg"
        @done="on_done_reset_password()" 
    />

  </div>
</template>

<script>
import Vue                  from "vue"  // toast
import LocText              from "../components/LocText"
import Sidebar              from "../components/Sidebar"
import ThemeEditor          from "../components/ThemeEditor"
import UserLogin            from "../components/UserLogin"
import UserSignup           from "../components/UserSignup"
import UserPasswordReset    from "../components/UserPasswordReset" 
import SearchFilters        from "../components/SearchFilters" 
import { useAppStore }      from '../stores/appStore'
import { mapState    }      from 'pinia'
import { mapActions  }      from 'pinia'

export default {
    name: "Navbar",
    components: { LocText, Sidebar, ThemeEditor, UserSignup, UserLogin, UserPasswordReset, SearchFilters },    
    data: () => ({

        show_login              : false,
        show_signup             : false,
        show_reset_password_dlg : false,
      //show_search_filters     : false,
        show_transcription_dlg  : false,
        show_sidebar            : false,
        show_sidebar2           : false,
        sidebar_clipped         : true,
        sidebar2_clipped        : true,
        search_text             : "",  
        show_maintenance_alert  : false,
        theme                   : null
    }),
    watch: {
        $route() {
            if( !this.$route.fullPath.startsWith('/search') )
                this.search_text = ""
        },
    },
    mounted() {
        if( localStorage.theme )
            this.on_theme( localStorage.theme )
    },  
    computed : {
        ...mapState(   useAppStore, ['user','authors','person_list','lang_current','theme_current', 'theme_editor', 'show_search_filters'] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'retrieve_user', 
            'do_logout', 
            'set_language',
            'set_theme',
            'show_theme_editor',
            'do_show_search_filters',
        ]),

        do_search() {
            //https://stackoverflow.com/questions/6163169/replace-multiple-whitespaces-with-single-whitespace-in-javascript-string
            const text = this.search_text.trim().replace(/\s+/g, ' ') 

            const B_match = text.match(/B[0-9]+/g)
            const C_match = text.match(/C[0-9]+/g)
            const D_match = text.match(/D[0-9]+/g)
            const P_match = text.match(/P[0-9]+/g)

            let B = B_match ? B_match[0] : ""  // la prima parola che inizia con 'B' seguito da un numero o stringa vuota
            let C = C_match ? C_match[0] : ""
            let D = D_match ? D_match[0] : ""
            let P = P_match ? P_match[0] : ""

            let path="/"
            if     ( C && D ) path += C+"_"+D
            else if( C && P ) path += C+"_"+P
            else if( C      ) path += C
            else if( B && D ) path += B+"_"+D  // in busta P e D coincidono
            else if( B      ) path += B
            //else path += "search=" + text.split(' ').join("|") 
            else {
                const words = text.split(' ').join(',')
                path += 'search/words=' + words + "&version=current"
                //console.log( 'path', path)
            }

            
            //console.log( text, "---", B_match, C_match, D_match ,P_match, "---", B, C, D ,P, "---", path )
            //if( path != this.$route.path )
            this.$router.push( path )
        },
        // do_filter_by_metadata(terms) {
        //     console.log( "terms", terms )

            // const person = this.person_list.find( x => x.name == this.person )
            // const author = this.authors.find( x => x.full_name == this.tr_author )

            
            // this.search_text = terms.join(' ').replace( 'version=current', '').replace(',', '  ').replace('words=','')

            // const path = "/search/" + terms.join('&')
            // this.$router.push( path )
            
            /*
            let search_text = ""
            let search_terms = []

            if( terms.words ) {
                search_text += terms.words.replace(',',' ') + ' '
                search_terms.push( "words="+terms.words ) 
            }
            if( terms.person ) {
                const name = terms.person.toLowerCase()
                search_text += "person=" + name + ' '
                const person = this.person_list.find( x => x.name.toLowerCase() == name )
                search_terms.push( "person="+person.id ) 
            }
            if( terms.date_from ) {
                search_text += "date_from=" + terms.date_from + ' '
                search_terms.push( "date_from=" + terms.date_from ) 
            }
            if( terms.date_to ) {
                search_text += "date_to=" + terms.date_to + ' '
                search_terms.push( "date_to=" + terms.date_to ) 
            }
            if( terms.version ) {
                if( terms.version != "current" )
                    search_text += "version=" + terms.version + ' '
                search_terms.push( "version=" + terms.version ) 
            }
            if( terms.author ) {
                const name = terms.author.toLowerCase()
                search_text += "author=" + name + ' '
                const author = this.authors.find( x => x.full_name.toLowerCase() == name )
                //console.log( "author name=", name, 'obj=', author, author.id )
                search_terms.push( "author="+author.id ) 
            }
            this.search_text = search_text
            const path = "/search/" + search_terms.join('&')
            this.$router.push( path )
            this.show_search_filters=false
            */

            // this.search_text = terms.join(' ').replace( 'version=current', '').replace(',', '  ').replace('words=','')
            // const path = "/search/" + terms.join('&')
            // this.$router.push( path )

        // },
        // do_filter_by_collocation( term ) {
        //     console.log( "term", term )
        //     this.search_text = term.replace('_','') 
        //     const path = "/" + term
        //     this.$router.push( path )
        //     this.show_search_filters=false
        // },
        set_search_text( terms ) {
            this.search_text = terms 
        },
        on_lost_password() {
            this.show_login = false
            this.show_reset_password_dlg = true
        },
        on_done_reset_password() {
            this.show_reset_password_dlg=false
            this.retrieve_user( (user) => {
                if( user && user.url ) // success -- reload all
                    window.location.pathname = "/" 
            })
        },
        on_test() {
            //console.log('on_test')
        },
        on_logout() {
            this.do_logout( () => {
                this.$router.push("/")
            })
        },

        on_theme( name ) {

            name = ( name == 'dark') ? 'dark' : 'light';
            this.set_theme( this.$vuetify, name )
            localStorage.theme = name
        },
        go_home() {
            if( this.$router.currentRoute.fullPath != '/')
                this.$router.push('/')
        }
    },
    sockets: {
        maintenance_alert: function () {
            //console.log('maintenance_alert_received:')
            //this.show_maintenance_alert=true
            const msg = "WARNING:\n" +
                        "Dear users\n" +
                        "this site will be shut down for maintenace in a few minutes,\n" +
                        "if you have any work in progress, we recommend to save it now.\n" +
                        "The maintenance will complete in about an hour.\n" +
                        "Thank you for your understanding.\n\n" +
                        "ATTENZIONE:\n" +
                        "Gentili utenti\n" +
                        "questo sito verra spento per manutenzione tra pochi minuti,\n" + 
                        "se avete dei lavori in corso vi preghiamo di salvare adesso.\n" + 
                        "La manutenzione dovrebbe completarsi entro circa un'ora.\n" +
                        "Vi ringraziamo per la vostra comprensione.\n" 

            Vue.$toast.info( msg, { timeout:0, position:"top-center" }  )
        }
    },        
};

</script>

<style scoped>
.search-widget {
  background-color : var(--v-search-base ); 
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0);
  padding: 8px;
  min-width: 200px;
  max-width: 600px;
  flex-grow: 5;
}
.search-input {
  background-color : var(--v-search-base ); 
  color :            var(--v-title-base ); 
  border: 0px solid rgba(0, 0, 0, 0);
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100% - 28px - 28px);
}
.search-input::placeholder {
  color: var(--v-text-base ); 
  opacity: 1;
}
.search-input:focus-visible {
  outline-style: none;
}
.v-window-item {
    padding: 0px 10px 0px 15px;
}
#main-toolbar{
    border-bottom:solid 0px #ddd !important;
}
.search_info_lab {
    min-width:100px;
}
/* https://stackoverflow.com/questions/61541258/change-padding-of-vuetify-v-expansion-panel-content */
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.z---router-link {
  text-decoration: none;
}
.nav-title {
    cursor:grab;
}

</style>
