<template>
    <v-dialog persistent width="280" v-model="show" >
        <v-card>
            <v-card-title>
                <LocText :text_id="title" />
            </v-card-title>
            <v-card-text>
                <LocText :text_id="text" />
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    color="error" depressed dark 
                    @click="on_yes" 
                    data-cy="confirm-yes" > 
                        <LocText text_id="general.yes" /> 
                    </v-btn>
                <v-spacer/>
                <v-btn depressed 
                    @click="on_no"
                    data-cy="confirm-no" > 
                        <LocText text_id="general.no" /> 
                    </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import LocText  from "../components/LocText"

export default { 
    name: "Confirm",    
    props : [ 'title', 'text', 'show' ],
    components: { LocText },
    methods : {
        on_yes() { this.$emit('yes')  },
        on_no()  { this.$emit('no')   },
    },
}
</script>

<style scoped>
</style>



