<template>
    <v-dialog 
        persistent 
        v-model="show_internal" 
        max-width="400" 
    >
        <v-card>
            <v-card-title class="text-h5">
            <LocText text_id="user.title" />
            </v-card-title>
            <v-card-text>
                <v-form  ref="form" v-model="valid" >

                    <v-text-field
                        v-model="initials"
                        :label="$t('user.initials')"
                    />
                    <v-text-field
                        v-model="title"
                        :label="$t('user.role')"
                    />
                    <v-text-field
                        v-model="first_name"
                        :label="$t('user.first_name')"
                        :rules="[ name_rule ]" 
                    />
                    <v-text-field
                        v-model="last_name"
                        :label="$t('user.last_name')"
                        :rules="[ name_rule ]" 
                    />
                    <v-text-field
                        v-model="email"
                        :label="$t('user.email')"
                        :rules="[ email_rule ]" 
                    />
                    <v-text-field
                        v-model="username"
                        :label="$t('user.username')"
                        :rules="[ name_rule ]" 
                    />
                    <v-text-field
                        v-model="organization"
                        :label="$t('user.organization')"
                        :rules="[ no_rule ]" 
                    />
                    <v-text-field
                        v-model="city"
                        :label="$t('user.city')"
                        :rules="[ no_rule ]" 
                    />
                    <v-text-field
                        v-model="country"
                        :label="$t('user.country')"
                        :rules="[ no_rule ]" 
                    />
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-5">
                <v-spacer/>
                <v-btn 
                    color="primary" 
                    text--color="white"
                    depressed 
                    @click="on_submit" 
                    v-bind:disabled="!valid" 
                > 
                    <LocText text_id="general.submit" />
                </v-btn>
                
                <v-btn depressed @click="on_close" >                          
                    <LocText text_id="general.cancel" />
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue              from "vue"
import LocText          from "../components/LocText"
import { fetch_json }   from '../api'
import { useAppStore }  from '../stores/appStore'
import { mapState }     from 'pinia'
//import { mapActions }   from 'pinia'


export default {
    name: "UserSettings",
    props : [ 'show' ],
    components: { LocText },    

    data: () => ({

        valid:          false,
        show_internal:  false,
        initials:       "",
        title:          "",
        first_name:     "",
        last_name:      "",
        email:          "",
        username:       "",
        organization:   "",
        city:           "",
        country:        "",

    }),
    created() {
        if( this.show ) this.on_show()
    },    
    watch: {
        show() {
            if( this.show ) this.on_show()
        },
    },
    computed : {
        ...mapState( useAppStore, ['user'] ),
     },
    methods : {
        //...mapActions( useAppStore, [ 'retrieve_user']),
        
        no_rule() { 
            return true
        },
        name_rule(value) { 
            if( !value ) return this.$t('signup.required')
            if(  value.length < 2 ) return this.$t('signup.name_too_short')
            if( !value.match( /^[a-z']+$/i ) ) return this.$t('signup.name_bad_char')
            return true
        },
        email_rule( value ) { 
            if( !value ) return this.$t('signup.required')
            if( !value.match( /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/ ) ) return this.$t('signup.bad_email')
            return true
        },
        username_rule(value) { 
            if( !value ) return this.$t('signup.required')
            if(  value.length < 2 ) return this.$t('signup.name_too_short')
            if( !value.match( /^[a-z0-9_]+$/i )) return this.$t('signup.username_bad_char')
            return true
        },
        on_show() {

            this.initials=       this.user.profile.initials
            this.title=          this.user.profile.title
            this.first_name=     this.user.first_name
            this.last_name=      this.user.last_name
            this.email=          this.user.email
            this.username=       this.user.username
            this.organization=   this.user.profile.organization
            this.city=           this.user.profile.city
            this.country=        this.user.profile.country
            this.show_internal = true
        },
        on_submit() {

            const body = new FormData()
            body.set('first_name',          this.first_name    )
            body.set('last_name',           this.last_name     )
            body.set('email',               this.email         )
            body.set('username',            this.username      )
            body.set('profile.initials',    this.initials      )
            body.set('profile.title',       this.title         )
            body.set('profile.organization',this.organization  )
            body.set('profile.city',        this.city          )
            body.set('profile.country',     this.country       )

            fetch_json( "PATCH", 'api/current_user/', body, "edit user settings", json => {
                //console.log('result json=', json )
                if( json && json.ok == 'true' ) {
                    Vue.$toast.success( this.$t('user.settings_saved'))
                    this.on_close()
                }
            })
            
        },
        on_close() {
            this.show_internal = false
            this.$emit('done') 
        },
    },
}
</script>
<style scoped>
</style>
