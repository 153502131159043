// ====== version 1 ======

/* 
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  theme: { dark: false },  
  / *
  theme: {
    themes: {
      light: {
        primary: '#0d6efd'
  //       secondary: '#b0bec5',
  //       accent: '#8c9eff',
  //       error: '#b71c1c',
      },
    },
  },
  * /
});
*/

// ====== version 2 with vuetify and tiptap-vuetify ======

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
Vue.use(Vuetify)

// https://github.com/iliyaZelenko/tiptap-vuetify#installation
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'

//const theme = { dark: false,  }
//const theme = { disable: true }

const theme = {
    options: { customProperties: true },
    dark : true,
    themes: {
        light: {
            accent:     "#FF4081",
            error:      "#FF5252",
            info:       "#2196F3",
            primary:    "#2196F3",
            secondary:  "#424242",
            success:    "#4CAF50",
            warning:    "#FB8C00",

            navbar:     "#D0D0D0",
            search:     "#FAFAFA",
            banner:     "#194858",
            page:       "#FAFAFA",      
            sheet:      "#FAFAFA",   
            panel:      "#DDD",
            field:      "#FFF",
            button:     "#FFF",

            title:      "#000000",
            text:       "#555555",
            lab:        "#333333", // il nome label non si puo usare -- side effect
            anchor:     "#2196F3",
            active:     "#2196F3", 

            box1:       "#64B5F6",   
            box1b:      "#FFFFFF",   
            box2:       "#F664DE",   
            box2b:      "#FFFFFF",   
            box3:       "#FFB74D",   
            box3b:      "#FFFFFF",   

            chatbg:     "#CCC",
            chat1:      "#C8E6C9",
            chat2:      "#FFF",

            chip1:      "#FB8C00",
            chip2:      "#64B5F6",
            chip3:      "#81C784",
            chip4:      "#FF4081",
        },
        dark: {
            accent:     "#FF4081",
            error:      "#FF5252",
            info:       "#2196F3",
            primary:    "#2196F3",
            secondary:  "#424242",
            success:    "#4CAF50",
            warning:    "#FB8C00",

            navbar:     "#194858", //"#0070c9",//"#006da1",
            search:     "#1C5F75", //"#2196F3",
            banner:     "#194858",
            page:       "#303030",      
            sheet:      "#303030",   
            panel:      "#303030",
            field:      "#4D4D4D",
            button:     "#272727",

            title:      "#FFFFFF",
            text:       "#CCCCCC", 
            lab:        "#AAAAAA",
            anchor:     "#2196f3", 
            active:     "#e7b051", 

            box1:       "#006da1",
            box1b:      "#005587",   
            box2:       "#AD0992",   
            box2b:      "#890873",   
            box3:       "#be7100",   
            box3b:      "#9d5600",   

            chatbg:     "#272727",
            chat1:      "#034E4D",
            chat2:      "#303030",

            chip1:      "#FB8C00",
            chip2:      "#2196F3",
            chip3:      "#4CAF50",
            chip4:      "#FF5252",

        }
    }
}


const options = { theme:theme }
const vuetify = new Vuetify( options )

const tiptap_options = {
  vuetify, // needed, important
  iconsGroup: 'mdi'
}

Vue.use( TiptapVuetifyPlugin, tiptap_options )

export default vuetify

