<template>
    <v-sheet class="pa-3" elevation="0" height="100%" width="100%" >

        <div class="d-flex justify-start align-center pa-1 mb-4">
            Theme editor
            <v-spacer/>
            <v-icon @click="show_theme_editor(false)" > mdi-close </v-icon>
        </div>

        <div v-if="!show_color_editor">

            <div v-for="group in color_groups" :key="group.name" >
                <v-expansion-panels flat class="mt-2" v-model="group.expanded">
                    <v-expansion-panel  >
                        <v-expansion-panel-header 
                            style="min-height: 40px !important; max-height: 40px !important; padding-right:0px; padding-left:4px;"
                            class="text-subtitle-2 font-weight-bold"
                            >
                            {{ group.name }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0">
                            <div v-for="color in group.children" :key="color.name" class="d-flex justify-start align-center pa-1">
                        
                                <div class="subtitle-2 label pl-4">{{ color.name }}</div>

                                <v-text-field dense class="input ma-0 pa-0" v-model="color.value" label="" hide-details="auto" @change="on_change(color)" />

                                <v-chip class="swatch ml-4 mb-1" :color="color.value"  @click="on_swatch_click(color)" />
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>



        </div>
        <div v-else>
            <div>
                <v-color-picker v-model="current_color_value" 
                    model="rgb"
                    show-swatches
                    swatches-max-height="240"
                    @update:color="on_color_edit"
                />
            </div>
            <div class="d-flex justify-start align-center mt-3" >
                <v-spacer/>
                <v-btn  color="primary" depressed @click="show_color_editor=false"> ok </v-btn>
                <v-spacer/>
                <v-btn  depressed @click="on_color_edit_cancel"> cancel </v-btn>
                <v-spacer/>
            </div>

        </div>

    </v-sheet>
</template>

<script>
import { useAppStore }      from '../stores/appStore'
import { mapState    }      from 'pinia'
import { mapActions  }      from 'pinia'

export default {
    name: "ThemeEditor",
    components: {  },    
    data: () => ({
        show_color_editor : false,
        current_color_name  : "none",
        current_color_value : "#000",
        current_color_backup : "#000",

        e1:false,
        e2:false,
        e3:false,
        e4:false,
        e5:false,
        e6:false,
    }),
    computed : {
        ...mapState(   useAppStore, ['theme_current', 'theme_colors'] ),
        
        color_groups : function() {
            return  [
                { 
                    name:"vuetify vars",
                    expanded : this.e1,
                    children: [
                        { name:'primary',     value:this.theme_colors.primary   },
                        { name:'secondary',   value:this.theme_colors.secondary },
                        { name:'info',        value:this.theme_colors.info      },
                        { name:'success',     value:this.theme_colors.success   },
                        { name:'warning',     value:this.theme_colors.warning   },
                        { name:'error',       value:this.theme_colors.error     },
                        { name:'accent',      value:this.theme_colors.accent    },
                    ]
                },
                { 
                    name:"foregrounds",
                    expanded : this.e2,
                    children: [
                        { name:'title',       value:this.theme_colors.title    },
                        { name:'text',        value:this.theme_colors.text     },
                        { name:'lab',         value:this.theme_colors.lab      },
                        { name:'anchor',      value:this.theme_colors.anchor   },
                        { name:'active',      value:this.theme_colors.active   },
                    ]
                },
                { 
                    name:"backgrounds",
                    expanded : this.e3,
                    children: [
                        { name:'navbar',      value:this.theme_colors.navbar  },
                        { name:'search',      value:this.theme_colors.search  },
                        { name:'banner',      value:this.theme_colors.banner  },
                        { name:'page',        value:this.theme_colors.page    },
                        { name:'sheet',       value:this.theme_colors.sheet   },
                        { name:'panel',       value:this.theme_colors.panel   },
                        { name:'field',       value:this.theme_colors.field   },
                        { name:'button',      value:this.theme_colors.button  },
                    ]
                },
                { 
                    name:"archive",
                    expanded : this.e4,
                    children: [
                        { name:'box1',        value:this.theme_colors.box1    },
                        { name:'box1b',       value:this.theme_colors.box1b   },
                        { name:'box2',        value:this.theme_colors.box2    },
                        { name:'box2b',       value:this.theme_colors.box2b   },
                        { name:'box3',        value:this.theme_colors.box3    },
                        { name:'box3b',       value:this.theme_colors.box3b   },
                    ]
                },
                { 
                    name:"chips",
                    expanded : this.e5,
                    children: [
                        { name:'chip1',      value:this.theme_colors.chip1  },
                        { name:'chip2',      value:this.theme_colors.chip2  },
                        { name:'chip3',      value:this.theme_colors.chip3  },
                        { name:'chip4',      value:this.theme_colors.chip4  },
                    ]
                },
                { 
                    name:"chat",
                    expanded : this.e6,
                    children: [
                        { name:'chatbg',      value:this.theme_colors.chatbg  },
                        { name:'chat1',       value:this.theme_colors.chat1   },
                        { name:'chat2',       value:this.theme_colors.chat2   },
                    ]
                }
            ]
        }
    },
    methods: {
        ...mapActions( useAppStore, ['set_theme_color', 'show_theme_editor']),

        on_change( color ) {  // user edited th value in the v-input
            //console.log('on_change', color )
            this.set_theme_color( color.name, color.value )
        },
        on_swatch_click( color ) { 
            //console.log('on_swatch_click', color )
            this.current_color_name = color.name
            this.current_color_value = color.value
            this.current_color_backup = color.value
            this.show_color_editor = true
        },
        on_color_edit( value ) { 
            //console.log('on_color_edit', value.hex )
            this.set_theme_color( this.current_color_name, value.hex )
        },
        on_color_edit_cancel() { 
            this.set_theme_color( this.current_color_name, this.current_color_backup )
            this.show_color_editor = false
        },
    },
};
</script>

<style scoped>
    .label {
        min-width: 120px;
    }
    .swatch {
        width:  40px;
        border: 1px solid gray !important;
        border-radius: 5px !important;
    }
    .input {
        max-width:  75px;
    }
    .v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
          padding: 0 !important;
    }

</style>

