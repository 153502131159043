<template>
    <v-sheet class="sidebar" elevation="0" height="100%" width="100%" >

        <!-- links --->

        <v-list dense  >
            <v-list-item v-for="item in sidebar_items" :key="item.title" >
                <v-list-item-icon> 
                    <v-icon color="anchor" >{{ item.icon }}</v-icon> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                            <LocText :text_id="item.title" :link="item.to" />
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <div v-if="user">
                <v-list-item v-for="item in sidebar_user_items" :key="item.title" >
                    <v-list-item-icon> 
                        <v-icon color="anchor">{{ item.icon }}</v-icon> 
                        <v-badge 
                            v-if="item.title == 'sidebar.chat'" 
                            :content="total_unread_messages" 
                            :value="total_unread_messages" 
                            overlap 
                            color="green" 
                        />
                    </v-list-item-icon>


                    <v-list-item-content>
                        <v-list-item-title>
                            <LocText :text_id="item.title" :link="item.to" />
                        </v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </div>

            <!------ bookmarks -------->

            <v-expansion-panels 
                v-if="user && bookmarks.length"    
                flat class="pa-1" 
                data-cy="bookmarks-panel">
                <v-expansion-panel  >
                    <v-expansion-panel-header 
                        z---color="#fff" 
                        style="min-height: 25px !important; max-height: 25px !important; padding-right:12px;"
                        class="text-subtitle-2 font-weight-bold"
                        data-cy="bookmarks-header"
                        >
                        <LocText text_id="sidebar.bookmarks" /> 
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-list-item v-for="item in bookmarks" :key="item.path" >
                            <v-list-item-icon> 
                                <v-icon>mdi-bookmark</v-icon> 
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div class="d-flex justify-start align-centre">
                                        <router-link 
                                            class="text-decoration-none router-link"
                                            :to="item.path"
                                            data-cy="bookmark-link"
                                            >
                                            {{ item.label }}
                                        </router-link>
                                        <v-spacer />
                                        <v-icon small 
                                            @click="remove_bookmark(item.path)"
                                            data-cy="bookmark-delete"
                                            >
                                            mdi-close
                                        </v-icon> 
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <!------ draft -------->

            <v-expansion-panels 
                v-if="user && user_transcriptions.length"
                flat class="pa-1">

                <v-expansion-panel  >
                    <v-expansion-panel-header 
                        style="min-height: 25px !important; max-height: 25px !important; padding-right:12px;"
                        class="text-subtitle-2 font-weight-bold"
                        data-cy="usr-tr-expand"
                        >
                        <LocText text_id="sidebar.draft_transcriptions" /> 
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="d-flex justify-start align-centre px-4 py-1"
                        v-for="item in user_transcriptions" 
                        :key="item.url" >
                            <v-icon class="pr-4"> mdi-file-document-edit-outline </v-icon> 
                            <v-btn 
                                small depressed text
                                @click="on_edit_user_transcription(item)" 
                                color="text"
                                data-cy="usr-tr-open"> 
                                {{ item.document_name }} 
                            </v-btn>
                            <v-spacer />
                            <v-icon small  @click="remove_transcription(item.url)"  data-cy="usr-tr-delete" >mdi-close</v-icon> 
                        </div>    
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <!------ superpowers -------->

            <v-expansion-panels flat class="pa-1">
                <v-expansion-panel v-if="user && user.is_staff">
                    <v-expansion-panel-header 
                        style="min-height: 25px !important; max-height: 25px !important; padding-right:12px;"
                        class="text-subtitle-2 font-weight-bold"
                        >
                        <LocText text_id="sidebar.superpowers" /> 
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <!-- <v-checkbox v-model="lang_edit" label="edit page text" class="pl-4" ></v-checkbox> -->

                        <v-list dense>
                            <v-list-item @click="set_lang_edit(!lang_edit)">
                                <v-list-item-icon> 
                                    <v-icon v-if="!lang_edit">{{ 'mdi-checkbox-blank-outline'  }}</v-icon> 
                                    <v-icon v-if=" lang_edit">{{ 'mdi-checkbox-marked-outline' }}</v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="edit-switch" >
                                        Edit page text
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>


                            <v-list-item>
                                <v-list-item-icon> 
                                    <v-icon > mdi-folder </v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title >
                                        <router-link  class="text-decoration-none router-link" to="/BV1">
                                            Archive V1
                                        </router-link>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon> 
                                    <v-icon > mdi-folder </v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title >
                                        <router-link  class="text-decoration-none router-link" to="/BV2">
                                            Archive V2
                                        </router-link>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon> 
                                    <v-icon > mdi-folder </v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title >
                                        <router-link  class="text-decoration-none router-link" to="/BV3">
                                            Archive V3
                                        </router-link>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>





                            <v-list-item >
                                <v-list-item-icon> 
                                    <v-icon>{{ 'mdi-calendar-search' }}</v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                            <LocText text_id="monitor.title" link="/monitor" />
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item >
                                <v-list-item-icon> 
                                    <v-icon>{{ 'mdi-account-network' }}</v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                            <LocText text_id="online_users.link" link="/online_users" />
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
<!-- 
                            <v-list-item >
                                <v-list-item-icon> 
                                    <v-icon>{{ 'mdi-test-tube' }}</v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                            <LocText text_id="test_page.link" link="/test_page" />
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item >
                                <v-list-item-icon> 
                                    <v-icon>{{ 'mdi-help-box' }}</v-icon> 
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <router-link 
                                            class="text-decoration-none router-link"
                                            to="/Guide"
                                            data-cy="Guide"
                                            >
                                            Guide (unfinished)
                                        </router-link>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
-->
                        </v-list>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </v-list>

        <TranscriptionEditDlg 
            :show="show_transcription_dlg" 
            :transcription="user_transcription" 
            @done="on_edit_user_transcription_done" 
        />
    </v-sheet>
</template>

<script>
import LocText              from "../components/LocText"
import TranscriptionEditDlg from "../components/TranscriptionEditDlg"
import { useAppStore }      from '../stores/appStore'
import { mapState    }      from 'pinia'
import { mapActions  }      from 'pinia'

export default {
    name: "Sidebar",
    components: { LocText, TranscriptionEditDlg },    
    data: () => ({

        show_transcription_dlg : false,
        user_transcription : null,

        sidebar_clipped: true,
        sidebar_items: [
            { title: 'sidebar.home',         icon: 'mdi-home',                  to:"/"},
            { title: 'sidebar.about',        icon: 'mdi-information',           to:"/about"},
            { title: 'sidebar.guide',        icon: 'mdi-help-box',              to:"/guide"},
            { title: 'sidebar.guideline',    icon: 'mdi-file',                  to:"/guideline"},
            { title: 'sidebar.archive',      icon: 'mdi-folder',                to:"/B"},
            //{ title: 'sidebar.archive1',     icon: 'mdi-folder',                to:"/BV1"},
            //{ title: 'sidebar.archive2',     icon: 'mdi-folder',                to:"/BV2"},
            //{ title: 'sidebar.archive3',     icon: 'mdi-folder',                to:"/BV3"},
            { title: 'sidebar.persons',      icon: 'mdi-account-multiple',      to:"/person"},
            { title: 'sidebar.places',       icon: 'mdi-map-marker-multiple',   to:"/place"},
            // { title: 'sidebar.copybooks',    icon: 'mdi-folder',                to:"/C"},
            // { title: 'sidebar.timebar',      icon: 'mdi-chart-gantt',           to:"/timebar"},
            // { title: 'sidebar.timeline',     icon: 'mdi-chart-gantt',           to:"/timeline"},
            // { title: 'sidebar.word_cloud',   icon: 'mdi-file-word-box',         to:"/words"},
        ],
        sidebar_user_items: [
            { title: 'sidebar.chat',        icon: 'mdi-chat',              to:"/chat"},
            { title: 'sidebar.my_account',  icon: 'mdi-account',           to:"/user_settings"},
            { title: 'sidebar.my_documents',icon: 'mdi-text-box-outline',  to:"/user_document"},
            { title: 'sidebar.history',     icon: 'mdi-history',           to:"/history"},
        ],
    }),
    computed : {
        ...mapState(   useAppStore, ['user', 'bookmarks', 'user_transcriptions', 'total_unread_messages', 'lang_edit', 'theme_editor'] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'remove_bookmark',
            'remove_transcription',
            'set_lang_edit',
            'get_doc_details',
            'show_theme_editor'
        ]),
        on_edit_user_transcription( item ) {
            //console.log('on_edit_user_transcriptions', item )
            
            if( typeof item.document === 'string' ) {
                // retrieve doc_details, as expected by TranscriptionEdit
                //console.log('get_doc_details', item.document )
                this.get_doc_details( item.document, (json)=>{

                    this.user_transcription = item
                    this.user_transcription.document = json
                    this.show_transcription_dlg = false
                    this.show_transcription_dlg = true
                })
            } 
            else 
            {
                // doc_details already retrieved
                this.user_transcription = item
                this.show_transcription_dlg = false
                this.show_transcription_dlg = true
            }
        },
        on_edit_user_transcription_done( exit_mode ) {
            console.log('Sidebar on_edit_done', exit_mode, this.user_transcription.document.name, this.$router.path )
            this.show_transcription_dlg = false

            if( exit_mode == 'submit') {
                const path = '/'+this.user_transcription.document.name
                this.$router.push('/B') // force copybook reload
                this.$router.push(path)
            }
        }
    },
};
</script>

<style scoped>

/* https://stackoverflow.com/questions/61541258/change-padding-of-vuetify-v-expansion-panel-content */
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
/* gli stili dei link si settano da LocText -- eccetto che per i bookmark*/
.router-link {
    color: var(--v-text-base) !important; 
}
.router-link-exact-active {
    color: var(--v-active-base); 
}
.edit-switch{
    color: var(--v-text-base); 
}

</style>

