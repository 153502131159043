<template>
    <v-container fluid data-cy="view-user-page"> 
        <div class="d-flex justify:center align-center  py-1" > 
            <div class="pagetitle text-h6">
                <LocText text_id="user.title" /> 
            </div>
            <v-spacer />
            <v-btn small depressed color="primary" class="mx-2" @click="show_user_settings_dlg=true" >
                edit
            </v-btn>  
            <v-btn small depressed color="primary" class="mx-2" @click="show_change_password_dlg=true" >
                change password
            </v-btn>  
        </div>


        <div class="text-body-1 pa-4">
            <div v-for="f in fields" :key="f.id" >

                    <div class="d-flex justify:center align-center py-1" >

                        <div class="label">
                            <LocText :text_id="f.name"/>
                        </div>

                        {{ f.value }} 

                    </div>
                    <v-divider />
            </div>
        </div>

        <div class="pt-3 text-h6"> my contributions: </div>
        <div> {{ user_contributes.length }} transcriptions </div>

        <div class="pa-4 d-flex justify-start flex-wrap">
            <v-chip label 
                v-for="t in user_contributes" 
                :key="t.name"
                @click="contribute_clicked(t.document_name)"
                >
            {{t.document_name}}
            </v-chip>
        </div>



        <UserPasswordChange 
            :show="show_change_password_dlg"
            @done="show_change_password_dlg=false" 
        />
        
        <UserSettings
            :show="show_user_settings_dlg"
            @done="done_user_settings_dlg()" 
        />

    </v-container>    
</template>

<script>
//import Cookies   from 'js-cookie'
import LocText              from "../components/LocText"
import UserPasswordChange   from "../components/UserPasswordChange"
import UserSettings         from "../components/UserSettings"
import { mapState    }      from 'pinia'
import { mapActions  }      from 'pinia'
import { useAppStore }      from '../stores/appStore'

export default { 
    name: "UserPage",    
    components: { LocText, UserPasswordChange, UserSettings },
    data() {
        return {
            fields : [],
            show_change_password_dlg:false,
            show_user_settings_dlg  :false,
    
            table_headers: [    
                { text: 'name',  value: 'name'  },
                { text: 'doc',   value: 'name'  },
            ]
        }
    },
    created() {
        this.update_fields()
    },
    computed : {
        ...mapState( useAppStore, ['user', 'user_contributes'] ),
    },
    methods : {
        ...mapActions( useAppStore, [ 'retrieve_user' ] ),    

        update_fields() {

            this.fields = []
            this.retrieve_user( ()=>{
                if( this.user ) {
                    this.fields = [
                        {'id':0, 'name':'user.initials',       'value':this.user.profile.initials          },
                        {'id':1, 'name':'user.role',           'value':this.user.profile.title             },
                        {'id':2, 'name':'user.first_name',     'value':this.user.first_name                },
                        {'id':3, 'name':'user.last_name',      'value':this.user.last_name                 },
                        {'id':4, 'name':'user.email',          'value':this.user.email                     },
                        {'id':5, 'name':'user.username',       'value':this.user.username                  },
                        {'id':6, 'name':'user.organization',   'value':this.user.profile.organization      },
                        {'id':7, 'name':'user.city',           'value':this.user.profile.city              },
                        {'id':8, 'name':'user.country',        'value':this.user.profile.country           },
                    ]
                }
            })
        },
        done_user_settings_dlg() {
            this.show_user_settings_dlg = false
            this.update_fields()
        },
        contribute_clicked( name ) {
            //console.log('contribute_clicked', name )
            this.$router.push( name )
        }
    },
}
</script>
<style scoped>
.label { min-width:150px;}
.v-chip { 
    margin:2px;
    min-width:105px;
}
</style>



