<template>
    <v-container fluid data-cy="view-place"> 

        <!--- header ---->    
        <div class="d-flex justify-start flex-wrap">

            <div v-if="view_mode==0" class="text-h6 pagetitle pt-1" > <LocText text_id="place_page.places" /> </div>

            <div v-if="view_mode==1" class="text-h6 pagetitle pt-1" >
                <router-link class="text-decoration-none router-link cy-places"  to="/place/" > 
                    &nbsp; <LocText text_id="place_page.places" /> 
                </router-link>
                / {{ place.name}}
            </div> 

            <v-spacer/>

            <div v-if="user" class="pl-6">
                <v-btn 
                    color="primary" dark small depressed 
                    @click="show_create_dialog=true" 
                    data-cy="place-add"
                    > 
                    <LocText text_id="place_page.new" />  
                </v-btn>
            </div>
            <div v-if="user && view_mode==1" class="pl-6">
                <v-btn 
                    color="primary" dark small depressed 
                    @click="show_edit_dialog=true" 
                    data-cy="place-edit"
                    > 
                    <LocText text_id="place_page.edit" /> 
                </v-btn>
            </div>
            <div v-if="user && view_mode==1" class="pl-6">
                <v-btn 
                    color="error" dark small depressed 
                    @click="ask_delete_confirmation=true"
                    data-cy="place-delete"
                    > 
                    <LocText text_id="place_page.delete" /> 
                </v-btn>
            </div>

            <!-- view mode 
            <v-btn-toggle v-model="view_mode" class="pl-6">
                <v-btn small ><v-icon >mdi-format-list-bulleted</v-icon></v-btn>
                <v-btn small ><v-icon >mdi-account-box</v-icon></v-btn>
            </v-btn-toggle>
            -->

            <!-- next / prev page  -->
            <div v-if="view_mode==1" class="pl-6">
                <v-btn-toggle>
                    <v-btn small 
                        data-cy="place-prev"  
                        @click="prev_place"> 
                            <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn small 
                        data-cy="place-num" > 
                        {{place.id}}
                    </v-btn>
                    <v-btn small 
                        data-cy="place-next"  
                        @click="next_place">
                            <v-icon >mdi-chevron-right</v-icon>
                    </v-btn>
                </v-btn-toggle>
                <v-spacer/>
            </div>


        </div>
                
        <!-- view as table --->
        <div v-if="view_mode==0" class="pt-3">
            <v-data-table
                mobile-breakpoint="100"
                :headers="table_headers"
                :items="place_list"
                :items-per-page="200"
                dense
                @click:row="place_clicked">

                    <template v-slot:header.name="      { header }" > <LocText :text_id="header.text" /> </template>
                    <template v-slot:header.long_name=" { header }" > <LocText :text_id="header.text" /> </template>
                    <template v-slot:header.note="      { header }" > <LocText :text_id="header.text" /> </template>

            </v-data-table>
        </div>

        <!-- view details --->
        <div v-if="view_mode==1" class="pt-3 pl-5">
            <div class="d-flex py-2"> <div class="place_lab"><LocText text_id="place_page.name"      />: </div> {{place.name}}      </div>
            <v-divider />
            <div class="d-flex py-2"> <div class="place_lab"><LocText text_id="place_page.long_name" />: </div> {{place.long_name}} </div>
            <v-divider />
            <div class="d-flex py-2"> <div class="place_lab"><LocText text_id="place_page.note"      />: </div> {{place.note}}      </div>
            <v-divider />
        </div>

        <PlaceAdd 
            :show="show_create_dialog"  
            :place_list="place_list" 
            @done="show_create_dialog=false" 
            @created="on_created"
        />

        <PlaceEdit
            :show="show_edit_dialog"  
            :place="place" 
            @done="show_edit_dialog=false" 
        />

        <Confirm
            :show="ask_delete_confirmation"  
            title="place_delete.confirm_title"
            text="place_delete.confirm_text"
            @yes="on_delete_place()" 
            @no="ask_delete_confirmation=false" 
        />

    </v-container>    
</template>

<script>
import Confirm    from "../components/Confirm"
import PlaceAdd  from "../components/PlaceAdd"
import PlaceEdit from "../components/PlaceEdit"
import LocText    from "../components/LocText"

import { mapState    }   from 'pinia'
import { mapActions  }   from 'pinia'
import { useAppStore }   from '../stores/appStore'

export default { 
    name: "Place",    
    components: { PlaceAdd, PlaceEdit, LocText, Confirm },
    data() {
        return {
            view_mode               : 0, // 0=table, 1=single place
            place                  : null,
            place_idx              : 0,
            show_create_dialog      : false,
            show_edit_dialog        : false,
            ask_delete_confirmation : false,

            table_headers: [    
                 { text: 'place_page.name',       value: 'name'  },
                 { text: 'place_page.long_name',  value: 'long_name' },
                 { text: 'place_page.note',       value: 'note' },
            ],

        }
    },
    mounted() {
        this.on_load()
    },
    watch: {
        $route() {
            this.on_load()
        },
        place_list() {
            this.on_load()
        }
    },
    computed : {
        ...mapState( useAppStore, ['user', 'place_list'] ),
    },
    methods : {

        ...mapActions( useAppStore, [ 
            'do_delete_place',
        ] ),

        on_load() {

            const params = this.$route.params['query']
            //console.log( 'Place::on_Load - params=', params )

            this.show_create_dialog  = false 
            this.show_edit_dialog = false 

            // retrieve the requested place, if any
            this.place_idx = 0
            this.view_mode = 0
            if( params != "" )
            {
                const idx = this.place_list.findIndex( item => item.id == params )
                if( idx != -1 )
                {
                    this.place_idx = idx
                    this.view_mode = 1
                }
            }
            this.place = this.place_list[this.place_idx]
        },
        next_place() {
            const i = this.place_idx
            const j = ( i<this.place_list.length-1 ) ? this.place_idx+1 : 0;
            const path = "/place/"+this.place_list[j].id
            this.$router.replace( path )
        },
        prev_place() {
            const i = this.place_idx
            const j = ( i>0 ) ? this.place_idx-1 : this.place_list.length-1;
            const path = "/place/"+this.place_list[j].id
            this.$router.replace( path )
        },
        place_clicked( place ) {
            this.$router.replace( "/place/"+place.id )
        },
        on_delete_place() {
            this.ask_delete_confirmation=false
            this.do_delete_place( this.place, ()=>this.on_load() )
        },
        on_created( json ) {
            if( json && json.id )
                this.$router.replace( "/place/"+json.id )
        }
    },
}
</script>
<style scoped>

.place_lab {
    min-width:140px;
    z--text-align: end;
    padding-right:10px;
}
</style>



