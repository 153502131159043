<template>
    <!---- CREATE DLG ----->
    <span>

        <span v-if="link" >
            <router-link  class="text-decoration-none router-link" :to="link">
                <span :data-cy="datacy"> {{ $t( text_id) }} </span>
            </router-link>
        </span>

        <div v-if="markdown" class="markdown">
            <vue-markdown  :data-cy="datacy" :watches="['source']" :source="$t(text_id)" >
            </vue-markdown>
        </div>

        <span v-if="!link && !markdown" :data-cy="datacy" >
            {{ $t( text_id) }}
        </span>

        <v-icon v-if="lang_edit" @click.stop="on_show_dialog" small color="red"> mdi-pen </v-icon>

        <v-dialog width="800" v-model="show_dialog" >
            <v-card>
                <v-card-title class="text-h5">
                    Edit: {{ text_id }}
                </v-card-title>
                <v-card-text>
                     <v-form >

                        <v-textarea   
                            filled
                            dense
                            hide-details
                            no-resize
                            rows="6"
                            row-height="15"
                            label="english"      
                            z-background-color = "#f1f3f4"
                            v-model="text_eng"  
                            class="pb-3"
                        ></v-textarea>

                        <v-textarea   
                            filled
                            dense
                            hide-details
                            no-resize
                            rows="6"
                            row-height="15"
                            label="italiano"      
                            z-background-color = "#f1f3f4"
                            v-model="text_ita"  
                        ></v-textarea>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text @click="on_submit" > submit </v-btn>
                    <v-btn color="primary" text @click="on_cancel" > cancel </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
    import { mapState    } from 'pinia'
    import { mapActions  } from 'pinia'
    import { useAppStore } from '../stores/appStore'
    import VueMarkdown from 'vue-markdown'

export default { 

    name: "LocText",    
    components : { VueMarkdown },
    props : { 
        text_id:String, 
        link:       { type:String, default:null }, 
        markdown:   { type:String, default:null }, 
        datacy:     { type:String, default:null } 
    },
    data() {
        return {
            show_dialog : false,
            text_ita : "",
            text_eng : "",
        }
    },
    created() {
    },
    computed : {
        ...mapState( useAppStore, [ 'user', 'lang_current','lang_eng', 'lang_ita', 'lang_edit'] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 'set_site_text' ]),

        on_show_dialog() {
            const [section,name] = this.text_id.split(".")
            this.text_eng = this.lang_eng[ section ][ name ]
            this.text_ita = this.lang_ita[ section ][ name ]
            this.show_dialog = true
        },
        on_submit() {
            //console.log('on_submit')
            this.set_site_text( this.text_id, this.text_ita, this.text_eng)
            this.show_dialog=false
        },
        on_cancel() {
            this.show_dialog=false
        }
    },
}
</script>
<style scoped>
.router-link.router-link-exact-active {
  color: var(--v-active-base) !important; 
}
.router-link {
  color: var(--v-text-base) !important; 
}
</style>

<style >  /* va messo non scoped */
    .z---markdown strong { color:var(--v-title-base) !important; }
</style>
    

