<template>
    <div>
        <v-container data-cy="view-buste">
            <div class="d-flex justify-start flex-wrap">
                <div class="text-h6 pagetitle" ><LocText text_id="buste.title"/></div> 
            </div>

            <div v-if="series">
            <!----SERIE 1----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie1.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie1.text }} </div>
                </div>   
                <v-expansion-panels>         
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-body pr-3" > {{ series.serie1.subtitle }} </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex justify-start flex-wrap">
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie1_headers"
                                    :items="series.serie1.buste"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_busta">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box1"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!----SERIE 2----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie2.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie2.text }} </div>
                </div>            
                <v-expansion-panels>         
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-body pr-3" > {{ series.serie2.subtitle }} </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex justify-start flex-wrap">
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie2_headers"
                                    :items="series.serie2.buste.slice(0,10)"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_busta">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box2"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie2_headers"
                                    :items="series.serie2.buste.slice(10,20)"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_busta">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box2"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie2_headers"
                                    :items="series.serie2.buste.slice(20,30)"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_busta">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box2"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>


                <!----SERIE 3----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie3.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie3.text }} </div>
                </div>            
                <v-expansion-panels>         
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-body pr-3" > {{ series.serie3.subtitle }} </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex justify-start flex-wrap">
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie3_headers"
                                    :items="series.serie3.copybooks"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_copybook">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box3"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
    

                <!----SERIE 4----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie4.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie4.text }} </div>
                </div>            
                <v-expansion-panels>         
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-body pr-3" > {{ series.serie4.subtitle }} </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex justify-start flex-wrap">
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie4_headers"
                                    :items="series.serie4.copybooks.slice(0,18)"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_copybook">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box3"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie4_headers"
                                    :items="series.serie4.copybooks.slice(18,36)"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_copybook">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box3"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie4_headers"
                                    :items="series.serie4.copybooks.slice(36,54)"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_copybook">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box3"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>


                <!----SERIE 5----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie5.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie5.text }} </div>
                </div>            
                <v-expansion-panels>         
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-body pr-3" > {{ series.serie5.subtitle }} </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex justify-start flex-wrap">
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie5_headers"
                                    :items="series.serie5.buste"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_busta">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box1"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!----SERIE 6----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie6.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie6.text }} </div>
                </div>            
                <v-expansion-panels>         
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-body pr-3" > {{ series.serie6.subtitle }} </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex justify-start flex-wrap">
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie6_headers"
                                    :items="series.serie6.buste"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_busta">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box2"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!----SERIE 7----------------------->
                <div class="mt-6" >
                    <div class="text-h6 pr-3" >   {{ series.serie7.title }} </div>
                    <div class="text-body pr-3" > {{ series.serie7.text }} </div>
                </div>            
                <v-expansion-panels>         
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-body pr-3" > {{ series.serie7.subtitle }} </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex justify-start flex-wrap">
                                <v-data-table
                                    mobile-breakpoint="100"
                                    :headers="serie7_headers"
                                    :items="series.serie7.buste"
                                    :items-per-page="200"
                                    hide-default-footer
                                    @click:row="open_busta">
                                    <template v-slot:item.name="{ item }">
                                        <v-chip label dark color="box1"> {{ item.name }}</v-chip>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-container>
    </div>
    </template>
    
    <script>
    import LocText          from "../components/LocText"
    import sortBy           from 'lodash/sortBy';
    import { mapState }     from 'pinia'
    import { useAppStore }  from '../stores/appStore'
    
    export default { 
        name: "BusteV3",  
        components: { LocText },    
        data() {
            return {
                sort_by: "name",   

                buste_with_letters_color : '#64b5f6',
                show_color_editor : false,
    
                fg1 : "#000",
                fg2 : "#000",
                fg3 : "#000",
                bg1 : "#64b5f6",
                bg2 : "#f664de",
                bg3 : "#ffb74d",

                serie1_headers : [
                    { text: 'busta',       value: 'name'  },
                    { text: 'from',        value: 'note' },
                    { text: 'year',        value: 'years' },
                    { text: 'num lett.',   value: 'nd1' },
                    { text: 'num tr.',     value: 'nd2' }
                ],
                serie2_headers : [
                    { text: 'busta',       value: 'name'  },
                    { text: 'year',        value: 'years' },
                    { text: 'n.lett.',   value: 'nd1' },
                    { text: 'n.tr.',     value: 'nd2' }
                ],
                serie3_headers : [
                    { text: 'libro',       value: 'name' },
                    { text: 'busta',       value: 'b'  },
                    { text: 'year',        value: 'years' },
                    { text: 'num lett.',   value: 'num_doc' },
                    { text: 'num tr.',     value: 'num_doc_wt' }
                ],
                serie4_headers : [
                    { text: 'libro',       value: 'name' },
                    { text: 'busta',       value: 'b'  },
                    { text: 'num lett.',   value: 'num_doc' },
                    { text: 'num tr.',     value: 'num_doc_wt' }
                ],
                serie5_headers : [
                    { text: 'busta',       value: 'name' },
                    { text: 'from',        value: 'note' },
                    { text: 'num lett.',   value: 'nd1' },
                    { text: 'num tr.',     value: 'nd2' }
                ],
                serie6_headers : [
                    { text: 'busta',       value: 'name' },
                    { text: 'year',        value: 'years' },
                    { text: 'num lett.',   value: 'nd1' },
                    { text: 'num tr.',     value: 'nd2' }
                ],
                serie7_headers : [
                    { text: 'busta',       value: 'name' },
                    { text: 'from',        value: 'note' },
                    { text: 'num lett.',   value: 'nd1' },
                    { text: 'num tr.',     value: 'nd2' }
                ]
            }
        },
        computed : {
            ...mapState( useAppStore, ['buste', 'lang_current', 'series' ] ),

            buste_sorted : function() {
                //return sortBy( this.buste, this.sort_by ) // non funziona
                if( this.sort_by == "name" )                // funziona
                    return sortBy( this.buste, "name" )
                else
                    return sortBy( this.buste, "date_from" )
            },
            colors : function() {
                return [
                    { i:0, lab:"Box A text",         val:this.fg1 },
                    { i:1, lab:"Box A background",   val:this.bg1 },
                    { i:2, lab:"Box B text",         val:this.fg2 },
                    { i:3, lab:"Box B background",   val:this.bg2 },
                    { i:4, lab:"Box C text",         val:this.fg3 },
                    { i:5, lab:"Box C background",   val:this.bg3 },
                ]
            },
        },
        methods: {
            open_busta( item ) {
                this.$router.push( '/B'+item.name )
            },
            open_copybook( item ) {
                this.$router.push( '/C'+item.name )
            },
            on_color( i, c ) {
                switch( i ) {
                case 0: this.fg1 = c; break; 
                case 1: this.bg1 = c; break; 
                case 2: this.fg2 = c; break; 
                case 3: this.bg2 = c; break; 
                case 4: this.fg3 = c; break; 
                case 5: this.bg3 = c; break; 
                }
            }
        }
    }
    </script>
    
    <style scoped>
    .buste_with_letters {
        font-size:16px;
        font-weight:700;
        text-align:center;
    }
    .copybook_nd {
        text-align:center;
    }
    .busta_wip {
        border-radius:5px;
    }
    .letters_nd {
        text-align: center;
        border-radius: 5px;
    }
    .serie1 {
        min-width:100px;
    }
    </style>
    