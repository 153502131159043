import Vue          from 'vue'
import VueRouter    from 'vue-router'
import Home         from '../views/Home.vue'
import About        from '../views/About.vue'
import Buste        from '../views/Buste.vue'
import BusteV1      from '../views/BusteV1.vue'
import BusteV2      from '../views/BusteV2.vue'
import BusteV3      from '../views/BusteV3.vue'
import Busta        from '../views/Busta.vue'
//import Copybooks    from '../views/Copybooks.vue'
import Copybook     from '../views/Copybook.vue'
import Person       from '../views/Person.vue'
import Place        from '../views/Place.vue'
import Search       from '../views/Search.vue'
import Guideline    from '../views/Guideline.vue'
import UserDocument from '../views/UserDocument.vue'
import UserPage     from '../views/UserPage.vue'
import History      from '../views/History.vue'
import Monitor      from '../views/Monitor.vue'
import Chat         from '../views/Chat.vue'
import OnlineUsers  from '../views/OnlineUsers.vue'
import TestPage     from '../views/TestPage.vue'

import Guide        from '../views/Guide.vue'
import GuideIntro   from '../views/GuideIntro.vue'
import GuideArchive from '../views/GuideArchive.vue'
import GuideSearch  from '../views/GuideSearch.vue'
import GuidePerson  from '../views/GuidePerson.vue'
import GuidePlace   from '../views/GuidePlace.vue'
import GuideViewer  from '../views/GuideViewer.vue'
import GuideTranscription   from '../views/GuideTranscription.vue'
import GuideSignup          from '../views/GuideSignup.vue'
import GuideDocument        from '../views/GuideDocument.vue'
import GuideBookmark        from '../views/GuideBookmark.vue'
import GuideDraft           from '../views/GuideDraft.vue'
import GuideChat            from '../views/GuideChat.vue'
import GuideHistory         from '../views/GuideHistory.vue'
import GuideLogin           from '../views/GuideLogin.vue'
import GuideLostPsw         from '../views/GuideLostPsw.vue'

//import Blank        from '../views/Blank.vue'
//import Words        from '../views/Words.vue'
//import Timebar      from '../views/Timebar.vue'
//import Timeline     from '../views/Timeline.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
//   {
//     path: '/persons',
//     name: 'Person',
//     component: Person
//   },
  {
    path: '/person/:query?',
    name: 'Person',
    component: Person
  },
//   {
//     path: '/place',
//     name: 'Place',
//     component: Place
//   },
  {
    path: '/place/:query?',
    name: 'Place',
    component: Place
  },
//   {
//     path: '/wip',
//     name: 'Blank',
//     component: Blank
//   },
//   {
//     path: '/user_document',
//     name: 'UserPage',
//     component: UserDocument
//   },
  {
    path: '/user_document/:query?',
    name: 'UserDocument',
    component: UserDocument
  },
  {
    path: '/search/:query',
    name: 'Search',
    component: Search
  },

//   {
//     path: '/words',
//     name: 'Words',
//     component: Words
//   },
//   {
//     path: '/timebar',
//     name: 'Timebar',
//     component: Timebar
//   },
//   {
//     path: '/timeline',
//     name: 'Timeline',
//     component: Timeline
//   },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/guideline',
    name: 'Guideline',
    component: Guideline
  },
  {
    path: '/user_settings',
    name: 'UserSettings',
    component: UserPage
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },  
  {
    path: '/monitor',
    name: 'Monitor',
    component: Monitor
  },
  {
    path: '/online_users',
    name: 'OnlineUsers',
    component: OnlineUsers
  },
  {
    path: '/test_page',
    name: 'TestPage',
    component: TestPage
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/B',
    name: 'Buste',
    component: Buste
  },
  {
    path: '/BV1',
    name: 'BusteV1',
    component: BusteV1
  },
  {
    path: '/BV2',
    name: 'BusteV2',
    component: BusteV2
  },
  {
    path: '/BV3',
    name: 'BusteV3',
    component: BusteV3
  },
  {
    path: '/B:query',
    name: 'Busta',
    component: Busta
  },
//   {
//     path: '/C',
//     name: 'Copybooks',
//     component: Copybooks
//   },
  {
    path: '/C:query',
    name: 'Copybook',
    component: Copybook
  },  
  {
    path: '/guide',
    name: 'Guide',
    component: Guide
  },  
  {
    path: '/guide/intro',
    name: 'GuideIntro',
    component: GuideIntro
  },  
  {
    path: '/guide/archive',
    name: 'GuideArchive',
    component: GuideArchive
  },  
  {
    path: '/guide/search',
    name: 'GuideSearch',
    component: GuideSearch
  },  
  {
    path: '/guide/person',
    name: 'GuidePerson',
    component: GuidePerson
  },  
  {
    path: '/guide/place',
    name: 'GuidePlace',
    component: GuidePlace
  },  
  {
    path: '/guide/viewer',
    name: 'GuideViewer',
    component: GuideViewer
  },  
  {
    path: '/guide/transcription',
    name: 'GuideTranscription',
    component: GuideTranscription
  },  
  {
    path: '/guide/signup',
    name: 'GuideSignup',
    component: GuideSignup
  },  
  {
    path: '/guide/document',
    name: 'GuideDocument',
    component: GuideDocument
  },  
  {
    path: '/guide/bookmark',
    name: 'GuideBookmark',
    component: GuideBookmark
  },  
  {
    path: '/guide/draft',
    name: 'GuideDraft',
    component: GuideDraft
  },  
  {
    path: '/guide/chat',
    name: 'GuideChat',
    component: GuideChat
  },  
  {
    path: '/guide/history',
    name: 'GuideHistory',
    component: GuideHistory
  },  
  {
    path: '/guide/login',
    name: 'GuideLogin',
    component: GuideLogin
  },  
  {
    path: '/guide/lost_password',
    name: 'GuideLostPsw',
    component: GuideLostPsw
  },  
      
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,         forse questo serve ....
  base: "",
  routes
})

export default router
