<template>
    <div class="guide_topdiv">


        <!--- header ---->   
        <div class="d-flex justify-start flex-wrap" >
            <div class="text-h6 pagetitle ">
                <router-link class="text-decoration-none router-link cy-guide"  to="/guide/" > 
                    <LocText text_id="g.title" /> 
                </router-link>
                / <LocText text_id="g_signup.title" /> 
            </div>
            <v-spacer/>
            <v-btn small depressed class="mx-1" @click="$router.push( '/guide/transcription')" >  <v-icon left>mdi-arrow-left-box </v-icon> <LocText text_id="g_toc.prev" /> </v-btn>
            <v-btn small depressed class="mx-1" @click="$router.push( '/guide'              )" >  <v-icon left>mdi-arrow-up-box   </v-icon> <LocText text_id="g_toc.toc"  /> </v-btn>
            <v-btn small depressed class="mx-1" @click="$router.push( '/guide/login'        )" >  <v-icon left>mdi-arrow-right-box</v-icon> <LocText text_id="g_toc.next" /> </v-btn>
        </div> 

        <!--- body ---->    
        <div>

            <div class="py-3" > <LocText text_id="g_signup.1" markdown="yes"  /> </div> 
            <v-img :src="require('../assets/g_signup_1.png')" max-width="960" /> 
            <div class="py-3" > <LocText text_id="g_signup.2" markdown="yes"  /> </div> 
            <v-img :src="require('../assets/g_signup_2.png')" max-width="600" /> 
            <div class="py-3" > <LocText text_id="g_signup.3" markdown="yes"  /> </div> 

        </div>

        <div class="d-flex justify-start flex-wrap pt-6" >
            <v-spacer/>
            <v-btn small depressed class="mx-1" @click="$router.push( '/guide/transcription')" >  <v-icon left>mdi-arrow-left-box </v-icon> <LocText text_id="g_toc.prev" /> </v-btn>
            <v-btn small depressed class="mx-1" @click="$router.push( '/guide'              )" >  <v-icon left>mdi-arrow-up-box   </v-icon> <LocText text_id="g_toc.toc"  /> </v-btn>
            <v-btn small depressed class="mx-1" @click="$router.push( '/guide/login'        )" >  <v-icon left>mdi-arrow-right-box</v-icon> <LocText text_id="g_toc.next" /> </v-btn>
        </div> 

    </div>
</template>

<script>
import LocText    from "../components/LocText"
export default { 
    name: "Guidesignup",    
    components: { LocText }
}
</script>
