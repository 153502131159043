/*
//https://www.digitalocean.com/community/tutorials/vuejs-vue-socketio
import socketio from 'socket.io';
import VueSocketIO from 'vue-socket.io';

export const SocketInstance = socketio('http://localhost');

Vue.use(VueSocketIO, SocketInstance)
*/

//const HOST = "http://127.0.0.1"
//const HOST = "http://131.175.204.20"
const HOST = window.location.protocol + '//' + window.location.host
console.log( 'HOST=',HOST)


//https://www.npmjs.com/package/vue-socket.io
import Vue from 'vue'
import SocketIO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'

const options = { path: "/socket.io/" }

const SocketIoInstance = SocketIO( HOST, options )

const VueSocketIOInstance = new VueSocketIO({
    debug: false,
    connection: SocketIoInstance
})

Vue.use( VueSocketIOInstance )

Vue.prototype.$socket = SocketIoInstance
