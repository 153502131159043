<template>
<div>
    <v-container fluid data-cy="view-copybook"> <!-- fluid => not using the column layout -->
        
        <!-- invalid copybook -- show warning -->
        <div v-if="!copybook" >
            <router-link class="text-decoration-none router-link"  to="/B">   <LocText text_id="busta.breadcrumbs_root"       /> </router-link>
            <div class="text-h6 pb-3">Copybook {{cb_name}}</div> 
            <v-alert text type="error"> {{cb_name}} : invalid letterbook id </v-alert>
        </div>

        <!-- valid copybook -->
        <div v-if="copybook">
            <div v-if="pages.length">
                <div id="copybook_header"  class="d-flex justify-start flex-wrap align-center">

                    <div class="breadcrumb text-h6">
                        <router-link class="text-decoration-none router-link"  to="/B"                >   <LocText text_id="busta.breadcrumbs_root"       /> </router-link>
                        <router-link class="text-decoration-none router-link" :to="'/B'+copybook.b"   > / <LocText text_id="busta.breadcrumbs_busta"      /> {{ copybook.b }}</router-link>
                        
                        <span  v-if="view_mode==0"                                                    > / <LocText text_id="busta.breadcrumbs_copyletter" /> {{ cb_name }} </span>                                                  
                        <a     v-if="view_mode!=0"  href="#"
                             class="text-decoration-none"  @click.prevent="reload_copybook"           > / <LocText text_id="busta.breadcrumbs_copyletter" /> {{ cb_name }} </a>

                        <span v-if="browse_mode==0 && view_mode!=2"                                   > / <LocText text_id="busta.breadcrumbs_pages"      /> </span>
                        <span v-if="browse_mode==0 && view_mode==2"                                   > / <LocText text_id="busta.breadcrumbs_page"       /> {{ page_name }} </span>

                        <span v-if="browse_mode==1 && view_mode!=2"                                   > / <LocText text_id="busta.breadcrumbs_docs"       /> </span>
                        <span v-if="browse_mode==1 && view_mode==2"                                   > / <LocText text_id="busta.breadcrumbs_doc"        /> {{ doc.num }} </span>

                        <span v-if="view_mode==2">
                            <v-icon 
                                v-if="bookmark" 
                                class="pb-1" 
                                @click="remove_bookmark($route.path)" 
                                data-cy="bookmark-remove"
                                >
                                mdi-bookmark
                            </v-icon>
                            <v-icon 
                                v-if="!bookmark" 
                                class="pb-1" 
                                @click="add_bookmark($route.path)" 
                                data-cy="bookmark-add"
                                >
                                mdi-bookmark-outline
                            </v-icon>
                        </span>
                    </div> 
                    
                    <v-spacer/>
                    
                    <v-btn-toggle v-if="view_mode==2" class="ml-4">
                        <v-btn 
                            small 
                            @click="on_citation"
                            data-cy="citation-btn">
                                <v-icon> mdi-format-quote-close</v-icon> 
                        </v-btn>
                    </v-btn-toggle>

                    <v-btn-toggle v-model="browse_mode" class="ml-4">
                        <v-btn small data-cy="browse-pages">
                            <LocText text_id="busta.breadcrumbs_pages" />
                        </v-btn>
                        <v-btn small data-cy="browse-docs">
                            <LocText text_id="busta.breadcrumbs_docs"  />
                        </v-btn>
                    </v-btn-toggle>
                    
                    <!--
                    <v-btn-toggle v-model="view_mode" class="pl-4">
                        <v-btn small data-cy="view_mode-icon"  ><v-icon >mdi-view-grid-outline</v-icon></v-btn>
                        <v-btn small data-cy="view_mode-table" ><v-icon >mdi-format-list-bulleted</v-icon></v-btn>
                        <v-btn 
                            v-if="(browse_mode==0 && page_name) || (browse_mode==1 && doc)"
                            small 
                            data-cy="view_mode-doc"   
                            ><v-icon >mdi-image-outline</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                    -->

                    <!-- next / prev page  
                    <div v-if="browse_mode==0 && view_mode==2" class="pl-4">
                            <v-btn-toggle>
                                <v-btn small @click="prev_page"> <v-icon>mdi-chevron-left</v-icon></v-btn>
                                <v-btn small @click="next_page"><v-icon >mdi-chevron-right</v-icon></v-btn>
                            </v-btn-toggle>
                    </div>
                    
                    next / prev doc 
                    <div v-if="browse_mode==1 && view_mode==2" class="pl-6">
                            <v-btn-toggle>
                                <v-btn small @click="prev_doc"> <v-icon>mdi-chevron-left</v-icon></v-btn>
                                <v-btn small @click="next_doc"><v-icon >mdi-chevron-right</v-icon></v-btn>
                            </v-btn-toggle>
                    </div>
                    -->

                    <!-- next / prev page --> 
                    <div v-if="browse_mode==0 && view_mode==2" class="pl-4">
                        <v-btn 
                            small depressed 
                            @click="prev_page"
                            data-cy="prev-page"
                            class="btn-outline"
                            > 
                                <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn 
                            small depressed 
                            @click="next_page"
                            data-cy="next-page"
                            class="btn-outline"
                            >
                                <v-icon >mdi-chevron-right</v-icon>
                            </v-btn>
                    </div>
                    
                    <!-- next / prev doc -->
                    <div v-if="browse_mode==1 && view_mode==2" class="pl-6">
                        <v-btn 
                            small depressed
                            @click="prev_doc"
                            data-cy="prev-doc"
                            class="btn-outline"
                            > 
                                <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn 
                            small depressed
                            @click="next_doc"
                            data-cy="next-doc"
                            class="btn-outline"
                            >
                                <v-icon >mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>



                </div> 
                <!-- end header -->

                <!-- bad_doc/page requests --->
                <v-alert text v-if="bad_doc_req"  type="error" class="mt-3"> {{bad_doc_req }} : invalid letter id </v-alert>
                <v-alert text v-if="bad_page_req" type="error" class="mt-3"> {{bad_page_req}} : invalid page id </v-alert>

                <!-- view page icons -->
                <div v-if="browse_mode==0 && view_mode==0" class="pt-3">
                    <div class="d-flex justify-start flex-wrap">

                        <div
                            v-for="page in pages" 
                            :key="page.fnum"
                            >

                            <v-tooltip bottom color="warning"> 
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    label 
                                    @click="page_clicked(page)"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                {{page.fnum}}
                                </v-chip>
                            </template>
                            <span>{{page.sides}}</span>
                            </v-tooltip>
                        </div>
                        
                    </div>
                </div>

                <!-- view page list -->

                <div v-if="browse_mode==0 && view_mode==1">
                    <v-data-table
                        mobile-breakpoint="100"
                        :headers="pages_headers"
                        :items="pages"
                        :items-per-page="30"
                        dense
                        @click:row="page_clicked">
                    </v-data-table>
                </div>

                <!-- view doc icons -->
                
                <div v-if="browse_mode==1 && view_mode==0" class="pt-3">
                    <div class="d-flex justify-start align-center">
                        <span class="mr-3"> <LocText text_id="doc_legend.title" /> </span> 
                        <v-chip label                > <LocText text_id="doc_legend.got_no_tr"/>       </v-chip>
                        <v-chip label class="chip1"  > <LocText text_id="doc_legend.got_md"   />       </v-chip>
                        <v-chip label class="chip2"  > <LocText text_id="doc_legend.got_tr"   />       </v-chip>
                        <v-chip label class="chip3"  > <LocText text_id="doc_legend.got_final_tr" />   </v-chip>
                        <v-chip label class="chip4"  > <LocText text_id="doc_legend.is_being_edited"/> </v-chip>
                    </div>

                    <br>

                    <div class="d-flex justify-start flex-wrap">
                        <v-chip
                            v-for="doc in docs" 
                            :key="doc.num"
                            label 
                            :z--color="doc.color"
                            :class="doc.class"
                            @click="doc_clicked(doc)">
                                <div>
                                    {{doc.num || doc.name}}
                                </div>
                        </v-chip>
                    </div>
                </div>

                <!-- view doc list -->

                <div v-if="browse_mode==1 && view_mode==1">
                    <v-data-table
                        mobile-breakpoint="100"
                        :headers="docs_headers"
                        :items="docs"
                        :items-per-page="30"
                        dense
                        @click:row="doc_clicked">
                    </v-data-table>
                </div>
            </div>
        </div>
    </v-container>    


    <div v-if="pages && view_mode==2">

        <Splitter 
            :header_size="32+24+64+2" 
            :bottom_min_size='183'
            :hide_bottom = "browse_mode != 1" 
        >
            <template v-slot:top-panel>
                <Dragon
                    viewer_id="CopybookDragon"
                    :sources="viewer_images" 
                    :ov_lab ="viewer_labs"
                    :ov_area="viewer_areas"
                />
            </template>

            <template v-slot:bottom-panel>
                <Transcription
                    v-if="browse_mode == 1"
                    :document="doc_details"
                    :label="doc_info"
                    @z--------expanded="on_tr_expanded"
                    @z--------collapsed="on_tr_collapsed"
                    @done-edit="on_done_edit"
                />
            </template>
        </Splitter>

        <Citation
            :show="show_citation"
            :images="cite_images"
            @done="on_close_citation"
        />        
    </div>
</div>
</template>

<script>
import Dragon          from "../components/Dragon"
import Transcription   from "../components/Transcription"
import LocText         from "../components/LocText"
import Citation        from "../components/Citation"
import Splitter        from "../components/Splitter"
import { mapState    } from 'pinia'
import { mapActions  } from 'pinia'
import { useAppStore } from '../stores/appStore'
import { fetch_json }  from '../api'


export default { 
    name: "Copybook",    
    components: { Dragon, Transcription, LocText, Citation, Splitter },

    data() {
        return {
            cb_name:'',     // the requested copybook name --- as given in the url
            copybook: null, // the requested copybook --- retrieved from search_info
            bad_page_req : null,
            bad_doc_req : null,

            page_idx : 0,   // current page index :  index of an entry in pages
            pages: [],      // idx -> page object -- grouping al the images with the same fnum
            page_name: "",  // current image name
            sides:[],
            side: 0,
            //fnums: [],     // page_idx to folio_number


            pages_headers: [    
                { text: 'page',     value: 'fnum' },
                { text: 'sides',    value: 'sides'  },
            ],

            docs:[],            // doc_list
            doc: null,          // l'elemento scelto in this.docs
            doc_details: null,   // i details di this.doc
            doc_idx:0,
            doc_info: "", // shown on transcription header
            docs_headers: [    
                { text: 'name',             value: 'num' },
                { text: 'transcription',    value: 'have_transcription'  },
            ],

            viewer_images: '',   // current image(s) -- passed to Dragon
            viewer_labs:   [],
            viewer_areas:  [],
            viewer_height: "300px",

            browse_mode:   0,  // 0=pages/folios/carte, 1=docs/lettere
            view_mode:     0,  // 0=icon 1==table 2=image
            bounded_resize: null, 

            show_citation : false,
            cite_images : [],

            enable_split : false,
        }
    },
    mounted () {
        // call .bind as otherwise the `this` will not point to the component
        //this.bounded_resize = this.on_resize.bind(this); 
        //window.addEventListener("resize", this.bounded_resize );

        this.load_copybook()
    },
    destroyed() {
        //window.removeEventListener("resize", this.bounded_resize );
    },    
    watch: {
        $route() {
            this.load_copybook()
        },
        copybooks() { 
            this.load_copybook()  // AppStore::load_buste -> make Copybooks
        },
        browse_mode() {
            this.on_browse_mode_toggled()
        },
        view_mode() {
            this.on_view_mode_toggled()
        },
    },
    computed : {
        bookmark() { return this.get_bookmark( this.$route.path ) },

        ...mapState(   useAppStore, ['user', 'bookmarks', 'copybooks', 'theme_colors'] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'get_bookmark',
            'add_bookmark',
            'remove_bookmark'
        ] ),

        reload_copybook() {
            this.view_mode=0
            this.$router.push( '/C' + this.cb_name )
        },
        on_done_edit(exit_mode) {
            console.log( 'CopyBook, done_edit', exit_mode)
            // reload the whole copybook to update the letters status
            console.log( 'on_done_edit', this.doc.name)
            this.copybook = null // will be reloaded
            //this.$router.push( '/' + this.doc.name )
            this.load_copybook()
        },

        load_copybook() {
            
            //debugger

            // read query params
            const params = this.$route.params['query'].split("_")
            let cb_name = params[0] // numero del copybook --- senza la C davanti 

            let req_page = null
            let req_doc  = null
            this.bad_doc_req = null
            this.bad_page_req = null
            
            //this.doc_details  = null // <<< if editing and user press 'back', hide the editor
            //not needed :  if the user press back, the transcription comp will be destroyed

            if( params.length > 1)
            {
                const p1 = params[1]
                if( p1.startsWith('P')) { req_page = p1.replace("P","") }
                if( p1.startsWith('D')) { req_doc  = parseInt( p1.replace("D","") ) }
                if( p1.startsWith('X'))   req_doc  = p1 
            }
            // console.log('req_doc',req_doc)
            // check if the requested copybook is already loaded

            if( this.copybook && this.cb_name == cb_name )
            {
                this.view_mode      = 0
                if( req_page ) this.load_page( req_page ) // may set view_mode = 1, browse_mode = 0 
                if( req_doc  ) this.load_doc ( req_doc  ) // may set view_mode = 1, browse_mode = 1
                return //<<<<<<<<<<<<<<<<<<<<
            }
            // reset
            this.cb_name        = cb_name
            this.copybook       = null
            this.page_idx       = 0
            this.pages          = []
            this.page_name      = ""
            this.sides          = []        
            this.side           = 0
            this.transcription  = ""
            this.docs           = []
            this.doc            = null
            this.doc_details    = null
            this.doc_idx        = 0
            this.doc_info       = ""
            this.viewer_images  = []
            this.viewer_labs    = []
            this.viewer_areas   = []
            this.browse_mode    = 0
            this.view_mode      = 0



            // check if cb_name is valid
            this.copybook = this.copybooks.find( cb => cb.name == cb_name )
            if( !this.copybook )
            {
                return; //<<<<<<<<<<<<<<<<<<<<
            }

            // retrieve copybook

            fetch_json( "GET", '/api/collection/C' + this.cb_name, {}, "Copybook.load_copybook", (json)=> {    
                
                //debugger
                //console.log( 'docs', json)
                //fill docs
                this.docs = json.document_set
                this.docs.sort( function(a,b) { return a.num-b.num } )

                // this.docs.forEach( d => { d.color = d.transcription ? "blue lighten-4" : "default" })
                // this.docs.forEach( d => { if(d.tr_status & 32 ) d.color = "blue lighten-2" })
                // this.docs.forEach( d => { if(d.final) d.color = "green lighten-2" })
                // this.docs.forEach( d => { if(d.editor) d.color = "orange lighten-2" })


                this.docs.forEach( d => { d.color = d.transcription ?         this.theme_colors.chip1 : "default" })
                this.docs.forEach( d => { if(d.tr_status & 32 )     d.color = this.theme_colors.chip2 })
                this.docs.forEach( d => { if(d.final)               d.color = this.theme_colors.chip3 })
                this.docs.forEach( d => { if(d.editor)              d.color = this.theme_colors.chip4 })

                this.docs.forEach( d => { d.class = d.transcription ?             "chip1" : "" })
                this.docs.forEach( d => { if(d.tr_status & 32 )         d.class = "chip2" })
                this.docs.forEach( d => { if(d.final)                   d.class = "chip3" })
                this.docs.forEach( d => { if(d.editor)                  d.class = "chip4" })


                this.docs.forEach( d => { d.have_transcription = d.transcription ? "x" : " " })

                const db_images = json.image_set

                // transform db_images
                // by grouping together images with the same folio-num                        
                let D = {}
                for( let i=0; i<db_images.length; i++ )
                {
                    const im = db_images[i] 
                    const n = im.fnum
                    if( n in D )
                        D[n].push(im)
                    else
                        D[n] = [im]
                }

                // fill pages
                let fnums = Object.keys(D)
                fnums.sort(function(a, b) {return a - b;});
                this.pages = []
                for( let i=0; i<fnums.length; i++ ) {
                    const o = {}
                    o.fnum   = fnums[i]
                    o.images = D[o.fnum]
                    o.from   = "-"
                    o.to     = "-"
                    o.date   = "-"
                    o.sides  = ""
                    let sides= []
                    for( let j=0; j<o.images.length; j++ ) {
                        const im = o.images[j]
                        sides.push(  ( im.bis ? im.bis+" " :"" ) + ( (im.type && im.type != 'C') ? im.type+" " :"" ) + ( im.side=="R" ? "Recto" : "Verso" ) ) 
                    }
                    o.sides = sides.join(', ')|| 0
                    this.pages.push( o )
                }
                // search the index of the requested page or doc, if any
                this.page_idx = 0
                if( req_page  ) {
                    this.load_page( req_page )
                } else {
                    if( req_doc ) {
                        this.load_doc( req_doc )
                    } else {
                        this.browse_mode = ( this.docs.length ) ? 1 : 0
                    }
                }
            })
        },
        next_page() {
            if( this.side < this.sides.length-1 )
            {
                this.side++
                this.load_page_side()
            }
            else
            {
                const i = this.page_idx;
                const j = ( i<this.pages.length-1 ) ? i+1 : 0
                const page = this.pages[j]
                this.side = 0
                this.$router.push( '/C'+this.cb_name+'_P'+page.fnum )
            }
        },
        prev_page() {
            if( this.side > 0 )
            {
                this.side--
                this.load_page_side()
            }
            else
            {
                const i = this.page_idx;
                const j = ( i>0 ) ? i-1 : this.pages.length-1
                const page = this.pages[j]
                this.side = page.sides.length-1
                this.$router.push( '/C'+this.cb_name+'_P'+page.fnum )
            }
        },
        page_clicked( page ) {
            //console.log( 'page_clicked', page )
            this.$router.push( 'C'+this.cb_name+'_P'+page.fnum )
        },

        load_page( req_page ) {
            //console.log('load_page',req_page)
            this.viewer_areas = []

            this.page_idx = this.pages.findIndex( x => x.fnum == req_page )  
            if( this.page_idx < 0 )
            {
                this.bad_page_req = req_page
                this.page_idx = 0
                this.view_mode = 0
                this.browse_mode = 0
                return
            }
            this.bad_page_req = null
            this.browse_mode = 0
            this.view_mode = 2

            this.page_name = this.pages[this.page_idx].fnum +" "+ this.pages[this.page_idx].bis +" "+ this.pages[this.page_idx].side
            this.sides= this.pages[this.page_idx].sides.split(',')
            this.side = 0
            this.load_page_side();
        },
        load_page_side() {
            if( this.sides.length == 0 ) { this.side = 0; this.page_name="none"; return }
            this.side = Math.max(0, Math.min( this.sides.length-1, this.side ) )

            const im = this.pages[this.page_idx].images[this.side]
            this.cite_images = [ im.filename ]
            this.page_name = [ im.fnum, im.bis, this.sides[this.side]].join(' ')
            this.viewer_images = [{ filename:im.filename }]
            this.viewer_labs =[]

            fetch_json( "GET", im.url, {}, "Copybook.load_page_side", (json)=> {
                   let overlays = []
                    //console.log( 'load_page_side', im.url, json )                        
                    // keep doc_idx in synch
                    let doc_name = ""
                    if( json.imagearea_set.length )
                        doc_name = json.imagearea_set[0].document.name
                    if( json.imagearea_set.length>1 )
                        doc_name = json.imagearea_set[1].document.name
                    const doc_idx = this.docs.findIndex( x => x.name == doc_name )
                    if( doc_idx > -1 )
                        this.doc_idx = doc_idx
                    //console.log( 'load_page_side', doc_name, doc_idx )                        

                   json.imagearea_set.forEach( o => {
                       const id = 'LAB'+overlays.length
                       const text=o.document.num
                       const x = (this.side==0) ? 0.03 : 0.2
                       const y = Math.max( 0.13, o.area_top/700.0 )

                       overlays.push( { element:id, text:text, x:x, y:y } )   
                   })
                   this.viewer_labs = overlays
                   
            })
            this.$nextTick( this.on_resize )
        },
        doc_clicked( doc ) {
            const name = doc.name[0]=='X' ? 'C'+this.cb_name+'_'+doc.name : doc.name
            this.$router.push( name )
        },
        next_doc() {
            const i = this.doc_idx;
            const j = ( i<this.docs.length-1 ) ? i+1 : 0
            const doc = this.docs[j]
            const name = doc.name[0]=='X' ? 'C'+this.cb_name+'_'+doc.name : doc.name
            this.$router.push( name )
        },
        prev_doc() {
            const i = this.doc_idx;
            const j = ( i>0 ) ? i-1 : this.docs.length-1
            const doc = this.docs[j]
            const name = doc.name[0]=='X' ? 'C'+this.cb_name+'_'+doc.name : doc.name
            this.$router.push( name )
        },
        load_doc( req_doc ) {
            //console.log('load_doc',req_doc)
            //debugger
            this.viewer_labs = []

            this.doc_idx = -1
            if( typeof req_doc === 'number'  ) { // inizia con 'D' - ma e' stata tolta
                this.doc_idx = this.docs.findIndex( x => x.num == req_doc )      
            }
            if( typeof req_doc === 'string'  ) { // inizia con 'X'
                if( req_doc.startsWith('X') ) {
                    this.doc_idx = this.docs.findIndex( x => x.name == req_doc )      
                }
            }

            if( this.doc_idx < 0 )
            {
                this.bad_doc_req = req_doc
                this.doc_idx = 0
                this.view_mode = 0
                this.browse_mode = 1
                return
            }            
            this.bad_doc_req = null
            this.browse_mode = 1
            this.view_mode = 2

            const doc = this.docs[this.doc_idx]
            this.doc = doc
            let doc_info = "doc: " + doc.name 

            //console.log('load_doc', doc, doc.url )
            
                
            fetch_json( "GET", doc.url, {}, "CopyBook.load_doc", json => {
                this.doc_details = json // serve a transcription
                //debugger
                let images = []
                let ov_area = []
                
                //console.log('load_doc',json)
                //this.transcription = json.transcription
                // this.doc.transcription e' la url,  this.doc.tr e' l'oggetto trascrizione
                // -- no -- doc.tr e' sempre la url
                //this.doc.tr = json.transcription

                //doc_info += "    tr: " + ( this.doc.tr ? this.doc.tr.name : "none" ) + "    images: "
                doc_info += "    images: "

                // keep page_idx in synch
                if( json.imagearea_set.length ) {
                    const fnum = parseInt( json.imagearea_set[0].image.filename.split('_')[3] )
                    this.page_idx = this.pages.findIndex(  x => x.fnum == fnum ) 
                    //console.log('load_doc -- fnum:',fnum, "page_idx", this.page_idx)
                }
                this.cite_images = []
                json.imagearea_set.forEach( o => {

                    this.cite_images.push( o.image.filename ) 

                    doc_info += o.image.filename.split("/")[1] +"    "
                    
                    let tile = {}
                    tile.filename = o.image.filename
                    tile.x = 2000 * images.length
                    tile.y = 0 
                    tile.width = 2000

                    images.push(  tile  )

                    let ov1 = {}
                    ov1.element = "AREA"+ov_area.length
                    //ov1.text = ov1.element
                    ov1.x= tile.x
                    ov1.y=0
                    ov1.width=2010
                    ov1.height=o.area_top *2830 / 1000
                    ov_area.push(ov1)

                    let ov2 = {}
                    ov2.element = "AREA"+ov_area.length
                    //ov2.text = ov2.element
                    ov2.x= tile.x,
                    ov2.y=o.area_bottom *2830 / 1000 
                    ov2.width=2010
                    ov2.height=2830-ov2.y
                    ov_area.push(ov2)
                }) 
                this.doc_info = doc_info
                this.viewer_images = images
                this.viewer_areas = ov_area
                this.$nextTick( this.on_resize )
            })
        },
        on_view_mode_toggled() {
            if( this.view_mode == 2 ) 
                this.$nextTick( this.on_resize )
        },
        on_browse_mode_toggled() {
            if( this.view_mode != 2 ) 
                return;
            if( this.browse_mode == 0 ) // docs --> pages
                this.load_page(  this.pages[this.page_idx].fnum )
            else                        // page --> docs 
                this.load_doc( this.docs[this.doc_idx].num ) 
            this.$nextTick( this.on_resize )
        },
        transcription_resized() {
            this.$nextTick( this.on_resize )
        },
        on_resize() {
            //console.log( 'on_resize' )
            // const cbh= document.getElementById("copybook_header")
            // const tr = document.getElementById("tr_panel")
            // const h1 = window.innerHeight
            // const h2 = ( cbh ? cbh.clientHeight : 0 )
            // const h3 = 10*2//12 * 2 // padding of the container
            // const h4 = 64 // nav
            // const h5 = (( this.browse_mode==1 && tr ) ? tr.clientHeight : 0 )
            // //console.log( 'on_resize',h1,h2,h3,h4,h5 )
            // this.viewer_height = (h1-h2-h3-h4-h5-2)+"px"
        },
        on_citation() {
            //console.log("on_citation")
            this.show_citation = true
        },
        on_close_citation() {
            this.show_citation = false
        },
        on_tr_expanded() {
            this.enable_split = true
        },
        on_tr_collapsed() {
            this.enable_split = false
        },
    }
}
</script>
<style scoped>
.v-chip { 
    margin:2px;
    min-width:58px;
}
.v-chip .v-chip__content {
    margin: 0 auto !important;  /* non funziona */ 
}
.btn-outline {
    border: 1px solid rgba(0,0,0,.15) !important;
}

.indicator {
    max-width  : 12px;
    min-width  : 12px;
    max-height : 4px;
    min-height : 4px;
}
.chip1 { background: var(--v-chip1-base  ) !important; }
.chip2 { background: var(--v-chip2-base  ) !important; }
.chip3 { background: var(--v-chip3-base  ) !important; }
.chip4 { background: var(--v-chip4-base  ) !important; }

</style>


