<template>
    <v-dialog persistent width="800" v-model="show_internal" >
        <v-card>
            <v-card-title class="text-h5">
                <LocText text_id="citation.title" />
            </v-card-title>
            <v-card-text>

                <div class="d-flex align-start">    
                    <div class="text-body-1">
                        <LocText text_id="citation.link" /><br>
                        <span id="url"> {{url}} </span><br>
                        <br>
                    </div>
                    <v-spacer/>
                    <!-- <v-btn color="primary" text @click="on_copy_url">copy</v-btn>  -->
                </div>


                <div class="d-flex align-start">    
                    <div class="text-body-1" id="collocation">
                        <LocText text_id="citation.collocation" /><br>
                        <span id="collocation">
                            Archivio Gonzaga / 
                            <LocText text_id="citation.folder"/> {{busta}} /
                            <span v-if="copybook">
                                <LocText text_id="citation.copybook"/> {{copybook}} /
                            </span>
                            <LocText :text_id="folio_lab"/>  {{folios}}
                        </span>
                        <br>
                    </div>
                    <v-spacer/>
                    <!-- <v-btn color="primary" text @click="on_copy_collocation">copy</v-btn>  -->
                </div>


            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn 
                    color="primary" text 
                    @click="on_close" 
                    data-cy="citation-close"> 
                        <LocText text_id="general.close" /> 
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import LocText from "../components/LocText"

export default { 
    name: "Citation",    
    props : [ 'show', 'images' ],
    components: { LocText },
    data() {
        return {
            show_internal : false,
            url           : "",
            busta         : "",
            copybook      : "",
            folios        : "",
            folio_lab     : true,
        }
    },
    created() {
        if(this.show) this.on_show()
    },
    watch : {
        show() { 
            if(this.show) this.on_show() 
        }
    },
    methods : {
        parse_filename( name ) {
            const re = /MDP(\d+)\/(\d+)_(\[\d+\]_)?(\w)_(\d+)_(\w+_)?(R|V)/
            const match = name.match(re)
            if( match ) {
                let o = {}
                o.busta     =(match[1] || "")
                o.img_num   =(match[2] || "")
                o.type      =(match[4] || "") // l'underscore deve stare dentro nel gruppo opzionale
                o.copybook  =(match[3] || "").replace('_','').replace(']','').replace('[','') 
                o.num       =(match[5] || "") 
                o.bis       =(match[6] || "").replace('_','')
                o.side      =(match[7] || "")

                if( o.num ) o.num = parseInt(o.num).toString() // remove any 0 in front 

                return o
            }
            return null
        },
        on_show() {
            this.show_internal =true

            this.url = window.location.href
            let pages = {}
            this.images.forEach( item => {
                //console.log('item',item )
                const o = this.parse_filename( item )
                //console.log('o',o)
                this.busta = o.busta
                this.copybook = o.copybook
                let name = (o.bis) ? o.num+' '+ o.bis : o.num
                const side = o.side=='R' ? ' recto' : ' verso' 

                // se sono presenti entrambi i lati, li tolgo    
                if( name in pages ) {
                    pages[name] = "" 
                } else {
                    pages[name] = side
                }
            })
            // pages = folio_num + [folio.bis] => Recto | Verso 
            const names = Object.keys(pages)
            names.sort()
            const values = []
            names.forEach( name => { values.push( name+pages[name] ) })
            this.folio_lab = ( values.length > 1 ) ? "citation.folios" : "citation.folio"
            //console.log( 'values.length', values.length, this.folio_lab )
            this.folios = values.join(', ')
        },        
        on_close() {
            this.show_internal=false
            this.$emit('done') 
        },
        // on_copy_collocation() {
        //     const el = document.getElementById("collocation")
        //     el.select()
        //     navigator.clipboard.writeText(el.value)
        // },
        // on_copy_url() {

        // }
    },
}
</script>
<style scoped>
</style>



