import { render, staticRenderFns } from "./BusteV1.vue?vue&type=template&id=3d2d2a59&scoped=true"
import script from "./BusteV1.vue?vue&type=script&lang=js"
export * from "./BusteV1.vue?vue&type=script&lang=js"
import style0 from "./BusteV1.vue?vue&type=style&index=0&id=3d2d2a59&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d2d2a59",
  null
  
)

export default component.exports