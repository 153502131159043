<template>
    <!-- 
        custom component to host two panel arranged one on top of the other.
        The two components will fill the whole window vertically, leaving a space of 'header-size'.
        The two components must have a height of 100% to fill their parent-div.
    --->   

    <v-container fluid class="pa-0 ma-0" >
    <div class="gray pa-0 ma-0">      

        <div  v-bind:style="{ height:h1+'px', visibility: dragging ? 'hidden' : 'visible' }" >
            <slot name="top-panel"/>
        </div>

        <div class="splitter  d-flex justify-center align-center" ref="splitter" v-bind:style="{ height:hs+'px' }" >  
            <v-icon>mdi-drag-horizontal-variant</v-icon>
            <!-- <slot name="mid-panel"/> -->
        </div>

        <div  v-bind:style="{ height:h2+'px', visibility: dragging ? 'hidden' : 'visible' }" >
            <slot name="bottom-panel"/>
        </div>

    </div>      
    </v-container>    
</template>

<script>

export default { 
    name: "Splitter",
    props : { 
        header_size      : { type: Number,  default:0     },
        enable_split     : { type: Boolean, default:true  },
        top_min_size     : { type: Number,  default:100   },
        bottom_min_size  : { type: Number,  default:312   },
        hide_bottom      : { type: Boolean, default:false },
    }, 
    
    data() {
        return {
            h1 : 100,
            h2 : 378,
            h2_bk : 378,
            hs : 10,
            dragging : false,
            bounded_md : null,
            bounded_mm : null,
            bounded_mu : null,
        }
    },
    mounted () {
        this.h2 = this.bottom_min_size 

        this.on_resize()
        this.on_enable_split()
        this.on_toggle_bottom()

        this.bounded_resize = this.on_resize.bind(this); 
        window.addEventListener("resize", this.bounded_resize );

        this.bounded_md = this.md.bind(this)
        this.bounded_mm = this.mm.bind(this)
        this.bounded_mu = this.mu.bind(this)

        //const splitter = document.getElementById("splitter")
        const splitter = this.$refs.splitter
        splitter.addEventListener( "mousedown", this.bounded_md )
    },
    destroyed() {
        window.removeEventListener("resize", this.bounded_resize );
    },    
    watch : {
        enable_split() { this.on_enable_split()  },
        hide_bottom()  { this.on_toggle_bottom() }
    },
    methods : {
        on_resize() {
            const m = this.header_size + this.hs 
            const h = window.innerHeight - m 
            this.h1 = h - this.h2 > 100 ? h - this.h2 : 100
        },
        md(e) {
            document.body.style.cursor = "ns-resize";
            document.addEventListener("mousemove", this.bounded_mm );
            document.addEventListener("mouseup",   this.bounded_mu );
            e.stopPropagation();
            //console.log("md")
            document.body.style.userSelect = "none"
        },
        mm( e ) {
            //this.dragging = true
            const m = this.header_size + this.hs 
            const h = window.innerHeight - m 
            const ymin = this.top_min_size
            const ymax = h-this.bottom_min_size
            let y = e.clientY - m + this.hs
            if( y < ymin ) y = ymin
            if( y > ymax ) y = ymax
            this.h1 = y
            this.h2 = h - y
            e.stopPropagation();
            //console.log("mm")
        },
        mu(e) {
            //this.dragging = false
            document.removeEventListener("mousemove", this.bounded_mm );
            document.removeEventListener("mouseup",   this.bounded_mu );
            document.body.style.cursor = "";
            e.stopPropagation();
            //console.log("mu")
            document.body.style.userSelect = ""
        },
        on_enable_split() {
            if(!this.enable_split) 
            {
                this.h2_bk = this.h2
                this.hs = 0
                this.h2 = 48
                this.h1 = window.innerHeight - this.header_size - this.h2 
            }
            else
            {
                this.h2 = this.h2_bk
                this.hs = 10
                this.h1 = window.innerHeight -this.header_size -this.hs - this.h2  
            }
        },        
        on_toggle_bottom() {
            //console.log('hide_bottom', this.hide_bottom);
            if( this.hide_bottom) 
            {
                this.h2_bk = this.h2
                this.hs = 0
                this.h2 = 0
                this.h1 = window.innerHeight - this.header_size
            }
            else
            {
                this.h2 = this.h2_bk
                this.hs = 10
                this.h1 = window.innerHeight -this.header_size -this.hs - this.h2  
            }
        }
    },
}
</script>
<style scoped>
.splitter  { 
    cursor:'ns-resize';
    background-color : var(--v-panel-base); 
}
.splitter:hover  { 
    background-color: var(--v-primary-base); 
}
</style>





