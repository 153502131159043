<template>
<div>
    <v-container fluid data-cy="view-busta"> <!-- fluid => not using the column layout -->





        <!-- invalid busta ID -->




        <div v-if="!busta" >
            <div class="text-h6 pb-4" >
                <router-link class="text-decoration-none router-link"  to="/B" data-cy="breadcrumb-root"> 
                    &nbsp; <LocText text_id="busta.breadcrumbs_root" /> 
                </router-link>
                /  <LocText text_id="busta.breadcrumbs_busta" /> {{busta_name}}
            </div> 
            <v-alert text type="error"> {{busta_name}} : invalid box id </v-alert>
        </div>




        <!-- valid busta ID -->



        <div v-if="busta">


            <!-- Busta with Copybook --> 


            <div v-if="copybooks.length">
            
                <div class="text-h6  pt-1">
                    <router-link class="text-decoration-none router-link"  to="/B" data-cy="breadcrumb-root" > 
                        &nbsp; <LocText text_id="busta.breadcrumbs_root" /> 
                    </router-link>
                    / <LocText text_id="busta.breadcrumbs_busta" /> {{busta_name}} 
                    / <LocText text_id="busta.breadcrumbs_copyletters" /> 
                </div> 

                <v-data-table
                    mobile-breakpoint="100"
                    :headers="copybooks_headers"
                    :items="copybooks"
                    :items-per-page="30"
                    dense
                    @click:row="copybook_clicked">
                </v-data-table>
            </div>




            <!-- Busta with Letters --> 


            <div v-else >
                <div v-if="docs && copybooks.length==0">


                    <!-- busta header -->
                    <div id="busta_header" class="d-flex justify-start align-center flex-wrap text-h6">

                        <div class="">
                            <router-link class="text-decoration-none router-link"  to="/B" data-cy="breadcrumb-root"> 
                                &nbsp; <LocText text_id="busta.breadcrumbs_root" />
                            </router-link>
                            / 
                            <span v-if="view_mode==0"><LocText text_id="busta.breadcrumbs_busta" /> {{busta_name}} </span>

                            <a    v-if="view_mode==2"  
                                  href="#"  
                                  class="text-decoration-none"  
                                  @click.prevent="reload_busta" 
                                  data-cy="busta-link"
                                  >
                                                      <LocText text_id="busta.breadcrumbs_busta" /> {{busta_name}} 
                            </a>                           
                            <span v-if="view_mode==2"> 
                                / <LocText text_id="busta.breadcrumbs_doc" /> <span data-cy="doc-num">{{ doc.num }}</span> 
                                
                                <v-icon 
                                    v-if="doc && bookmark" 
                                    class="pb-1" 
                                    @click="remove_bookmark($route.path)" 
                                    data-cy="bookmark-remove"
                                    >
                                    mdi-bookmark
                                </v-icon>
                                <v-icon 
                                    v-if="doc && !bookmark" 
                                    class="pb-1" 
                                    @click="add_bookmark($route.path)" 
                                    data-cy="bookmark-add"
                                    >
                                    mdi-bookmark-outline
                                </v-icon>

                            </span>
                        </div> 
                        <v-spacer/>
                        
                        <!--
                        <v-btn-toggle v-if="view_mode==2" class="pl-4">
                            <v-btn small @click="on_citation"> <v-icon> mdi-format-quote-close</v-icon> </v-btn>
                        </v-btn-toggle>

                        <v-btn-toggle v-if="view_mode==2" class="pl-4">
                            <v-btn small @click="view_mode=0"> <v-icon> mdi-view-grid-outline </v-icon> </v-btn>
                        </v-btn-toggle>
                        -->

                        <!-- view mode --
                        <v-btn-toggle v-model="view_mode" class="pl-4">
                            <v-btn              small data-cy="view-mode-icon"  ><v-icon >mdi-view-grid-outline</v-icon></v-btn>
                            <v-btn              small data-cy="view-mode-list"  ><v-icon >mdi-format-list-bulleted</v-icon></v-btn>
                            <v-btn v-if="doc"   small data-cy="view-mode-letter"><v-icon >mdi-image-outline</v-icon></v-btn>
                        </v-btn-toggle>
                        -->

                        <!-- next / prev page  
                        <div v-if="view_mode==2" >

                            <v-btn-toggle class="pl-4">
                                <v-btn small @click="on_citation"> <v-icon> mdi-format-quote-close</v-icon> </v-btn>
                            </v-btn-toggle>

                            <v-btn-toggle class="pl-4">
                                <v-btn small @click="prev_doc"> <v-icon>mdi-chevron-left</v-icon></v-btn>
                                <v-btn small >{{doc.num}}</v-btn> 
                                <v-btn small @click="next_doc"><v-icon >mdi-chevron-right</v-icon></v-btn>
                            </v-btn-toggle>
                            <v-spacer/>
                        </div>
                        -->    

                        <div v-if="view_mode==2" >
                                <v-btn 
                                    small depressed 
                                    class="btn-outline mx-4" 
                                    @click="on_citation" 
                                    data-cy="citation-btn">
                                        <v-icon> mdi-format-quote-close</v-icon> 
                                </v-btn>
                                <v-btn 
                                    small depressed 
                                    class="btn-outline"      
                                    @click="prev_doc"
                                    data-cy="prev-doc"> 
                                        <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn 
                                    small depressed 
                                    class="btn-outline"      
                                    @click="next_doc"
                                    data-cy="next-doc">
                                        <v-icon >mdi-chevron-right</v-icon>
                                </v-btn>
                        </div>

                    </div>
                    
                    <!-- bad_doc_name --->

                    <v-alert text v-if="bad_doc_name" type="error" class="mt-3"> {{bad_doc_name}} : invalid letter id </v-alert>
                    
                    <!-- view as table --->
                    <div v-if="view_mode==1" class="pt-3">
                        <v-data-table
                            mobile-breakpoint="100"
                            :headers="doc_headers"
                            :items="docs"
                            :items-per-page="30"
                            dense
                            @click:row="doc_clicked">
                        </v-data-table>
                    </div>

                    <!-- view as icons --->


                    <div v-if="view_mode==0" class="pt-4">

                        <div class="d-flex justify-start align-center">
                            <span class="mr-3"> <LocText text_id="doc_legend.title" /> </span> 
                            <v-chip label                         > <LocText text_id="doc_legend.got_no_tr"/>       </v-chip>
                            <v-chip label class="chip1" > <LocText text_id="doc_legend.got_md"   />       </v-chip>
                            <v-chip label class="chip2" > <LocText text_id="doc_legend.got_tr"   />       </v-chip>
                            <v-chip label class="chip3" > <LocText text_id="doc_legend.got_final_tr" />   </v-chip>
                            <v-chip label class="chip4" > <LocText text_id="doc_legend.is_being_edited"/> </v-chip>
                        </div>
                        <br>

                        <div class="d-flex justify-start flex-wrap">
                            <v-chip label 
                                v-for="doc in docs" 
                                :key="doc.name"
                                :class="doc.class"
                                @click="doc_clicked(doc)">
                                    
                                    {{doc.num}}
                            </v-chip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-container>    
    
    <!-- view as icons --  the viewer is outside the container to avoid its padding -->

    <div v-if="docs && view_mode==2">
        <!--
        <Dragon 
            viewer_id="BustaDragon"
            :sources="viewer_images"  
            :height="viewer_height" 
        />
        <Transcription 
            :document="doc"
            @resized="transcription_resized"
        />
        -->

        <Splitter 
            :header_size="32+24+64+2" 
            :z----enable_split='enable_split'
            :bottom_min_size='183'
        >
            <template v-slot:top-panel>
                <Dragon
                    viewer_id="BustaDragon"
                    :sources="viewer_images" 
                />
            </template>

            <template v-slot:bottom-panel>
                <Transcription
                    :document="doc"
                    @resized="transcription_resized"
                    @expanded="on_tr_expanded"
                    @collapsed="on_tr_collapsed"
                />
            </template>
        </Splitter>

        <Citation
            :show="show_citation"
            :images="cite_images"
            @done="on_close_citation"
        />
    </div>
</div>
</template>

<script>
import Dragon          from "../components/Dragon"
import Transcription   from "../components/Transcription"
import LocText         from "../components/LocText"
import Citation        from "../components/Citation"
import Splitter        from "../components/Splitter"

import { mapState, mapActions } from 'pinia'
import { useAppStore  }         from '../stores/appStore'
import { fetch_json }           from '../api'

export default { 
    name: "Busta",    
    components: { Dragon, Transcription, LocText, Citation, Splitter },

    data() {
        return {
            busta_name : "", // the requested busta
            busta: null,     // the current busta --- retrieved from search_info
            bad_doc_name: "",// set after requesting a bad doc name

            docs:      [],   // list of all the docs in this busta
            doc:       null, // current doc
            doc_idx:   0,    // set in load_doc, used in prev/next_doc

            viewer_images:[],
            viewer_height:'300px',
            
            view_mode:0,      // 0=icon 1=table  2=image
            doc_headers: [    
                { text: 'name',           value: 'name' },
                { text: 'transcription',  value: 'transcription' },
            ],
            copybooks : [],
            copybooks_headers: [    
                { text: 'name',     value: 'name' },
                { text: 'pages',    value: 'p[1]' },
                { text: 'documents',value: 'd[1]' },
            ],
            //bounded_resize : null,

            show_citation : false,
            cite_images : [],

            enable_split : false,

        }
    },
    created() {
    },
    mounted () {
        // call .bind as otherwise the `this` will not point to the component
        //this.bounded_resize = this.on_resize.bind(this); 
        ///window.addEventListener("resize", this.bounded_resize );

        this.load_busta()
    },
    destroyed() {
        //window.removeEventListener("resize", this.bounded_resize );
    },    
    watch: {
        $route() {
            this.load_busta()
        },
        buste() {  // al termine di AppStore::load_buste
            this.load_busta()
        },
        view_mode() {
            this.on_view_mode_toggled()

            // if passing in view_mode==2 without a current document, load the first one
            // debugger
            // if( this.view_mode == 2 &&  this.busta.type == "L" &&  !this.viewer.images ) {
            //     this.load_doc(  this.doc.name )
            // }    
        },
    },
    computed : {
        bookmark() { return this.get_bookmark( this.$route.path ) },
        ...mapState(   useAppStore, ['user', 'bookmarks', 'buste'] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 
            'get_bookmark',
            'add_bookmark',
            'remove_bookmark'
        ] ),

        reload_busta() {
            //console.log( 'reload_busta', '/B' + this.busta_name )
            this.view_mode=0
            this.doc = null
            this.$router.push( '/B' + this.busta_name )
        },        

        load_busta() {
            
            const params = this.$route.params['query']
            const busta_name  = params.split('_')[0]                            // es: 1890
            const doc_name    = ( params.split('_').length > 1 ? 'B'+params : "" ) // es: B1890_D1



            if( this.busta && this.busta_name == busta_name ) {
                this.view_mode  = 0
                if( doc_name ) this.load_doc( doc_name) // altrimenti va posticipato
                return;
            }
            //reset data
            this.busta_name = busta_name
            this.busta      = null
            this.doc        = null
            this.docs       = [] 
            this.copybooks  = []
            this.view_mode  = 0

            this.busta = this.buste.find( item => item.name == busta_name )
            if( !this.busta ) 
                return

            if( this.busta.type == "C" ) {
                this.copybooks = []
                this.busta.collection_set.forEach( cb => {
                    this.copybooks.push(cb)
                })
            }
            if( this.busta.type == "L" ) {

                // retrieve busta    
                fetch_json( 'GET',  '/api/buste/B' + this.busta.name, {}, "busta.load_busta", (json)=> {

                    // retrieve collection    

                    const url2 = json.collection_set[0].url
                    fetch_json( 'GET', url2, {}, "busta.load_collection", (json)=> {    

                        // fill docs 
                        this.docs = json.document_set
                        this.docs.sort( function(a,b) { return a.num - b.num } )
                        this.docs.forEach( d => {

                            // d.color = d.transcription ? "blue lighten-4" : "default"
                            // if(d.tr_status & 32 ) d.color = "blue lighten-2"
                            // if(d.final) d.color = "green lighten-2"
                            // if(d.editor) d.color = "orange lighten-2" 

                            d.class = d.transcription ? "chip1" : ""
                            if(d.tr_status & 32 )   d.class = "chip2"
                            if(d.final)             d.class = "chip3"
                            if(d.editor)            d.class = "chip4" 

                            d.have_details = false
                        })

                        // now we are ready to load the doc, if requested
                        if( doc_name ) 
                            this.load_doc( doc_name )
                    })
                })
            }
        },
        load_doc( doc_name ) {
            const index = this.docs.findIndex( d => d.name==doc_name )
            if( index < 0 ) {
                this.bad_doc_name = doc_name
                this.view_mode = 0
                this.doc_idx = 0
                return
            }
            this.bad_doc_name = ""
            if( this.doc && doc_name == this.doc.name ) 
                return

            this.doc = this.docs[index]
            this.doc_idx = index

            if( this.doc.have_details ) {
                this.load_doc_images()
            }
            else
            {
                fetch_json( 'GET', this.doc.url, {}, 'Busta.load_doc', (json)=> {
                    this.doc.imagearea_set = json.imagearea_set
                    this.doc.transcription = json.transcription // dovrebbe essere la url della trascrizione
                    this.doc.tr = null                          // dovrebbe essere l'oggetto trascrizione
                    this.doc.have_details = true
                    this.load_doc_images()
                })
            }
        },
        load_doc_images() {
            let images = []
            if( this.doc.imagearea_set ) {
                this.doc.imagearea_set.forEach( o => {

                    let tile = {}
                    tile.filename = o.image.filename
                    tile.x = 2000 * images.length
                    tile.y = 0
                    tile.width = 2000
                    images.push(  tile  )
                })
            }
            this.viewer_images = images
            this.view_mode = 2
            this.$nextTick( this.on_resize )
        },
        next_doc() {
            const i = this.doc_idx
            const j = ( i<this.docs.length-1 ) ? this.doc_idx+1 : 0;
            const next_name = this.docs[j].name
            this.$router.replace( next_name )
        },
        prev_doc() {
            const i = this.doc_idx
            const j = ( i>0 ) ? this.doc_idx-1 : this.docs.length-1;
            const next_name = this.docs[j].name
            this.$router.replace( next_name )
        },
        doc_clicked( doc ) {
            this.$router.push( doc.name )
        },
        copybook_clicked( copybook ) {
            this.$router.push( '/C'+copybook.name )
        },
        transcription_resized() {
        //     this.$nextTick( this.on_resize )
        },
        on_view_mode_toggled() {
            if( this.view_mode == 2 ) 
                this.$nextTick( this.on_resize )
        },
        on_resize() {
        //     const busta_header  = document.getElementById("busta_header")
        //     const transcription = document.getElementById("tr_panel")
        //     if( !busta_header || !transcription) return
        //     const h1 = window.innerHeight
        //     const h2 = busta_header.clientHeight
        //     const h3 = 12 * 2// padding of the container
        //     const h4 = 64 // nav
        //     const h5 = transcription.clientHeight
        //     this.viewer_height = (h1-h2-h3-h4-h5-2)+"px"
        },
        on_citation() {
            //console.log("on_citation")
            this.cite_images = []
            this.doc.imagearea_set.forEach( item=> {
                this.cite_images.push( item.image.filename )
            })
            this.show_citation = true
        },
        on_close_citation() {
            this.cite_images = []
            this.show_citation = false
        },
        on_tr_expanded() {
            this.enable_split = true
        },
        on_tr_collapsed() {
            this.enable_split = false
        },

    },
}
</script>
<style scoped>
.font_h1 {
    font-family: Roboto, sans-serif;
    font-size:32px;
    font-weight: 700;
    line-height: 1.5rem;
    color:rgba(0,0,0,0.87);
}
.v-chip { 
    margin:2px;
    min-width:50px;
}
.btn-outline {
    border: 1px solid rgba(0,0,0,.15) !important;
}
.indicator {
    max-width  : 12px;
    min-width  : 12px;
    max-height : 4px;
    min-height : 4px;
}

.chip1 { background: var(--v-chip1-base  ) !important; }
.chip2 { background: var(--v-chip2-base  ) !important; }
.chip3 { background: var(--v-chip3-base  ) !important; }
.chip4 { background: var(--v-chip4-base  ) !important; }
</style>



