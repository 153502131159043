<template>
    <v-card >
        <v-card-title class="text-h5"><LocText text_id="search.title" /></v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" >

                <v-tabs v-model="tab" centered >
                    <v-tab > <LocText text_id="search.tab1" />    </v-tab>
                    <v-tab > <LocText text_id="search.tab2" /> </v-tab>
                    <v-tab-item>
                        <br><LocText text_id="search.tab1_subtitle" /><br><br>

                        <v-text-field outlined clearable
                            v-model="tr_text"
                            data-cy="filter-text">
                                <template v-slot:label>
                                    <LocText text_id="search.words" />
                                </template>
                        </v-text-field>

                        <v-autocomplete outlined clearable
                            :items="person_list" 
                            item-text="name" 
                            v-model="person" 
                            data-cy="filter-person">
                                <template v-slot:label>
                                    <LocText text_id="search.person" />
                                </template>
                        </v-autocomplete>

                        <v-text-field outlined clearable
                            v-model="date_from"
                            placeholder =  "yyyy-mm-dd"
                            :rules = date_rules
                            data-cy="filter-date-from">
                                <template v-slot:label>
                                    <LocText text_id="search.date_from" />
                                </template>
                        </v-text-field>

                        <v-text-field outlined clearable
                            v-model="date_to"
                            placeholder =  "yyyy-mm-dd"
                            :rules = date_rules
                            data-cy="filter-date-to">
                                <template v-slot:label>
                                    <LocText text_id="search.date_to" />
                                </template>
                        </v-text-field>

                        <v-text-field outlined clearable
                            v-model="tr_biblio"
                            data-cy="filter-biblio">
                                <template v-slot:label>
                                    <LocText text_id="search.biblio" />
                                </template>
                        </v-text-field>

                        <v-autocomplete outlined
                            v-model="tr_version"
                            :items="tr_versions">
                                <template v-slot:label>
                                    <LocText text_id="search.version" />
                                </template>
                                <template v-slot:item="data">
                                    <LocText :text_id="data.item" />
                                </template>
                                <template v-slot:selection="data">
                                    <LocText :text_id="data.item" />
                                </template>
                        </v-autocomplete>

                        <v-autocomplete outlined
                            v-model="tr_doc_type"
                            :items="tr_doc_types">
                                <template v-slot:label>
                                    <LocText text_id="search.doc_type" />
                                </template>
                                <template v-slot:item="data">
                                    <LocText :text_id="data.item" />
                                </template>
                                <template v-slot:selection="data">
                                    <LocText :text_id="data.item" />
                                </template>
                        </v-autocomplete>

                        <v-autocomplete outlined 
                            :items="authors" 
                            item-text="full_name" 
                            v-model="tr_author" 
                            data-cy="filter-author">
                                <template v-slot:label>
                                    <LocText text_id="search.author" />
                                </template>
                        </v-autocomplete>

                    </v-tab-item>

                    <v-tab-item>
                        <br><LocText text_id="search.tab2_subtitle" /><br><br>

                        <v-autocomplete outlined clearable
                            :items="buste" 
                            item-text="name" 
                            v-model="box_num" 
                            data-cy="filter-box"
                            @change="box_changed">
                                <template v-slot:label>
                                    <LocText text_id="search.box" />
                                </template>
                        </v-autocomplete>

                        <v-autocomplete outlined clearable
                            :items="copybooks" 
                            item-text="name" 
                            v-model="copybook_num" 
                            data-cy="filter-copybook"
                            @change="copybook_changed">
                                <template v-slot:label>
                                    <LocText text_id="search.copybook" />
                                </template>
                        </v-autocomplete>

                        <v-text-field  outlined clearable
                            v-model="letter_num"
                            data-cy="filter-letter"
                            :rules = "letter_num_rules"
                            :disabled = "disable_letter_num"
                            @change="letter_num_changed">
                                <template v-slot:label>
                                    <LocText text_id="search.letter" />
                                </template>
                        </v-text-field>

                        <v-text-field outlined clearable
                            v-model="page_num"
                            data-cy="filter-page"
                            :rules = "page_num_rules"
                            :disabled = "disable_page_num"
                            @change="page_num_changed">
                                <template v-slot:label>
                                    <LocText text_id="search.page" />
                                </template>
                        </v-text-field>


                    </v-tab-item>
                </v-tabs>

            </v-form>
        </v-card-text>
        <v-card-actions class="pb-5">
            <v-spacer/>

            <v-btn depressed @click="on_clear" >                          
                <LocText text_id="general.clear" />
            </v-btn>

            <v-btn 
                color="primary" 
                text--color="white"
                depressed 
                @click="on_search" 
                v-bind:disabled="!valid" 
            > 
                <LocText text_id="general.search" />
            </v-btn>
            
            <v-btn depressed @click="on_close" >                          
                <LocText text_id="general.cancel" />
            </v-btn>
            <v-spacer/>
        </v-card-actions>
    </v-card>
</template>

<script>
import LocText   from "../components/LocText"
import { mapState    }    from 'pinia'
//import { mapActions  }    from 'pinia'
import { useAppStore }    from '../stores/appStore'

export default { 
    name: "SearchFilters",    
    components: { LocText },
    data() {
        return {
            tab                 : 0,
            valid               : false,
            box_num             : null,
            copybook_num        : null,
            letter_num          : null,
            page_num            : null,
            person              : null, 
            date_from           : null,
            date_to             : null,
            tr_text             : null,
            tr_biblio           : null,
            tr_author           : null,
            tr_version          : 'latest',
            tr_versions         : [ 'search.version_any','search.version_current','search.version_final'],
            tr_doc_type         : 'any',
            tr_doc_types        : [ 'search.doc_sent','search.doc_received','search.doc_autograph' ],


            disable_letter_num : true,
            disable_page_num   : true,

            date_rules: [
                value => {
                    if( !value ) return true
                    const reg = /^\d{4}-\d{2}-\d{2}$/
                    if( !reg.test(value) ) return 'invalid format -- must be: yyyy-mm-dd'
                    const tok = value.split('-')
                    const y = parseInt(tok[0])
                    const m = parseInt(tok[1])
                    const d = parseInt(tok[2])
                    if ( y<1490 || y>1540 ) return "year must be in 1490..1540"
                    if ( m<1    || m>12   ) return "month must be in 01..12"
                    if ( d<1    || d>31   ) return "day must be in 01..31"
                    return true
                }
            ],            
            letter_num_rules: [
                value => {
                    if( !value ) return true
                    const reg = /^\d+$/
                    if( !reg.test(value) ) return 'invalid format -- must be a number'
                    const num = parseInt(value)
                    if ( num<1 || num>1461 ) return "must be in 1..1460"
                    return true
                }
            ],            
            page_num_rules: [
                value => {
                    if( !value ) return true
                    const reg = /^\d+$/
                    if( !reg.test(value) ) return 'invalid format -- must be a number'
                    const num = parseInt(value)
                    if ( num<1 || num>198 ) return "must be in 1..198"
                    return true
                }
            ],            
        }
    },
    computed : {
        ...mapState( useAppStore, ['authors','person_list', 'buste', 'copybooks'] ),
    },
    methods : {

        box_changed() {
            if( this.box_num ) {
                this.copybook_num = null
                this.page_num = null
                this.disable_page_num = true
                this.disable_letter_num = false
            }
        },
        copybook_changed() {
            if( this.copybook_num ) {
                this.box_num = null
                this.disable_page_num = false
                this.disable_letter_num = false
            }
        },
        letter_num_changed() {
            if( this.letter_num ) 
                this.page_num = null
        },
        page_num_changed() {
            if( this.page_num ) 
                this.letter_num = null
        },
        on_clear() {
            this.valid               = true
            this.box_num             = null
            this.copybook_num        = null
            this.letter_num          = null
            this.page_num            = null
            this.person              = null 
            this.date_from           = null
            this.date_to             = null
            this.tr_text             = null
            this.tr_biblio           = null
            this.tr_version          = null
            this.tr_author           = null
            this.tr_doc_type         = null
            this.disable_letter_num  = true
            this.disable_page_num    = true
        },
        on_search() {

            if( this.tab == 0 )  {

                let search_text = ""
                let terms = []
                if( this.tr_text ) {
                    search_text = this.tr_text.replace(/\s\s+/g, ' '); // replace multiple spaces with single
                    const words = search_text.split(' ').join(',')
                    terms.push( 'words='+words )
                }
                if( this.person ) {
                    const person = this.person_list.find( x => x.name == this.person )
                    terms.push( 'person=' + person.id )
                }
                if( this.date_from )
                    terms.push( 'date_from=' + this.date_from )

                if( this.date_to )
                    terms.push( 'date_to=' + this.date_to )

                if( this.tr_biblio ) {
                    terms.push( 'note=' + this.tr_biblio )
                }
                if( this.tr_doc_type ) {
                    if( this.tr_doc_type == this.tr_doc_types[0] ) terms.push( 'type=sent'      )
                    if( this.tr_doc_type == this.tr_doc_types[1] ) terms.push( 'type=received'  )
                    if( this.tr_doc_type == this.tr_doc_types[2] ) terms.push( 'type=autograph' )
                }
                if( this.tr_author ) {
                    const author = this.authors.find( x => x.full_name == this.tr_author )
                    terms.push( 'author=' + author.id )
                }
                if( this.tr_version == this.tr_versions[0] ) terms.push( 'version=any'     )
                else if( this.tr_version == this.tr_versions[1] ) terms.push( 'version=current' )
                else if( this.tr_version == this.tr_versions[2] ) terms.push( 'version=final'   )
                else terms.push( 'version=current' )

                terms.push( 'published=true' )

                if( terms.length ) {
                    this.$emit('set_search_text', search_text )
                    this.$router.push( '/search/' + terms.join('&') )
                    this.on_close()
                }


                /*
                let terms = {}
                if( this.tr_text ) {
                    const text = this.tr_text.replace(/\s\s+/g, ' '); // replace multiple spaces with single
                    const words = text.split(' ').join(',')
                    terms.words = words 
                }
                if( this.person ) {
                    terms.person= this.person
                }

                if( this.date_from )
                    terms.date_from= this.date_from

                if( this.date_to )
                    terms.date_to= this.date_to

                if( this.tr_author ) {
                    terms.author= this.tr_author 
                }
                terms.version='current' // default if unset
                if( this.tr_version == this.tr_versions[0] ) terms.version='any'
                if( this.tr_version == this.tr_versions[2] ) terms.version='final'  

                this.$emit('set_search_terms', terms)
                */


            }
            else
            {
                let term = ""
                if( this.box_num ) {
                    term = 'B' + this.box_num
                    if( this.letter_num )
                        term += "_D" + this.letter_num
                }
                else if( this.copybook_num ) {

                    term = 'C' + this.copybook_num
                    if( this.letter_num )
                        term += "_D" + this.letter_num
                    else if( this.page_num )
                        term+= "_P" + this.page_num
                }
                if( term ) {
                    this.$emit('set_search_text', term.replace("_", " ") )
                    this.$router.push( '/' + term )
                    this.on_close()
                }
                    
            }
        },
        on_close() {
            this.$emit('close')
        },


    },
}
</script>
<style scoped>
</style>






