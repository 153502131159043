<template>
    <v-container fluid pa-0 data-cy="view-about">
        <div class="d-flex justify-start pa-1" v-if="user && user.is_staff && lang_edit">
            <v-btn v-if="!edit" small depressed color="primary"  @click="edit=true"  > edit   </v-btn>
        </div>    
        <div v-if="edit">
            <TextEditor 
                v-bind:text="$t('page.about')" 
                v-on:save="on_save($event)"
                v-on:cancel="on_cancel()"
            />
        </div>
        <div v-else v-html="$t('page.about')" class="pa-3 text-body">
        </div>
    </v-container>
</template>

<script>
import TextEditor      from "../components/TextEditor"
import { mapState }    from 'pinia'
import { mapActions }  from 'pinia'
import { useAppStore } from '../stores/appStore'

export default { 
    name: "About",  
    components: { TextEditor },
    data() {
        return {
            text:"",
            edit:false,
        }
    },
    mounted () {
        this.init()
    },
    computed : {
        ...mapState(   useAppStore, ['user', 'lang_ita', 'lang_eng', 'lang_current', 'lang_edit'] ),
    },
    methods: {
        ...mapActions( useAppStore, [ 'set_site_text'] ),

        init() {
            this.text=this.$t('page.about')
        },
        on_save( value ) {
            let text_ita = this.lang_ita['page']['about']
            let text_eng = this.lang_eng['page']['about']

            if( this.lang_current == "it") {
                text_ita = value
            } else {
                text_eng = value
            }
            this.set_site_text( 'page.about', text_ita, text_eng)
            this.edit=false
            this.init()
        },
        on_cancel() {
            this.edit=false
        }
    }
}
</script>
<style scoped >
.text-body {
    margin: 20px !important;
}
.text-body a {
      pointer-events: none;
}
.text-body h1 {
      margin: 10px 0 20px !important;
}
.text-body h2 {
      margin: 10px 0 20px !important;
}
.text-body h3 {
      margin: 10px 0 20px !important;
}
.text-body blockquote {
      border-left: .25em solid #dfe2e5;
      color: #6a737d;
      padding-left: 1em;
      margin: 20px 0 !important;
}
.text-body code {
      padding: 0 4px !important;
      margin: 0 5px !important;
}
.text-body pre code {
        padding: 8px !important;
        margin: 0 5px !important;
}
.text-body code:before {
      content: none !important;
      letter-spacing: initial !important;
}
.text-body code:after {
      content: none !important;
      letter-spacing: initial !important;
}
.text-body p {
      margin-top: 16px !important;
      margin-bottom: 16px !important;
      min-height: 1rem;
}



/*  ... non funziona ?? ... */
.text-body h1  {
    font-family:  Homenaje, Oswald, Roboto, sans-serif !important;
    font-size: 2rem !important;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: .0125em!important;
    color:     var(--v-title-base   ) !important;
}
.text-body h2  {
    font-family:  Homenaje, Oswald, Roboto, sans-serif !important;
    font-size: 2rem !important;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: .0125em!important;
    color:     var(--v-title-base   ) !important;
}
.text-body h3  {
    font-family:  Homenaje, Oswald, Roboto, sans-serif !important;
    font-size: 2rem !important;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: .0125em!important;
    color:     var(--v-title-base   ) !important;
}
</style>

