<template>
<!--<div :id="viewer_id" v-bind:style="{ height:height+'px' }" class="osd_viewer"> -->
    <div :id="viewer_id" v-bind:style="{ height:'100%' }" class="osd_viewer">

        <div 
            v-for="o in ov_lab_divs" 
            :key="o.element" 
            :id="o.element"
            style="color:black; background-color:rgb(255 255 255 / 40%); font-size:small; padding:0 5px; border-radius:10px"
        >   
            {{o.text}}         
        </div>

        <div 
            v-for="o in ov_area_divs" 
            :key="o.element" 
            :id="o.element"
            style="background-color:rgb(0 0 0 / 40%); box-shadow: 0px 0px 20px rgb(0 0 0 / 80%); color:white; font-size:small"
        >   
            {{o.text}}         
        </div>

    </div>
</template>

<script>
import OpenSeadragon from 'openseadragon'

export default {
    name:"Dragon",
    props: [
        'viewer_id',
        'sources',  // lista di tileSource
        'ov_area',  // definizione degli area overlay
        'ov_lab',   // definizione dei   label overlay 
        'height',
    ], 

    data() { 
        return {
            viewer: null,
            ov_lab_divs:[],
            ov_area_divs:[],
        } 
    },
    mounted() {
        //const server = ( process.env.NODE_ENV == "development" ) ? "http://127.0.0.1" : ""
        //const url = server+"/static/letters/img_osd/"   
        const url = "/static/letters/img_osd/"   
        this.viewer = OpenSeadragon({
            id:                  this.viewer_id,
            prefixUrl:           url, 
            sequenceMode:        false,
            crossOriginPolicy:   "Anonymous", // serve ?
            ajaxWithCredentials: false,       // serve ?
        }); 
        this.load_sources()
    },
    watch: {
        sources: function() {
            this.load_sources()
        },
        ov_lab: function() {
            this.update_ov_lab()
        },
        ov_area: function() {
            this.update_ov_area()
        },
    },
    methods: {
        copy_object(o) {
            return JSON.parse(JSON.stringify(o))
        },
        fix_filename( filename ) {
            
            //const devel = process.env.NODE_ENV == "development"
            //console.log( 'devel=',devel)
            
            // in production we have only the first 200 images of MDP3000
            // if( !devel ) {
            //     const busta_ok = filename.startsWith('MDP3000')
            //     const cb_ok = filename.indexOf("52") > -1
            //     if( !busta_ok || !cb_ok )
            //         filename = "isa_p.tif"
            // }
            
            // il server va specificato

            let server = ""

            const url = window.location.href
            if( url.startsWith( 'http://127.0.0.1:8000/' ) )
                server = "http://127.0.0.1"

            //const server = ( devel ) ? "http://127.0.0.1" : ""
            const path = server +  "/fcgi-bin/iipsrv.fcgi?Deepzoom=/data/iip/tiles/"
            return path + filename + ".dzi"
        },
        load_sources() {

            if( !this.sources )
                return // at mounted ?

            this.images = []
            this.sources.forEach( o => {
                o.tileSource = this.fix_filename( o.filename ) 
                this.images.push( o )
            })

            this.viewer.open( this.images ) 
            this.update_ov_lab()
            this.update_ov_area()
        },
        update_ov_lab() {
            this.ov_lab_divs.forEach( o=> {
                this.viewer.removeOverlay( o.element )
            })
            if( !this.ov_lab) { // if not used ov_lab is undefined - this mess up stringify
                this.ov_lab_divs=[]
                return 
            }
            this.ov_lab_divs =  this.copy_object( this.ov_lab)
            //setTimeout( this.update_ov_lab2, 10 ) // let vue create the divs     
            this.$nextTick( this.update_ov_area2 ) // let vue create the divs     
        },
        update_ov_lab2() {
            this.ov_lab.forEach( o=> {
                o.location = new OpenSeadragon.Point (o.x, o.y )
                this.viewer.addOverlay( o )
            })
        },
        update_ov_area() {
            this.ov_area_divs.forEach( o=> {
                this.viewer.removeOverlay( o.element )
            })
            if( !this.ov_area ) { // if not used ov_area is undefined - this mess up stringify
                this.ov_area_divs = []
                return // undefined 
            }
            this.ov_area_divs = this.copy_object( this.ov_area )
            //setTimeout( this.update_ov_area2, 10 ) // let vue create the divs     
            this.$nextTick( this.update_ov_area2 ) // let vue create the divs     
        },
        update_ov_area2() {
            this.ov_area.forEach( o=> {
                o.location = new OpenSeadragon.Point (o.x, o.y )
                this.viewer.addOverlay( o )
            })
        }
    }
}  
</script>

<style scoped>
.osd_viewer {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgb(0, 0, 0);
    min-height: 100px;
    height:100%;
}
</style>


