<template>
<div>
    <v-container data-cy="view-buste">
        <div class="d-flex justify-start flex-wrap">
            <div class="text-h6 pagetitle" ><LocText text_id="buste.title"/></div> 
            <!-- <v-icon @click="show_color_editor=true"> mdi-palette </v-icon> -->
            <v-spacer/>
            <!--
            <v-btn-toggle v-model="view_mode" class="pl-6">
                <v-btn small ><v-icon >mdi-view-grid-outline</v-icon></v-btn>
                <v-btn small ><v-icon >mdi-format-list-bulleted</v-icon></v-btn>
            </v-btn-toggle>            
            -->
            <LocText text_id="buste.sort"/>
            <v-btn-toggle class="ml-6">
                <v-btn small @click="sort_by='name'" data-cy="sort-by-name"> <LocText text_id="buste.name"/> </v-btn>
                <v-btn small @click="sort_by='date'" data-cy="sort-by-date"> <LocText text_id="buste.date"/> </v-btn>
            </v-btn-toggle>            


        </div>
        <!-- gray versions -->
        <div v-if="!$vuetify.theme.dark">
            <img v-if="lang_current=='en'" src="https://docs.google.com/drawings/d/e/2PACX-1vTjKHZ6okd0CdA0hhdeyxrWraBUwRqP3WAIiiHudyBQmtpPlu2LnCQ8EMb1ZiRM6EAEiWHOToyhgnsH/pub?w=804&amp;h=143" />
            <img v-if="lang_current=='it'" src="https://docs.google.com/drawings/d/e/2PACX-1vQ0XCDoe9mDKDq-9IFrfDrG66F9PxXnOfHLF23XCUIc296q-CW1eSUx8gRzFBcsdtb920axY3eyxfV6/pub?w=804&amp;h=143" />
        </div>
        <div v-else>
            <img v-if="lang_current=='it'" src="https://docs.google.com/drawings/d/e/2PACX-1vS7-l3YroPXh10jH5Ml5zE_tg4FrOXJgMqoMTEhzKDA9vkYDgn3IVYQJhkKz9so4_AQOBs_cS3OswUz/pub?w=804&amp;h=143" />
            <img v-if="lang_current=='en'" src="https://docs.google.com/drawings/d/e/2PACX-1vSkHPETX-d3_dBjE_4NNtERG4p9OfX1w2_o0_nQjbpArfGWhXaGMG1VO0D9u-tOBYA5z3MzV0utnf3Z/pub?w=804&amp;h=143" />
        </div>

        <!--- colored version 
        <img v-if="lang_current=='en'" src="https://docs.google.com/drawings/d/e/2PACX-1vTlsdhrRWJPse9P1oyJPkHjOai5nan2c6CSP4lY6Pvwv-YI0XHbIYs7gLiq3O4bb_rEfnM6wKzX26XX/pub?w=804&amp;h=143">
        <img v-if="lang_current=='it'" src="https://docs.google.com/drawings/d/e/2PACX-1vQIvo2NZtpuF7o0dTvJUasjNjIrdyLApjITk4gdJR5XqTVRiDln_7u6QemSiy_mIvkT_kc2Tk72AMvq/pub?w=804&amp;h=143">
        -->

        <!--- colored version 2
        <img v-if="lang_current=='en'" src="https://docs.google.com/drawings/d/e/2PACX-1vQlULela62Ep92ZQ2o9cYok9Wb8s95pH7lF7JnQuLzGtjZOszgY9BZ8jcujA77ntAIBdbF9HSN3tWB_/pub?w=804&amp;h=143" > 
        <img v-if="lang_current=='it'" src="https://docs.google.com/drawings/d/e/2PACX-1vQ0XCDoe9mDKDq-9IFrfDrG66F9PxXnOfHLF23XCUIc296q-CW1eSUx8gRzFBcsdtb920axY3eyxfV6/pub?w=804&amp;h=143" > 
        -->

        <!--
        <div v-if="view_mode==1">
            <div class="pl-4 text-body-2" v-if="num_results" > {{num_results}} <LocText text_id="buste.items"/> </div>
            <v-data-table 
                mobile-breakpoint="100"
                :headers="headers"
                :items="buste"
                :items-per-page="100"
                dense
                @click:row="open_busta">
            </v-data-table>
        </div>
        -->

<!-- 
        <ColorEditor
            :colors="colors"
            :show="show_color_editor"
            @close="show_color_editor=false"
            @changed="on_color"
        />
 -->

        <!-- <div v-if="view_mode==0" class="pt-3"> -->
            <div class="d-flex justify-start align-center" >
                <div class="text-h6 pagetitle pr-3" > <LocText text_id="buste.buste_wl"/></div>
            </div>            
            <div class="d-flex justify-start flex-wrap">
                <div
                    v-for="b in buste1" 
                    :key="b.name"
                    @click="open_busta(b)"
                    >
                    <v-sheet rounded class="buste_with_letters ma-1 pa-2" :z--style="{background:bg1}" color="box1">
                        <div class="text-body font-weight-bold" > 
                            {{ b.name.replace('V00', 'Volta ') }}
                        </div>
                        <div class="text-caption"> {{ $d( Date.parse(b.date_from), 'short' )}} </div>
                        <v-sheet class="text-caption letters_nd" color="box1b"> {{ b.collection_set[0].num_doc }} / {{ b.collection_set[0].num_doc_wt }}  </v-sheet>
                    </v-sheet>
                </div>
            </div>
            
            
            <div class="text-h6 pagetitle pt-3" > <LocText text_id="buste.buste_wo"/></div>
            <div class="d-flex justify-start flex-wrap">
                <div
                    v-for="b in buste2" 
                    :key="b.name"
                    @click="open_busta(b)"
                    >
                    <v-sheet rounded class="buste_with_original_letters ma-1 pa-2" :z---style="{background:bg2}" color="box2">
                        <div class="text-body font-weight-bold" > 
                            {{ b.name==="VA01" ? "Volta Autografi" : b.name }}
                        </div>
                        <div class="text-caption"> {{ $d( Date.parse(b.date_from), 'short' )}} </div>
                        <v-sheet class="text-caption letters_nd" color="box2b"> {{ b.collection_set[0].num_doc }} / {{ b.collection_set[0].num_doc_wt }}  </v-sheet>
                    </v-sheet>
                </div>
            </div>

            <div class="text-h6 pagetitle pt-3" > <LocText text_id="buste.buste_wc"/> </div>
            <div class="d-flex justify-start align-stretch flex-wrap" >
                <div
                    v-for="b in buste3" 
                    :key="b.name"
                    >
                    <v-sheet rounded class="buste_with_copybook ma-1" v-bind:class="{ busta_wip: b.wip }" :z--style="{background:bg3}" color="box3">
                        <div class="px-2 ">
                            <div class="d-flex justify-start pt-1" >
                                <div class="text-body font-weight-bold" > {{ b.name }} </div>
                                <v-spacer />
                                <v-icon v-if="b.wip" small color="#ff4141" z-class="mb-2"  > mdi-circle </v-icon> 
                            </div>
                            <div class="text-caption"> {{ $d( Date.parse(b.date_from), 'short' )}} </div>
                        </div>
                        <div class="d-flex justify-start px-1 pb-1"> 
                            <v-sheet
                                label
                                v-for="c in b.collection_set" 
                                :key="c.name"
                                class="copybook mx-1 mb-1"
                                color="box3b"
                                @click="open_copybook(c.name)"
                            >
                                <div class="copybook_name pt-1" >
                                    {{ c.name }}
                                </div>
                                <div class="copybook_nd text-caption" >
                                    {{c.num_doc}} / {{c.num_doc_wt}}
                                </div>
                            </v-sheet>   

                        </div>

                    </v-sheet>
                </div>
            </div>
        <!-- /div -->
    </v-container>
</div>
</template>

<script>
import LocText          from "../components/LocText"
//import ColorEditor      from "../components/ColorEditor"
import sortBy           from 'lodash/sortBy';
import { mapState }     from 'pinia'
import { useAppStore }  from '../stores/appStore'

export default { 
    name: "Buste",  
    components: { LocText  /*, ColorEditor*/ },    
    data() {
        return {
            sort_by: "name",   
            //view_mode:0,          // 0=icon 1=table

            buste_with_letters_color : '#64b5f6',
            show_color_editor : false,

            fg1 : "#000",
            fg2 : "#000",
            fg3 : "#000",
            bg1 : "#64b5f6",
            bg2 : "#f664de",
            bg3 : "#ffb74d",
        }
    },
    computed : {
        buste_sorted : function() {
            //return sortBy( this.buste, this.sort_by ) // non funziona
            if( this.sort_by == "name" )                // funziona
                return sortBy( this.buste, "name" )
            else
                return sortBy( this.buste, "date_from" )
        },
        buste1 : function() {  // original letters received
            return this.buste_sorted.filter( b => {
                //return b.type == "L" &&  !['2106','2107','2108','2108a','2109','2110','2111', 'VA01'].includes( b.name )              
                return b.type == "L" && !b.name.startsWith('21') && !b.name.startsWith('VA')
            })
        },
        buste2 : function() {  // original letters sent
            return this.buste_sorted.filter( b => {
                //return b.type == "L" &&  ['2106','2107','2108','2108a','2109','2110','2111', 'VA01'].includes( b.name )              
                return b.name.startsWith('21') || b.name.startsWith('VA')
            })
        },
        buste3 : function() {  // copy of letters sent
            return this.buste_sorted.filter( b => {
                return b.type == "C"                
            })
        },
        colors : function() {
            return [
                { i:0, lab:"Box A text",         val:this.fg1 },
                { i:1, lab:"Box A background",   val:this.bg1 },
                { i:2, lab:"Box B text",         val:this.fg2 },
                { i:3, lab:"Box B background",   val:this.bg2 },
                { i:4, lab:"Box C text",         val:this.fg3 },
                { i:5, lab:"Box C background",   val:this.bg3 },
            ]
        },

        ...mapState( useAppStore, ['buste', 'lang_current'] ),
    },
    methods: {
        open_busta( item ) {
            this.$router.push( '/B'+item.name )
        },
        open_copybook( item ) {
            this.$router.push( '/C'+item )
        },
        on_color( i, c ) {
            //console.log( "on_color", i, c )
            //debugger
            switch( i ) {
            case 0: this.fg1 = c; break; 
            case 1: this.bg1 = c; break; 
            case 2: this.fg2 = c; break; 
            case 3: this.bg2 = c; break; 
            case 4: this.fg3 = c; break; 
            case 5: this.bg3 = c; break; 
            //default: console.log( "on_color, bad index:",i)
            }
        }
    }
}
</script>

<style scoped>
.buste_with_letters {
    z--color:white !important;
    z---background: #ddd !important;
    z---background: #ece8df !important; 
    z---background: #64b5f6!important;
    min-width:100px;
}
.buste_with_letters:hover {
    z--background: #ddd !important;
    z--background: #ffb74d!important;
    background:  var(--v-box1-lighten2 ) !important; 
}
.buste_with_original_letters {
    z---background: #f664de!important;
    min-width:100px;
}
.buste_with_original_letters:hover {
    z---background: #ddd !important;
    background:  var(--v-box2-lighten2 ) !important; 
}
.buste_with_copybook {  /* no hilight */
    z---background: #ddd !important;
    z---background: #ece1df !important; 
    z---background: #ffb74d!important;
    min-width:100px;
}
.copybook {
    background-color: white !important;
    z---background-color: #ddd !important;
    z---background: #64b5f6!important;
    border-radius:5px;
    max-width:60px;
    min-width:60px;
}
.copybook:hover { 
    z--background: #ddd !important;
    z---background: #ffb74d!important;
    background:  var(--v-box3b-lighten2 ) !important; 
}
.copybook_name {
    font-size:16px;
    font-weight:700;
    text-align:center;
}
.copybook_nd {
    z---color:gray;
    z---color:#333;
    z---background-color: white !important;
    z---background-color : #333;
    text-align:center;
}
.busta_wip {
    border-radius:5px;
    z----border: 2px dashed red;
}
.letters_nd {
    z---color: gray;
    z---color: #333;
    z---background-color: white !important;
    z---background-color : #333;
    text-align: center;
    border-radius: 5px;
}
</style>
