<template>
    <v-container fluid data-cy="view-search">
<!-- 
        <div class="text-body-2"> 
            filters: {{ filters }}
        </div>              
 -->
        <div class="d-flex justify-start flex-wrap">
            <div class="text-h6" ><LocText text_id="search_page.title" /></div> 
        </div>
        <div class="text-body-2  pb-3"> 
            <span v-if="next"> 
                showing {{items.length}} results of {{num_results}} 
                <v-btn plain small @click="load_more"> load more </v-btn>
            </span>
            <span v-else>
                found {{items.length}} results
            </span>
        </div>              

<!-- 
        <div v-for="i in items" :key="i.key" class="pt-5">
            <div class="d-flex justify-start">
                <v-chip label :color="i.color" >
                    <router-link class="text-decoration-none router-link text-body-2 black--text" :to="i.url"> 
                        {{i.name}}
                    </router-link> 
                </v-chip>
                <div>
                    <div class="text-caption pl-3">{{i.date}}</div>
                    <div v-for="line in i.lines" :key="line" class="text-body-2 pl-3" v-html="line" />
                </div>
            </div>
        </div>
 -->

        <v-data-table
            mobile-breakpoint="100"
            width = "1500px"
            :headers="table_headers"
            :items="items"
            item-key="key"
            :items-per-page="200"
            :single-expand="false"
            show-expand
            @click:row="open_result">

            <template v-slot:header.key         ="{ header }" > <LocText :text_id="header.text" /> </template>
            <template v-slot:header.date        ="{ header }" > <LocText :text_id="header.text" /> </template>
            <template v-slot:header.sender      ="{ header }" > <LocText :text_id="header.text" /> </template>
            <template v-slot:header.recipient   ="{ header }" > <LocText :text_id="header.text" /> </template>
            <template v-slot:header.ccrecipient ="{ header }" > <LocText :text_id="header.text" /> </template>
            <template v-slot:header.lines       ="{ header }" > <LocText :text_id="header.text" /> </template>
            <template v-slot:header.author      ="{ header }" > <LocText :text_id="header.text" /> </template>
            <template v-slot:header.biblio      ="{ header }" > <LocText :text_id="header.text" /> </template>

            <template v-slot:item.key="{ item }">
                <router-link  class="text-decoration-none router-link" :to="item.doc_url">
                    {{item.name}}
                </router-link>  
            </template>

            <template v-slot:item.lines="{ item }">
                <span v-html="item.lines"></span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div style="white-space: pre-line; margin:20px!important;">
                            {{item.text}}<br> 
                    </div>
                </td>
            </template>

        </v-data-table>



        <div v-if="next" class="pt-4"> 
            <v-btn plain small @click="load_more"> load more </v-btn>
        </div>
    </v-container>
</template>

<script>
import LocText              from "../components/LocText"
import { mapState    }      from 'pinia'
//import { mapActions  }    from 'pinia'
import { useAppStore }      from '../stores/appStore'
import { fetch_json }       from '../api'

export default { 
    name: "Search",    
    components: { LocText },
    data() {
        return {
            params : "",
            filters : "",
            terms : [],
            words : [],
            next : "",
            num_results : 0,
            items: [], 

            table_headers: [    
                 { text: 'search.th_id',            value: 'key',           width:"150px" },
                 { text: 'search.th_date',          value: 'date',          width:"150px" },
                 { text: 'search.th_sender',        value: 'sender',        width:"150px" },
                 { text: 'search.th_recipient',     value: 'recipient',     width:"150px" },
                 { text: 'search.th_cc_recipient',  value: 'ccrecipient',   width:"200px" },
                 { text: 'search.th_text',          value: 'lines',         width:"300px" },
                 { text: 'search.th_author',        value: 'author',        width:"200px" },
                 { text: 'search.th_biblio',        value: 'biblio',        width:"200px" },
            ],
        }
    },
    created() {
        this.do_search()
    },
    watch: {
        $route() {
            this.do_search()
        },
    },    
    computed : {
        ...mapState( useAppStore, ['person_list'] ),
    },
    methods : {
        //...mapActions( useAppStore, ['load_authors'] ),

        do_search() {
            this.params = this.$route.params['query']
            this.filters = this.params.replace('&','  ')
            //console.log('search params', this.params )
            this.terms = this.params.split('&')
            this.words = []
            this.terms.forEach( x => { 
                if( x.startsWith('words=') ) 
                        this.words = x.replace( 'words=','').split(',') 
            })
            
            const url = '/api/transcription/?' + this.params
            fetch_json( "GET", url, {}, "search transcription", (json)=> {
                this.num_results = json.count
                this.next = json.next
                this.items = []
                this.add_items( json.results )
            })
        },
        load_more() {
            const url = this.next
            fetch_json( "GET", url, {}, "search more transcription", (json)=> {
                this.next = json.next
                this.add_items( json.results )
            })
        },
        add_items( results ) {

            //console.log('results',results)

            results.forEach( r => {
                
                let lines = []
                if(  this.words.length ) {
                    const text = r.transcription.replace('\n','')
                    let pos1 = 0
                    let pos2 = 0
                    while( pos1 < text.length-1 )
                    {
                        pos2 = text.indexOf( ' ', pos1+30 )
                        if ( pos2 == -1 ) pos2 = text.length
                        let line = text.substr( pos1, pos2-pos1+1 )
                        
                        let keep = false;    
                        this.words.forEach( word => {
                            // stackoverflow.com/questions/19056744/highlight-all-occurrences-of-string-case-insensitive
                            const re = new RegExp( "("+word+")","gi")
                            if( line.match(re) ) {
                                keep = true
                                line = line.replace( re, "<mark>$1</mark>" )
                            }
                        })
                        if( keep ) {
                            lines.push( "..."+line+"...")
                            if (lines.length > 2)
                                break
                        }
                        pos1 = pos2
                    }
                }
                else
                {
                    lines = r.transcription ? [ r.transcription.split(' ').slice(0,8).join(' ') + " ... " ] : ["-"]
                }


                let sender = "-"
                if( r.sender ) sender = this.person_list.find( x => x.url == r.sender ).name
                let recipient = "-"
                if( r.recipient ) recipient = this.person_list.find( x => x.url == r.recipient ).name
                let cc = []
                r.cc_recipient.forEach( y => {
                    const person = this.person_list.find( x => x.url == y )
                    if( person )  cc.push(  person.name  )
                })
                const cc_recipient = cc.length ? cc.join(', ') : "-"

                const item = {}
                item.lines = lines.join("<br>")
                // r.name example: C052_D17:T1   X1:Tv1
                item.key            = r.name.split(':')[0].replace('C00','C').replace('C0','C')
                item.url            = '/'+item.key
                item.doc_url        = item.url.split('_T')[0]
                item.date           = r.date_sent
                item.sender         = sender
                item.recipient      = recipient
                item.ccrecipient    = cc_recipient
                item.author         = r.author ? r.author.first_name +" "+ r.author.last_name : "-"
                item.text           = r.transcription
                item.biblio         = r.bibliography

                if( item.key[0] == 'C' ) {

                    const cnum = item.key.split('_')[0].replace('C','')
                    const dnum = item.key.split('_')[1].replace('D','')
                    item.name = "copybook "+cnum+ " document "+ dnum    
                }
                else
                    item.name = "document "+ item.key

                item.color = ( item.key[0] == 'C' ) ? "blue lighten-3" : "default"
                item.name = item.key.replace('_',' ')
                this.items.push( item )
            })
        },
        open_result( item ) {
            if( !item || !item.doc_url ) return
            //console.log('item',item,'doc_url',url)
            this.$router.push( item.doc_url )
        },
    }
}
</script>

<style scoped>

/*  sembra che nessuno stile faccia qualcosa -- ma va bene */

mark {
    background-color: red; /*#e7e7e7;*/
}
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color:lightyellow !important;
  border-radius:15px !important;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color:lightyellow !important;
  border-radius:15px !important;
}
/* .v-data-table {   
  overflow-x: auto !important;
  max-width : 100% !important;
  width : 100% !important;
} */

</style>
