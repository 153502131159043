<template>
    <v-dialog persistent v-model="show_internal" max-width="400" >
        <v-card>
            <v-card-title class="text-h5">
                <LocText text_id="reset_psw.title" />
            </v-card-title>

            <v-stepper v-model="step" flat vertical>


                <v-stepper-step :complete="step > 1"  step="1" > <LocText text_id="reset_psw.title1"/> </v-stepper-step>

                <!--------- STEP 1 ---------------->

                <v-stepper-content step="1">
                    <v-card>
                        <v-card-text>

                            <div class="text-body">
                                <LocText text_id="reset_psw.subtitle" />
                            </div>
                            <v-form  ref="form1" v-model="valid1" >
                                <v-text-field class="pt-6"
                                    v-model="email" 
                                    v-bind:label="$t('signup.email')"
                                    :error-messages="email_error"
                                    :rules="[ rule_email ]"
                                />
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="step > 2"  step="2" > <LocText text_id="reset_psw.title2"/> </v-stepper-step>

                <!--------- STEP 2 ---------------->

                <v-stepper-content step="2">
                    <v-card>
                        <v-card-text>

                            <v-form  ref="form2" v-model="valid2" >
                                
                                <v-text-field class="py-4"
                                    v-model="token" 
                                    v-bind:label="$t('reset_psw.subtitle2')"
                                    :rules="[ rule_token ]"
                                />

                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-stepper-content>


                <v-stepper-step :complete="step > 3"  step="2" > <LocText text_id="reset_psw.title3"/> </v-stepper-step>

                <!--------- STEP 3 ---------------->

                <v-stepper-content step="3">
                    <v-card>
                        <v-card-text>

                            <v-form  ref="form2" v-model="valid2" >
                                
                                <v-text-field class="py-4"
                                    v-model="new_password1" 
                                    :type="show_psw ? 'text' : 'password'" 
                                    :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append = "show_psw = !show_psw" 
                                    v-bind:label="$t('change_password.password')"
                                    :rules="[ rule_psw1 ]"
                                />
                                <v-text-field class="py-4"
                                    v-model="new_password2" 
                                    :type="show_psw ? 'text' : 'password'" 
                                    :append-icon="show_psw ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append = "show_psw = !show_psw" 
                                    v-bind:label="$t('change_password.password2')"
                                    :rules="[ rule_psw2 ]"
                                />
                            </v-form>

                        </v-card-text>
                    </v-card>
                </v-stepper-content>

            </v-stepper>

            <v-card-actions v-if="step==1" class="pb-5">
                <v-spacer/>
                <v-btn color="primary" text--color="white" depressed  @click="on_submit1" v-bind:disabled="!valid1" > 
                    <LocText text_id="general.next" />
                </v-btn>
                <v-btn depressed @click="on_close" >                          
                    <LocText text_id="general.cancel" />
                </v-btn>
                <v-spacer/>
            </v-card-actions>

            <v-card-actions v-if="step==2" class="pb-5">
                <v-spacer/>
                <v-btn depressed  @click="step=1"> 
                    <LocText text_id="general.prev" />
                </v-btn>
                <v-btn color="primary" text--color="white" depressed  @click="on_submit2" v-bind:disabled="!valid2" > 
                    <LocText text_id="general.next" />
                </v-btn>
                <v-btn depressed @click="on_close" >                          
                    <LocText text_id="general.cancel" />
                </v-btn>
                <v-spacer/>
            </v-card-actions>

            <v-card-actions v-if="step==3" class="pb-5">
                <v-spacer/>
                <v-btn depressed  @click="step=2"> 
                    <LocText text_id="general.prev" />
                </v-btn>
                <v-btn color="primary" text--color="white" depressed  @click="on_submit3" v-bind:disabled="!valid2" > 
                    <LocText text_id="general.submit" />
                </v-btn>
                <v-btn depressed @click="on_close" >                          
                    <LocText text_id="general.cancel" />
                </v-btn>
                <v-spacer/>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import Vue              from "vue"
import LocText          from "../components/LocText"
import { fetch_json }   from '../api'

export default {
    name: "UserPasswordReset",
    props : [ 'show' ],
    components: { LocText },    

    data: () => ({
        show_internal   : false,
        step            : 1,

        valid1          : false,
        email           : "",
        email_error     : '',

        valid2          : false,
        token           : "",
        new_password1   : "",
        new_password2   : "",
        show_psw        : false,

    }),
    created() {
        if( this.show ) this.on_show()
    },    
    watch: {
        show() {
            if( this.show ) this.on_show()
        },
    },
    methods : {
        rule_email(value) { 
            if( !value ) return this.$t('signup.required')
            if( !value.match( /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/ ) ) return this.$t('signup.bad_email')
            return true
        },
        rule_token(value) {
            if( !value ) return this.$t('change_password.required')
            if( !value.match( /^[0-9A-Za-z]{1,13}-[0-9A-Za-z]{1,32}$/ ) ) return this.$t('signup.bad_token')
            return true
        },
        rule_psw1(value) {
            if( !value ) return this.$t('change_password.required')
            if( !value.length >= 4 ) return this.$t('change_password.password_too_short')
            return true
        },        
        rule_psw2() {
            if( this.new_password1 != this.new_password2 ) return this.$t('change_password.new_password2_bad') 
            return true
        },
        on_show() {
            this.step = 1
            this.show_internal = true
        },
        on_submit1() {
            const body = new FormData()
            body.set('email', this.email )

            fetch_json( 'POST', '/lost_password', body, 'lost_password step1', json => {
                if( !json.ok ) {
                    //console.log('reset_psw error')
                    Vue.$toast.error( json.msg )
                }
            })
            this.step = 2
        },
        on_submit2() {
            this.step = 3
        },
        on_submit3() {
            const body = new FormData()
            body.set('email',       this.email )
            body.set('token',       this.token )
            body.set('password',    this.new_password1 )

            fetch_json( 'POST', '/reset_password', body, 'reset_password step3', json => {
                //console.log( 'reset_psw json=', json )
                if( json && json.ok ) {
                    Vue.$toast.success( this.$t('change_password.ok'))
                }
                else {
                    if( json && json.msg )
                        Vue.$toast.error( json.msg )
                    else
                        Vue.$toast.error( 'reset_psw error' )
                }
                this.on_close() 
            })
        },
        on_reset() {
            this.$refs.form1.reset()
            this.$refs.form2.reset()
            this.show_internal = false
        },
        on_close() {
            this.$emit('done') 
            this.on_reset()
        },
    },
}
</script>
<style scoped>
</style>
